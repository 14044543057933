import {ReactNode, useMemo} from 'react';
import {createPortal} from 'react-dom';

interface IProps {
  children: ReactNode;
  elementId?: string;
}

const Portal = ({children, elementId = 'portal'}: IProps) =>
  createPortal(
    children,
    useMemo(() => document.getElementById(elementId), [elementId]),
  );

export default Portal;
