import {stringify} from 'querystring';
import moment from 'moment';
export const header = [
  {
    // id: 'id',
    minWidth: 100,
    title: 'No',
    wrapWidth: 1,
    align: 'center',
  },
  {
    id: 'createdAt',
    minWidth: 140,
    title: '접수일시',
    align: 'center',
  },
  {
    id: 'progressType',
    minWidth: 90,
    title: '접수상태',
    align: 'center',
  },
  {
    id: 'fieldProgressType',
    minWidth: 120,
    title: '현장 진행상태',
    align: 'center',
  },
  // {
  //   id: 'userEmail',
  //   minWidth: 220,
  //   title: '아이디',
  // },
  {
    id: 'userName',
    minWidth: 60,
    title: '고객명',
    align: 'center',
  },
  // {
  //   id: 'userInfo',
  //   minWidth: 130,
  //   title: '고객정보',
  //   align: 'center',
  // },
  {
    id: 'userMobile',
    minWidth: 140,
    title: '연락처',
    align: 'center',
  },
  {
    id: 'contactTime',
    minWidth: 140,
    width: 140,
    title: '연락가능시간',
    align: 'center',
    wrapWidth: 1,
  },
  {
    id: 'roomInfo',
    minWidth: 220,
    width: 220,
    title: '접수정보',
    align: 'center',
    wrapWidth: 1,
  },
  {
    id: 'internalManager',
    minWidth: 90,
    title: '본사담당자',
    align: 'center',
  },
  {
    id: 'fieldManager',
    minWidth: 90,
    title: '중개담당자',
    align: 'center',
  },
  {
    id: 'review',
    minWidth: 40,
    title: '계약후기',
    align: 'center',
  },
  // {
  //   id: 'contractInfo',
  //   minWidth: 15,
  //   title: '계약정보',
  //   align: 'center',
  // },
  // {
  //   id: 'documents',
  //   minWidth: 15,
  //   title: '관련서류',
  //   align: 'center',
  // },
  // {
  //   id: 'memo',
  //   minWidth: 15,
  //   title: '메모',
  //   align: 'center',
  // },
];

export const memoHeader = [
  {
    id: 'createdAt',
    minWidth: 60,
    title: '등록일',
    align: 'center',
  },
  {
    id: 'modifiedAt',
    minWidth: 60,
    title: '수정일',
    align: 'center',
  },
  {
    id: 'status',
    minWidth: 60,
    title: '상태',
    align: 'center',
  },
  {
    id: 'memoSample',
    minWidth: 60,
    title: '메모요약',
    align: 'center',
  },
  {
    id: 'contents',
    minWidth: 60,
    title: '메모전체',
    align: 'center',
  },
  {
    id: 'createdBy',
    minWidth: 60,
    title: '작성자',
    align: 'center',
  },
];

export const masterHeader = [
  {
    id: 'delete',
    minWidth: 50,
    title: '정보삭제',
    align: 'center',
  },
];

export const getProgressType = (type) => {
  if (type === 'COMPLETE') return '계약성공';
  else if (type === 'ACCEPT') return '접수';
  else if (type === 'FAIL') return '계약실패';
  else if (type === 'ASSIGN') return '담당자인계';
  else if (type === 'NORMAL') return '일반상담';
  else if (type === 'MISSED') return '부재';
  else if (type === 'VIEWING') return '투어예정';
  else if (type === 'FINISH_VIEWING') return '투어완료';
  else type;
};
export const getFieldProgressType = (type) => {
  if (type === 'NORMAL') return '일반상담';
  else if (type === 'MISSED') return '부재';
  else if (type === 'NOT_PROGRESS') return '미진행';
  else if (type === 'VIEWING') return '투어예정';
  else if (type === 'FINISH_VIEWING') return '투어완료';
  else type;
};

export const getReplaceMobile = (mobile) => {
  return mobile.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3');
};

const ageGroup = [
  {
    id: 1,
    label: '20대',
    value: 'TWENTIES',
  },
  {
    id: 2,
    label: '30대',
    value: 'THIRTIES',
  },
  {
    id: 3,
    label: '40대',
    value: 'FORTIES',
  },
  {
    id: 4,
    label: '50대 이상',
    value: 'MORE_THEN_FIFTIES',
  },
];

const jobType = [
  {
    id: 1,
    label: '직장인',
    value: 'OFFICE_WORKER',
  },
  {
    id: 2,
    label: '대학(원)생',
    value: 'STUDENT',
  },
  {
    id: 3,
    label: '취업예정자,무직',
    value: 'OUT_OF_WORK',
  },
  {
    id: 4,
    label: '자영업',
    value: 'SELF_EMPLOYMENT',
  },
  {
    id: 5,
    label: '프리랜서,아르바이트',
    value: 'FREELANCE',
  },
  {
    id: 6,
    label: '전문직',
    value: 'PROFESSIONAL',
  },
  {
    id: 5,
    label: '기타',
    value: 'ETC',
  },
];

const options = [
  {
    id: 1,
    label: '일반상담',
    value: 'NORMAL',
  },
  {
    id: 2,
    label: '부재',
    value: 'MISSED',
  },
  {
    id: 3,
    label: '투어예정',
    value: 'VIEWING',
  },
  {
    id: 4,
    label: '투어완료',
    value: 'FINISH_VIEWING',
  },
];

export const getAgeGroupByValue = (value: string) => {
  return ageGroup.find((x) => x.value === value)?.label;
};

export const getJobTypeByValue = (value: string) => {
  return jobType.find((x) => x.value === value)?.label;
};

export const getStatusValue = (value: string) => {
  return options.find((x) => x.value === value)?.label;
};
export const getMemoSummary = (value: string) => {
  if (value.length < 7) {
    return value + '...';
  } else {
    return value.slice(0, 10) + '...';
  }
};

export const getModifiedAt = (value: number) => {
  if (value) {
    return (
      moment(parseInt(value + '000')).format('yyyy-MM-DD') +
      '\u00A0' +
      '\u00A0' +
      '\u00A0' +
      moment(parseInt(value + '000')).format('HH:mm a')
    );
  } else {
    return '';
  }
};

const rentCostGroup = [
  {
    id: 1,
    label: '30만원대',
    value: '30',
  },
  {
    id: 2,
    label: '40만원대',
    value: '40',
  },
  {
    id: 3,
    label: '50만원대',
    value: '50',
  },
  {
    id: 4,
    label: '60만원대',
    value: '60',
  },
  {
    id: 5,
    label: '70만원대',
    value: '70',
  },
  {
    id: 6,
    label: '80만원대',
    value: '80',
  },
  {
    id: 7,
    label: '90만원대',
    value: '90',
  },
  {
    id: 8,
    label: '100만원이상',
    value: '100',
  },
];

const roomType = [
  {
    id: 1,
    label: '원룸',
    value: 'ONE',
  },
  {
    id: 2,
    label: '투룸',
    value: 'TWO',
  },
  {
    id: 3,
    label: '쓰리룸 이상',
    value: 'MORE_THAN_THREE',
  },
];

export const getRentCostGroupByValue = (value: string) => {
  // return rentCostGroup.find((x) => x.value === value)?.label;
  if (!value || typeof value !== 'string') return;
  if (value.includes('-')) {
    return value + '만원';
  }
  if (value === '100' || value === '150') {
    return value + '만원 이상';
  }
  return value + '만원대';
};

export const getRoomTypeByValue = (value: string) => {
  return roomType.find((x) => x.value === value)?.label;
};

const regionTypes = [
  {
    id: 1,
    label: '금천구',
    value: 'GEUMCHEON',
    checked: false,
  },
  {
    id: 2,
    label: '관악구',
    value: 'GWANAK',
    checked: false,
  },
  {
    id: 3,
    label: '구로구',
    value: 'GURO',
    checked: false,
  },
];

export const getContactTimeValue = (value: string) => {
  return value;
};

export const getRegionTypeByValue = (value: string[]) => {
  const result: any = [];
  for (let i = 0; i < value?.length; i++) {
    result.push(regionTypes.find((x) => x.value === value[i])?.label);
  }
  return result.join(',');
};

const moveInDateType = [
  {
    id: 1,
    label: '즉시',
    value: 'NOW',
  },
  {
    id: 2,
    label: '1개월이내',
    value: 'ONE_MONTH',
  },
  {
    id: 3,
    label: '2개월이내',
    value: 'TWO_MONTH',
  },

  {
    id: 4,
    label: '3개월이내',
    value: 'THREE_MONTH',
  },

  {
    id: 5,
    label: '협의가능',
    value: 'DISCUSSION',
  },
];

export const getMoveInDateTypeByValue = (value: string) => {
  return moveInDateType.find((x) => x.value === value)?.label;
};

const addRequestTypes = [
  {
    id: 1,
    label: '주차가능',
    value: 'PARKING',
  },
  {
    id: 2,
    label: '반려동물',
    value: 'PET',
  },
  {
    id: 3,
    label: '엘리베이터',
    value: 'ELEVATOR',
  },
  {
    id: 4,
    label: '기타',
    value: 'ETC',
  },
];

export const getAddRequestTypesByValue = (value: string[]) => {
  const result: any = [];
  for (let i = 0; i < value?.length; i++) {
    result.push(addRequestTypes.find((x) => x.value === value[i])?.label);
  }
  return result.join(',');
};

export const getAddRequestTypesSummury = (value: string[]) => {
  const result: any = [];
  for (let i = 0; i < value?.length; i++) {
    result.push(addRequestTypes.find((x) => x.value === value[i])?.label);
  }
  if (result.length > 2) {
    result.splice(2, 2);
  }

  return result.join(',');
};

export const getGenderByValue = (value: string) => {
  return value ? (value === 'male' ? '남자' : '여자') : '';
};

export const getVisitTypeByValue = (value: string[]) => {
  const result: any = [];
  for (let i = 0; i < value?.length; i++) {
    result.push(visitTypes.find((x) => x.value === value[i])?.label);
  }
  return result.join(',');
};

const visitTypes = [
  {
    label: '카카오톡',
    checked: false,
    value: 'KAKAO',
  },
  {
    label: '페이스북',
    checked: false,
    value: 'FACEBOOK',
  },
  {
    label: 'KB금융그룹',
    checked: false,
    value: 'KB',
  },
  {
    label: '틱톡',
    checked: false,
    value: 'TIKTOK',
  },
  {
    label: '택시',
    checked: false,
    value: 'TAXI',
  },
  {
    label: '마을버스',
    checked: false,
    value: 'BUS',
  },
  {
    label: '가로등 배너',
    checked: false,
    value: 'BANNER',
  },
  {
    label: '피터팬 APP, 홈페이지,카페',
    checked: false,
    value: 'PETERPANZ',
  },
  {
    label: '기타(지인소개 등)',
    checked: false,
    value: 'ETC',
  },
];

export const FIELDTYPES = [
  {label: '미진행', value: 'NOT_PROGRESS'},
  {label: '일반상담', value: 'NORMAL'},
  {label: '부재', value: 'MISSED'},
  {label: '투어예정', value: 'VIEWING'},
  {label: '투어완료', value: 'FINISH_VIEWING'},
];

export const STATUS = [
  {label: '접수', value: 'ACCEPT'},
  {label: '담당자인계', value: 'ASSIGN'},
  {label: '계약성공', value: 'COMPLETE'},
  {label: '계약실패', value: 'FAIL'},
];
