export const header = [
  {
    // id: 'id',
    minWidth: 50,
    title: 'No',
    wrapWidth: 1,
    align: 'center',
  },
  {
    id: 'adminType',
    minWidth: 80,
    title: '담당자 구분',
    align: 'center',
  },
  {
    id: 'email',
    minWidth: 180,
    title: 'ID',
  },
  {
    id: 'basicAddr',
    minWidth: 180,
    title: '지역',
  },
  {
    id: 'fieldOffice',
    minWidth: 180,
    title: '업체명',
  },
  {
    id: 'name',
    minWidth: 150,
    title: '담당자명',
  },
  {
    id: 'mobile',
    minWidth: 150,
    title: '연락처',
  },
  {
    id: 'authorizeType',
    minWidth: 130,
    title: '권한',
    align: 'center',
  },
  {
    id: 'status',
    minWidth: 130,
    title: '상태',
    align: 'center',
  },
];

export const getReplaceMobile = (mobile) => {
  return mobile.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3');
};

export const masterHeader = [
  {
    id: 'resetPw',
    minWidth: 110,
    title: '비밀번호 초기화',
    align: 'center',
  },
  {
    id: 'delete',
    minWidth: 50,
    title: '정보삭제',
    align: 'center',
  },
  {
    id: 'modify',
    minWidth: 180,
    title: '정보수정',
    align: 'center',
  },
];

export const authorizeTypes = [
  {id: 'MASTER', label: '마스터'},
  {id: 'GENERAL', label: '일반'},
];

export const statusType = [
  {id: '1', label: '승인'},
  {id: '0', label: '미승인'},
];
