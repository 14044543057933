import {BasicButton} from '@/components/Button';
import Icon_x from '@/components/Modal/icon__x.svg';
import {useContractDetail, useUpdateregister} from '@/hooks/room';
import {IRoom} from '@/interface';
import search from '@/page/ClientDetail/search.svg';
import theme from '@/styled-components/theme';
import React, {useEffect, useState} from 'react';
import DaumPostcode from 'react-daum-postcode';
import styled from 'styled-components';
import {DateSelector} from '../../components/DateSelector';
import {Dropdown} from '../../components/Dropdown';
import {TextField} from '../../components/TextField';
import Typography from '../../components/Typography';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {changeRoomDataSttate} from '@/stores/room';

interface IProps {
  data: IRoom;
}

interface IOption {
  id: number | undefined;
  label: string;
  value?: string;
}

const initialValue = {
  address: '',
  broker: '',
  brokerMobile: '',
  company: '',
  contractDate: null,
  contractType: null,
  detailAddress: '',
  endDate: null,
  id: null,
  lessor: '',
  lessorMobile: '',
  paymentDate: null,
  startDate: null,
  payType: null,
};

const options: IOption[] = [
  {
    id: 1,
    label: '신규',
    value: 'NEW',
  },
  {
    id: 2,
    label: '연장',
    value: 'CONTINUE',
  },
  {
    id: 3,
    label: '계약실패',
    value: 'FAIL',
  },
];

const POST_WIDTH = 500;
const POST_HEIGHT = 450;

const ContractInformTab = ({data}: IProps) => {
  const [selectedItem, setSelectedItem] = React.useState<IOption>({id: null, label: '선택'});
  const [open, setOpen] = useState<boolean>(false);
  const [getContractDetail, result] = useContractDetail();
  const [values, setValues] = React.useState<any>(initialValue);

  const [isChange, setIsChange] = useRecoilState(changeRoomDataSttate);

  useEffect(() => {
    if (data) {
      getContractDetail(data.id);
    }
  }, [data]);

  const handleDropdown = React.useCallback(
    (selected) => {
      if (selected.id) {
        setSelectedItem(selected);
        setValues({...values, ['contractType']: selected.value});
        setIsChange(true);
      } else {
        setSelectedItem({id: null, label: '선택'});
        setValues({...values, ['contractType']: null});
        setIsChange(true);
      }
    },
    [selectedItem, values],
  );

  const handleChange = React.useCallback(
    (type, value) => {
      setValues({...values, [type]: value});
      setIsChange(true);
    },
    [values],
  );

  const handleDateChange = React.useCallback(
    (type, date) => {
      setValues({...values, [type]: new Date(date).getTime()});
      setIsChange(true);
    },
    [values],
  );

  useEffect(() => {
    setIsChange(false);
  }, []);

  const execPostCode = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';
    if (data.userSelectedType === 'R') {
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setValues({
      ...values,
      address: fullAddress,
    });
    setOpen(false);
    setIsChange(true);
  };

  React.useEffect(() => {
    if (result?.data?.data) {
      setValues(result?.data?.data);
      if (result?.data?.data?.contractType) {
        setSelectedItem(options.find((x) => x.value === result?.data?.data?.contractType));
      }
    } else if (!result?.loading && result.error) {
      alert(result.error);
    }
  }, [result]);

  const handleOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({...values, payType: e.target.value});
    setIsChange(true);
  };

  const resetValues = () => {
    setValues({...initialValue, id: data.id});
    setSelectedItem({id: null, label: '선택'});
  };

  const [req, res] = useUpdateregister();
  const handleSumit = () => {
    req(values);
  };

  useEffect(() => {
    if (res.error && res.called) {
      alert(res.error);
    }
    if (res.called && res.data) {
      setIsChange(false);
      alert('수정이 완료되었습니다.');
    }
  }, [res.called, res.data, res.error]);

  return (
    <Root>
      <Title>계약정보</Title>
      <Container>
        <Label>계약구분</Label>
        <DropdownBox
          active={selectedItem}
          options={[{id: null, label: '선택'}, ...options]}
          onSelected={(selected) => handleDropdown(selected)}
        />
      </Container>
      <Container>
        <Label>계약일</Label>
        <DateSelector
          textHolder="날짜선택"
          value={values?.contractDate}
          onChange={(date) => handleDateChange('contractDate', date)}
        />
      </Container>
      <Container>
        <Label>임대차 계약기간</Label>
        <DateSelector
          textHolder="시작일"
          value={values?.startDate}
          onChange={(date) => handleDateChange('startDate', date)}
        />
        &nbsp;&nbsp;~&nbsp;&nbsp;
        <DateSelector
          textHolder="종료일"
          value={values?.endDate}
          onChange={(date) => handleDateChange('endDate', date)}
        />
      </Container>
      <Container>
        <Label>수수료 납부방법</Label>
        <input type="radio" name="payType" value="FULL" checked={values.payType === 'FULL'} onChange={handleOnchange} />
        일시납
        <input
          type="radio"
          name="payType"
          value="SPLIT"
          checked={values.payType === 'SPLIT'}
          onChange={handleOnchange}
        />
        분납
      </Container>
      <Container>
        <Label>주소</Label>
        <InputTextField
          isAddressInput={true}
          value={values?.address}
          onChange={(e) => handleChange('address', e.target.value)}
        />
        <CallApiBtn onClick={() => setOpen(true)}>
          <img src={search} />
        </CallApiBtn>
        {open && (
          <AdressModal>
            <CloseBtn onClick={() => setOpen(false)}>
              <img src={Icon_x} />
            </CloseBtn>
            <DaumPostcode
              onComplete={execPostCode}
              autoClose
              width={POST_WIDTH}
              height={POST_HEIGHT}
              style={{
                position: 'absolute',
                zIndex: 100,
                border: `5px solid ${theme.palette.primary}`,
              }}
            />
          </AdressModal>
        )}
      </Container>
      <Container>
        <Label>상세주소</Label>
        <InputTextField value={values?.detailAddress} onChange={(e) => handleChange('detailAddress', e.target.value)} />
      </Container>
      <Container>
        <Label>임대인 이름</Label>
        <InputTextField value={values?.lessor} onChange={(e) => handleChange('lessor', e.target.value)} />
      </Container>
      <Container>
        <Label>담당중개사</Label>
        <InputTextField value={values?.broker} onChange={(e) => handleChange('broker', e.target.value)} />
      </Container>
      <Container>
        <Label>중개사연락처</Label>
        <InputTextField value={values?.brokerMobile} onChange={(e) => handleChange('brokerMobile', e.target.value)} />
      </Container>
      <ButtonContainer>
        <BasicButton width="140" borderRadius={4} type="button" size="medium" onClick={resetValues}>
          초기화
        </BasicButton>
        <BasicButton
          width="140"
          borderRadius={4}
          type="button"
          size="medium"
          onClick={handleSumit}
          style={{marginLeft: '15px', background: '#1db177', color: '#fff'}}
        >
          등록 및 수정
        </BasicButton>
      </ButtonContainer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  width: 48%;
  flex-direction: column;
  max-height: 500px;
  margin: 0px 0px 12px 0px;
  padding-left: 12px;
`;

const Title = styled.span`
  font-size: 20px;
  color: #1db177;
  margin: 24px 0;
`;
const Container = styled.div`
  padding: 5px 12px 26px 0px;
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

const Label = styled(Typography)`
  min-width: 130px;
`;

const DropdownBox = styled(Dropdown)`
  width: calc(100% - 130px);
`;

const InputTextField = styled(({...props}) => <TextField allowSpace fullWidth {...props} />)`
  box-sizing: border-box;
  width: calc(100% - 130px);
`;

const CallApiBtn = styled.button`
  width: 15%;
  margin-left: 10px;
  border: 1px solid #818181;
  border-radius: 4px;
  background-color: #fff;
  color: white;
  height: 42px;
`;

const AdressModal = styled.div`
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 400px;
`;

const CloseBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -10px;
  width: 20px;
  height: 20px;
  z-index: 101;
  background-color: ${theme.palette.primary};
  cursor: pointer;
`;
export default ContractInformTab;
