import {useUpdateRoom} from '@/hooks/room';
import {IAccount, IRoom} from '@/interface';
import {
  getAddRequestTypesByValue,
  getAgeGroupByValue,
  getContactTimeValue,
  getGenderByValue,
  getJobTypeByValue,
  getMoveInDateTypeByValue,
  getProgressType,
  getRentCostGroupByValue,
  getRoomTypeByValue,
  header,
} from '@/page/ClientDetail/data';
import {roomState} from '@/stores/room';
import {userState} from '@/stores/session';
import moment from 'moment';
import React from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import styled from 'styled-components';
import {BasicButton} from '../../components/Button';
import {Retouch} from './Retouch';

interface IProps {
  data?: IRoom;
  catchChange: (isChange: boolean) => void;
}

export const ReceipDetail = ({data, catchChange}: IProps) => {
  const [changeVal, setChangeVal] = React.useState(false);
  const [values, setValues] = React.useState<IAccount>();
  const [user, setUser] = useRecoilState(userState);

  const [req, res] = useUpdateRoom();
  const room = useRecoilValue(roomState);

  const handleChange = () => {
    if (data !== null) {
      setChangeVal(true);
    }
  };
  const handleFixed = () => {
    setChangeVal(false);
    setValues({
      ...(user as IAccount),
    });

    req({
      contactTime: room.contactTime,
      id: room.id,
      moveInDateType: room.moveInDateType,
      rentCostGroup: room.rentCostGroup.split('만')[0],
      wishRegion: room.wishRegion,
    })
      .then(() => {
        alert('수정이 완료되었습니다.');
        window.location.reload();
      })
      .catch((e) => {
        alert(e);
      });
    catchChange(changeVal);
  };

  React.useEffect(() => {
    setValues({
      ...(user as IAccount),
    });
  }, []);

  return (
    <div>
      <Root>
        <Container>
          {changeVal === true ? (
            <Retouch data={data} />
          ) : (
            <Items>
              {header.map((x) => {
                return (
                  <ItemContainer key={x.id}>
                    <Title>{x.title}</Title>
                    {data !== null ? (
                      <Content>
                        {{
                          id: data[x.id], // 고객번호
                          createdAt: moment(data[x.id]).format('yyyy-MM-DD HH:mm:ss'), // 접수일시
                          progressType: getProgressType(data[x.id]), // 처리상태
                          userName: data[x.id], // 고객명
                          userEmail: data[x.id], // 이메일
                          userMobile: data[x.id], // 연락처
                          contactTime: x.id === 'contactTime' ? data[x.id].split(',').join(', ') : data[x.id], // 연락 가능시간
                          ageGroup: getAgeGroupByValue(data[x.id]), // 연령
                          gender: getGenderByValue(data[x.id]), // 성별
                          jobType: getJobTypeByValue(data[x.id]), //직업
                          fieldAdmin: data[x.id]?.name ?? '미지정', //담당자
                          rentCostGroup: getRentCostGroupByValue(data[x.id]), //희망월세
                          roomType: getRoomTypeByValue(data[x.id]), //방개수
                          moveInDateType: getMoveInDateTypeByValue(data[x.id]), //입주시기
                          // regionTypes: getRegionTypeByValue(data[x.id]), //희망지역
                          regionTypes: `${data.wishRegion
                            .split('/')
                            .filter((el, idx) => idx < 2)
                            .join('/')}`, //희망지역
                          addRequestTypes: getAddRequestTypesByValue(data[x.id]), //추가요청사항
                          requestMemo: data[x.id],
                        }[x.id] || ''}
                      </Content>
                    ) : (
                      <Content value={''} disabled={!changeVal} />
                    )}
                  </ItemContainer>
                );
              })}
            </Items>
          )}
        </Container>
      </Root>
      {values?.adminType === 'INTERNAL' ? (
        <ButtonContainer>
          <BasicButton borderRadius={4} type="button" size="medium" onClick={handleChange}>
            수정
          </BasicButton>
          <BasicButton
            borderRadius={4}
            type="button"
            size="medium"
            onClick={handleFixed}
            style={{marginLeft: '15px', background: '#1db177', color: '#fff'}}
          >
            적용
          </BasicButton>
        </ButtonContainer>
      ) : (
        ''
      )}
    </div>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 650px;
`;

const ItemContainer = styled.div`
  display: flex;
`;

const Container = styled.div`
  padding: 0px 0px 12px 0px;
  display: flex;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

const Items = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
`;

const Title = styled.div`
  min-width: 124px;
  padding: 7px 10px;
  display: flex;
  padding-left: 14px;
  background: #f8f8f8;
`;

const Content = styled.div`
  width: 100%;
  min-width: 300px;
  display: flex;
  padding-left: 12px;
  align-items: center;
  border: 0px;
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
  /* overflow-y: scroll;
  padding-top: 5px;
  height: 32px; */
`;
