import {css} from '@emotion/react';
import React from 'react';
import styled from 'styled-components';
import IconArrow from '../icon-12-arrowdown.svg';
import IconCheck from '../icon__check.svg';

export interface IOption {
  id: string | number;
  label: string | number;
  value?: string;
  checked?: boolean;
  enable?: boolean;
}

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  options: IOption[];
  active?: any;
  defaultOption?: IOption[];
  disabled?: boolean;
  placeholder?: string;
  size?: 'large' | 'basic';
  maxOptionsHeight?: number;
  /**
   * 값 선택 시 강조 UI 여부
   */
  showSelectHighlight?: boolean;

  onSelected?(selected: IOption): void;
  onDeleted?(lable: any): void;
  disableIds?: number[];
}

export const TimeSelect = ({
  defaultOption,
  active,
  options,
  disabled,
  size,
  maxOptionsHeight,
  placeholder,
  showSelectHighlight,
  onSelected,
  onDeleted,
  disableIds,
  ...props
}: IProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const isActive = React.useMemo(
    () => (showSelectHighlight && !disabled && active?.length !== 0 ? true : false),
    [showSelectHighlight, disabled, active, defaultOption],
  );

  const toggleClick = React.useCallback(() => {
    if (!disabled) {
      setOpen(!open);
    }
  }, [open, disabled, setOpen]);

  const handleSelect = React.useCallback(
    (selected) => () => {
      const check = active.filter((x) => x === selected.label);
      setOpen(false);
      if (check.length !== 0) {
        handleDelete(check[0]);
      } else if (active.length > 1) {
        alert('최대 2개 까지 선택이 가능합니다');
      } else if (check.length === 0) {
        onSelected(selected.label);
      }
    },
    [active, onSelected, setOpen],
  );

  const handleDelete = (label) => {
    onDeleted(label);
  };

  return (
    <Root {...props}>
      <Box disabled={disabled} active={isActive}>
        <Item onClick={toggleClick} size={size}>
          <div style={{gap: '8px', display: 'flex'}}>
            {active.length !== 0
              ? active.map((x, index) => (
                  <SelectedTIme css={selectedBox} key={index}>
                    {x}
                    <button onClick={() => handleDelete(x)}>x</button>
                  </SelectedTIme>
                ))
              : placeholder}
          </div>
          <Arrow />
        </Item>
      </Box>

      {!disabled && open && (
        <>
          <TransparentBackdrop onClick={toggleClick} />
          <List hasMargin={size !== 'large'} maxOptionsHeight={maxOptionsHeight}>
            {options.map((i) => (
              <Item
                key={i.id}
                onClick={handleSelect(i)}
                title={i.label}
                disable={disableIds?.length && disableIds.includes(i.id as number)}
              >
                <span color={'#000000'}>{i.label}</span>
                {active.filter((x) => x === i.label).length !== 0 && <Check />}
              </Item>
            ))}
          </List>
        </>
      )}
    </Root>
  );
};

const SelectedTIme = styled.button`
  cursor: pointer;
  background-color: #f4f4f5;
  color: white;
  font-weight: 500;
  padding-left: 10px;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  color: #92959b;

  button {
    color: #92959b;
    border-radius: 50%;
    font-weight: 500;
    margin-left: 6px;
    border: none;
    width: 12px;
    height: 11.5px;
    cursor: pointer;
  }
`;

export const TransparentBackdrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Root = styled.div`
  width: 330px;
  position: relative;
`;

const Item = styled(({size, ...props}) => <div {...props} />)`
  height: ${({size}) => (size === 'large' ? 44 : 34)}px;
  background-color: ${({disable}) => (disable ? '#F8F9FA' : '#fff')};
  cursor: pointer;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;

  &:hover {
    ${({disable}) => (!disable ? `background-color: '#f8f8f8';` : `cursor: not-allowed;`)}
  }
`;

const Item2 = (size?: string) => css`
  height: ${size && size === 'large' ? 44 : 34}px;
  cursor: pointer;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Box = styled(({active, disabled, ...props}) => <div {...props} />)`
  border-radius: 2px;
  border: solid 1px #cdd5e1;
  background-color: #fff;
  ${({disabled}) =>
    disabled &&
    `
    background-color: #BBF0DB;
    ${Item} {
      opacity: 0.2;
      cursor: default;
    }
  `}
  ${({active}) =>
    active &&
    `
    border-color: #000000;
    background-color: #fff;
  `}
`;

const Icon = styled.div`
  flex-shrink: 0;
  background-position: center;
  background-repeat: no-repeat;
`;

const Arrow = styled(Icon)`
  background-image: url(${IconArrow});
  width: 12px;
  height: 12px;
`;

const selectedBox = css`
  padding: 5px 5px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  background-color: #f5f5f5;

  button {
    border: none;
    color: #818181;
    font-size: 18px;
    cursor: pointer;
    background-color: #f5f5f5;
    &:hover {
      color: #818181;
    }
  }
`;

const List = styled(({hasMargin, maxOptionsHeight, ...props}) => <Box {...props} />)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  ${({hasMargin}) => (hasMargin ? `margin-top: 6px;` : `margin-top: -1px;`)}
  ${({maxOptionsHeight}) =>
    maxOptionsHeight > 0 &&
    `
    max-height: ${maxOptionsHeight}px;
    overflow: auto;
  `}
`;

const Check = styled(Icon)`
  background-image: url(${IconCheck});
  width: 16px;
  height: 16px;
`;
