import {IOption} from '@/components/Dropdown';
import {IPageable, IRoom} from '@/interface';
import {IAccount} from '@/interface/account';
import {IPageType, valueProps} from '@/page/ReceiptHistory';
import {atom} from 'recoil';
import {recoilPersist} from 'recoil-persist';
export const {persistAtom} = recoilPersist();

export const roomListState = atom<{
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: IPageable;
  size: number;
  content: IRoom[];
  totalElements: number;
  totalPages: number;
}>({
  default: {
    empty: false,
    first: true,
    last: true,
    pageable: {
      offset: 0,
      pageNumber: 1,
      pageSize: 1,
      paged: false,
      unpaged: false,
    },
    number: 0,
    numberOfElements: 0,
    size: 0,
    content: [],
    totalElements: 0,
    totalPages: 0,
  },
  key: 'room/list/state',
  effects_UNSTABLE: [persistAtom],
});

export const accountListState = atom<{
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: IPageable;
  size: number;
  content: IAccount[];
  totalElements: number;
  totalPages: number;
}>({
  default: {
    empty: false,
    first: true,
    last: true,
    pageable: {
      offset: 0,
      pageNumber: 1,
      pageSize: 1,
      paged: false,
      unpaged: false,
    },
    number: 0,
    numberOfElements: 0,
    size: 0,
    content: [],
    totalElements: 0,
    totalPages: 0,
  },
  key: 'account/list/state',
  effects_UNSTABLE: [persistAtom],
});

export const userMobileState = atom<{userMobile: string}>({
  key: 'userMobile',
  default: {
    userMobile: '',
  },
});

export const roomState = atom<IRoom>({
  key: 'roomByUser',
  default: {} as IRoom,
});

export const changeRoomDataSttate = atom<boolean>({
  key: 'roomDataChange',
  default: false,
});

export const historyValueState = atom<valueProps>({
  key: 'historyValue',
  default: {
    page: 1,
    sort: 'id',
  },
});

export const historySelectedState = atom<string>({
  key: 'historySelected',
  default: '20',
});

interface IHeaderState {
  isDetail: boolean;
  name: string;
}

export const headerState = atom<IHeaderState>({
  key: 'headerState',
  default: {
    isDetail: false,
    name: '',
  },
});

export const pageTypeState = atom<IPageType>({
  key: 'pageTypeState',
  default: 'normal',
});
