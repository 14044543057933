import theme from '@/styled-components/theme';
import React from 'react';
import styled from 'styled-components';
import Portal from '../Portal';

import {Backdrop, ModalContainer} from './components';
import IconX from './icon__x.svg';
import {ModalProps} from './index.d';

export const Modal = ({title, width, fullWidth, print, onClose, ...props}: ModalProps) => {
  return (
    <Portal>
      <>
        <Backdrop />
        <ModalContainer>
          <Wrapper width={width} fullWidth={fullWidth} print={print}>
            {!!title?.length && (
              <Header width={width}>
                {title} {!!onClose && <Close onClick={onClose} />}
              </Header>
            )}
            {!title?.length && (
              <Header width={width}>
                {!!onClose && <Close onClick={onClose} />}
              </Header>
            )}
            <Body {...props} />
          </Wrapper>
        </ModalContainer>
      </>
    </Portal>
  );
};

const Wrapper = styled(({width, fullWidth, print, ...props}) => <div {...props} />)<ModalProps>`
  flex-grow: 1;
  position: relative;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 10px;
  padding: 0 16px 16px 16px;
  background-color: ${theme.palette.white};
  box-shadow: 0 2px 4px 0 ${theme.palette.disabled};
  width: 21cm;
  ${({fullWidth, width}) => !fullWidth && `max-width: ${width}px;`}
  ${({print}) =>
    print &&
    `width: 21cm;
    `}
`;

const Header = styled.header<{width: number}>`
  background-color: ${theme.palette.white};
  position: fixed;
  ${({width}) => width && `width: ${width}px;`};
  padding-top: 16px;
  border-bottom: 1px solid #f2f2f2;
  color: ${theme.palette.primary};
  min-height: 46px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.27px;
  z-index: 2;
`;

const Body = styled.div`
  padding-top: 56px;
  width: 100%;
`;

export const Close = styled.div`
  position: absolute;
  right: 0px;
  top: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url(${IconX});
  background-position: center;
  :active {
    opacity: 0.5;
  }
`;
