import theme from '@/styled-components/theme';
import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {sideMenu} from '../../../constants';
import {NAV_HEIGHT} from '@/styled-components/constants';
import {Typography} from '@/components/Typography';
import {useRecoilValue} from 'recoil';
import {useSession} from '@/hooks/session';
import logo from './main_logo.svg';

export const SideMenu = () => {
  const {user} = useSession();
  const [currMenu, setCurrMenu] = useState<string>(location.pathname);

  useEffect(() => {
    if (location.pathname === '/') {
      setCurrMenu('/receiptHistory');
    }
  }, [location.pathname]);

  return (
    <Root>
      <Menu>
        <img src={logo} alt="로고" />
        {sideMenu.map((i) => {
          if (i.id === 'Account' && user.authorizeType !== 'MASTER') {
            return <></>;
          }
          return (
            <Item key={i.path} to={i.path} color={currMenu === i.path}>
              <Typography variant="body1" color={theme.palette.white} fontWeight="500">
                {i.label}
              </Typography>
            </Item>
          );
        })}
      </Menu>
    </Root>
  );
};

const Root = styled.div`
  width: 100px;
  height: 100%;
  overflow: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  background-color: ${({theme}) => theme.palette.primary};

  img {
    margin: 55px 0px 74px 0px;
  }
`;

const Item = styled(NavLink)<{color: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${NAV_HEIGHT.sm}px;
  background-color: ${({color}) => (color ? '#419266' : 'none')};
`;
