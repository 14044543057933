import {Layout} from '@/components/Layout';
import Typography from '@/components/Typography';
import {BasicButton} from '@/components/Button';
import {Flexbox} from '@/components/styledComponents/Common';
import {Dropdown} from '@/components/Dropdown';
import {TextField} from '@/components/TextField';
import React from 'react';
import styled from 'styled-components';
import {accountSearchType, adminType, status, authorizeType} from '../../constants/filters';
import {header, masterHeader} from './data';
import {List} from './List';
import Spinner from '@/common/Spinner';
// import {Pagination} from '@/components/Pagination';
import PaginationContainer from '@/components/NewPagination';
import {useRecoilState} from 'recoil';
import {accountListState, headerState} from '@/stores/room';
import {useAccountList, useMemberUpdateList} from '@/hooks/room';
import {convertToObject} from 'typescript';
import {IAccount} from '@/interface';
import {useSession} from '@/hooks/session';
import {useHistory} from 'react-router';
import {pathname} from '@/constants';
import theme from '@/styled-components/theme';
import selected from '@/page/ReceiptHistory/btn_radio_selected.svg';
import {start} from 'repl';

interface valueProps {
  adminType?: string;
  authorizeType?: string;
  status?: string;
  page?: number;
}

const initialValue = {
  adminType: null,
  authorizeType: null,
  status: null,
  page: 1,
};

export const Account = () => {
  const [values, setValues] = React.useState<valueProps>(initialValue);
  const [searchType, setSearchType] = React.useState(accountSearchType[0].id);
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [onFilter, setOnFilter] = React.useState<boolean>(false);
  const [data, setAccountList] = useRecoilState(accountListState);
  const [loadData, setOnLoadData] = React.useState<IAccount[]>();
  const [request, result] = useAccountList();
  const {user} = useSession();
  const history = useHistory();
  const [update, updateResult] = useMemberUpdateList();

  const selectedSearchType = React.useMemo(() => accountSearchType?.find((i) => i.id === searchType), [searchType]);

  const selectedAuthorizeType = React.useMemo(
    () => authorizeType?.find((i) => i.id === values.authorizeType),
    [values.authorizeType],
  );

  const selectedAdminType = React.useMemo(() => adminType?.find((i) => i.id === values.adminType), [values.adminType]);

  // const selectedStatus = React.useMemo(() => status?.find((i) => i.id === values.status), [values.status]);

  const [selectedStatus, setState] = React.useState();
  const [headerValue, setHeaderValue] = useRecoilState(headerState);

  React.useEffect(() => {
    setHeaderValue({...headerValue, isDetail: false});
    if (user?.authorizeType !== 'MASTER') {
      // alert('마스터 권한만 사용할 수 있습니다.');
      history.replace(pathname.RECEIPT_HISTORY);
      return;
    }
    requestQuery();
  }, []);

  React.useEffect(() => {
    if (!result.loading && result.error) {
      alert(result.error);
      return;
    }
    setAccountList(result.data?.data || []);
  }, [result]);

  const validation = () => {
    const values_Keys = Object.keys(values);
    const initialValue_keys = Object.keys(initialValue);
    if (values_Keys.length !== initialValue_keys.length) {
      return setOnFilter(false);
    }
    for (const i of values_Keys) {
      if (values[i] != initialValue[i]) {
        return setOnFilter(false);
      }
    }
    return setOnFilter(true);
  };

  React.useEffect(() => {
    validation();
  }, [values]);

  const handleRadio = React.useCallback(
    (type, selected) => {
      setValues({...values, ['status']: selected});
      setState(selected);
    },
    [values],
  );

  const handleChangeText = React.useCallback(
    (e) => {
      const {target} = e;
      setSearchKeyword(target?.value);
      setValues({...values, [searchType]: target?.value});
    },
    [values, searchType],
  );

  const handleDropdown = React.useCallback(
    (type, selected) => {
      setValues({...values, [type]: selected.id});
    },
    [values, searchType, searchKeyword, authorizeType],
  );

  const handleSearchType = React.useCallback(
    (selected) => {
      setSearchType(selected.id);
    },
    [searchType, values],
  );

  const handleReset = React.useCallback(() => {
    setValues(initialValue);
    setSearchType(accountSearchType[0].id);
    setSearchKeyword('');
    setState(undefined);
  }, [selectedStatus]);

  const handleSubmit = React.useCallback(() => {
    requestQuery();
  }, [searchType, searchKeyword, values]);

  const handlePageChanage = React.useCallback((page) => {
    requestQuery(page);
  }, []);

  const requestQuery = React.useCallback(
    (page: number = 1) => {
      request(values, page - 1);
    },
    [values, searchType, searchKeyword],
  );

  const handleReload = React.useCallback(() => {
    requestQuery(data?.pageable?.pageNumber + 1);
  }, [data]);

  const handleUpdate = React.useCallback(() => {
    update(loadData);
  }, [loadData]);

  React.useEffect(() => {
    setOnLoadData(data?.content);
  }, [data?.content]);

  React.useEffect(() => {
    if (!updateResult.loading && updateResult.called) {
      alert('업데이트 되었습니다.');
      requestQuery();
    } else if (!updateResult.loading && updateResult.error) {
      alert(updateResult.error);
    }
  }, [updateResult]);

  return (
    <Layout category="계정관리" page="계정관리">
      <Contents>
        <Filter>
          <Row>
            <Column>
              <Typography as="label" variant="body1">
                담당자 구분
              </Typography>
              <DropdownBox
                active={selectedAdminType || {id: null, label: '전체'}}
                options={[{id: null, label: '전체'}, ...adminType]}
                onSelected={(selected: any) => handleDropdown('adminType', selected)}
              />
            </Column>
            <Column>
              <Typography as="label" variant="body1">
                상태
              </Typography>
              <RadioContainer>
                <label htmlFor="accept">
                  <input
                    id="accept"
                    type="radio"
                    checked={selectedStatus === true}
                    onChange={(e) => handleRadio('FAIL', e.target.checked)}
                  />
                  승인
                </label>
              </RadioContainer>
              <RadioContainer>
                <input
                  id="unAccept"
                  type="radio"
                  checked={selectedStatus === false}
                  onChange={(e) => handleRadio('FAIL', !e.target.checked)}
                />
                <label htmlFor="unAccept">
                  <input
                    id="unAccept"
                    type="radio"
                    checked={selectedStatus === false}
                    onChange={(e) => handleRadio('FAIL', !e.target.checked)}
                  />
                  미승인
                </label>
              </RadioContainer>
            </Column>
          </Row>
          <Row>
            <Column>
              <Typography as="label" variant="body1">
                권한
              </Typography>

              <DropdownBox
                active={selectedAuthorizeType || {id: null, label: '전체'}}
                options={[{id: null, label: '전체'}, ...authorizeType]}
                onSelected={(selected) => handleDropdown('authorizeType', selected)}
              />
            </Column>
            <Column>
              <Typography as="label" variant="body1">
                검색
              </Typography>
              <DropdownBox active={selectedSearchType} options={accountSearchType} onSelected={handleSearchType} />
              <SearchBox value={searchKeyword} onChange={handleChangeText} />
            </Column>
          </Row>
        </Filter>
        <ButtonContainer>
          <BasicButton borderRadius={4} type="button" size="medium" onClick={handleReset}>
            초기화
          </BasicButton>
          {onFilter ? (
            <BasicButton borderRadius={4} type="button" size="medium" colorType="darkGreen" onClick={handleSubmit}>
              검색
            </BasicButton>
          ) : (
            <BasicButton borderRadius={4} type="button" size="medium" colorType="primary" onClick={handleSubmit}>
              검색
            </BasicButton>
          )}
        </ButtonContainer>

        <ListInfoBlock>
          <span>
            검색결과 (총 <span className="highlight">{data?.totalElements}</span>건)
          </span>
        </ListInfoBlock>

        {result.loading ? (
          <Spinner />
        ) : (
          <ListContainer>
            <ListBox>
              {data && (
                <List
                  header={[...header, ...(user.authorizeType === 'MASTER' ? masterHeader : [])]}
                  items={data?.content || []}
                  onLoad={handleReload}
                  onSetValue={setOnLoadData}
                  totalElements={data?.totalElements}
                  size={20}
                  number={data?.number}
                />
              )}
            </ListBox>
          </ListContainer>
        )}

        <FooterContainer>
          <ApplyButton>
            <Typography as="label" variant="body2">
              권한과 상태를 설정한 뒤 적용버튼을 누르세요.
            </Typography>
            <BasicButton type="button" size="medium" colorType="primary" onClick={handleUpdate}>
              적용
            </BasicButton>
          </ApplyButton>
          <PaginationContainer
            activePage={data?.pageable?.pageNumber + 1}
            itemsCountPerPage={20}
            totalItemsCount={data?.totalElements}
            pageRangeDisplayed={data?.pageable?.pageSize || 1}
            onPageChange={handlePageChanage}
          />
          {/* <Pagination
            initialPage={data?.pageable?.pageNumber + 1}
            pageCount={data?.totalPages}
            onPageChange={handlePageChanage}
            pageRangeDisplayed={data?.pageable?.pageSize || 1}
          /> */}
        </FooterContainer>
      </Contents>
    </Layout>
  );
};

const Contents = styled.div`
  /* padding: 20px; */
  width: 100%;
`;

const Filter = styled(Flexbox)`
  flex-direction: column;
  align-items: flex-start;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0px;
  background-color: ${({theme}) => theme.palette.grey};
  border-top: 1px solid ${({theme}) => theme.palette.borderGray};
  & + & {
    border-bottom: 1px solid ${({theme}) => theme.palette.borderGray};
  }
`;

const Column = styled(Flexbox)`
  width: 100%;
  padding-left: 30px;
  label {
    width: 120px;
  }
`;

const DropdownBox = styled(Dropdown)`
  width: 145px;
  margin-right: 16px;
`;

const SearchBox = styled.input`
  width: 300px;
  height: 38px;
  outline: none;
  border: 1px solid ${theme.palette.borderGray};
  border-radius: 4px;
  padding-left: 14px;
  box-sizing: border-box;
  font-size: 13px;
  line-height: 19px;
`;

const ListContainer = styled.div`
  overflow-x: auto;
`;

const ListBox = styled.div`
  overflow-y: visible;
  padding-bottom: -50px;
  margin-bottom: 50px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
  button {
    margin: 0 10px;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const ApplyButton = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  button {
    margin-left: 10px;
  }
`;

const RadioContainer = styled.div`
  font-size: 14px;
  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: none;
  }

  label {
    cursor: pointer;
    padding-right: 15px;
    color: ${theme.palette.blackLighter};
  }

  label input[type='radio']:after {
    content: '';
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-right: 10px;
    margin-bottom: 5px;
    border: 1px solid #eaeaea;
    background-color: #fff;
    border-radius: 100%;
    vertical-align: middle;
    cursor: pointer;
  }
  label input[type='radio']:checked:after {
    background: url(${selected}) 0 0 no-repeat;
    background-size: contain;
    border: 1px solid ${theme.palette.grey};
  }
`;

const ListInfoBlock = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;

  & > span {
    .highlight {
      color: #1db177;
    }
    &:nth-child(1) {
      color: #333333;
      font-size: 14px;
    }
    &:nth-child(2) {
      color: #818181;
      font-size: 12px;
    }
  }
`;
