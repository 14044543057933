import React, {useState} from 'react';
import styled from 'styled-components';
import {useRecoilState} from 'recoil';
import {changeRoomDataSttate} from '@/stores/room';

export type TabsProps = {
  menus: MenuProps[];
  children: React.ReactChild | React.ReactChild[];
  handleMenu: (index: number) => void;
};

export type MenuProps = {
  id: number;
  label: string;
};

function Tabs({menus, children, handleMenu}: TabsProps) {
  const [selected, setSelected] = useState(0);

  const [isChange, setIsChange] = useRecoilState(changeRoomDataSttate);

  const handleSelected = (index: number) => {
    if (isChange) {
      const result = confirm('탭 이동 시 저장되지 않은 데이터는 삭제됩니다. 이동하시겠습니까? ');
      if (result) {
        setSelected(index);
        handleMenu(index);
        setIsChange(false);
      }
    } else {
      setSelected(index);
      handleMenu(index);
    }
  };
  return (
    <RightBlock>
      <Menus>
        {menus &&
          menus.map((menu, index) => (
            <Menu
              key={index}
              className={`${selected === index ? 'active' : ''}`}
              onClick={() => {
                handleSelected(index);
              }}
            >
              {menu.label}
            </Menu>
          ))}
      </Menus>
      <div>{children}</div>
    </RightBlock>
  );
}

const RightBlock = styled.section`
  width: 100%;
`;

const Menus = styled.ul`
  list-style: none;
  display: flex;
  border-bottom: 3px solid #e5e5e5;
  margin: 0;
  padding: 0;

  .active {
    color: #1db177;
    border-bottom: 4px solid #1db177;
  }
`;

const Menu = styled.li<{selected: number}>`
  font-weight: 500;
  font-size: 14px;
  width: 122px;
  height: 36px;
  text-align: center;
  cursor: pointer;
`;

export default Tabs;
