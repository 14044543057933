import React from 'react';
import {Route} from './components/Router/Route';
import {routers} from './constants';
import {BrowserRouter as Router, Switch} from 'react-router-dom';

function App() {
  return (
    //error, toast, History stack
    <Router>
      <Switch>
        {Object.values(routers).map((item) => (
          <Route
            path={item.path}
            component={item.component}
            key={item.path}
            exact={item.exact}
            isPrivate={item.private}
          />
        ))}
      </Switch>
    </Router>
  );
}

export default App;
