import {Redirect, RedirectProps, RouteComponentProps} from 'react-router-dom';
import {SignIn} from '../page/SignIn';
import {ReceiptHistory} from '../page/ReceiptHistory';
import {Account} from '../page/Account';
import {pathname} from './pathname';
import {SignUp} from '@/page/Signup';
import {ClientDetal} from '@/page/ClientDetail';

interface IRouteItem {
  path: string;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  private?: boolean;
  exact?: boolean;
}

export interface IRouteComponentProps
  extends JSX.IntrinsicAttributes,
    JSX.IntrinsicClassAttributes<Redirect>,
    Readonly<RedirectProps>,
    Readonly<{children?: React.ReactNode}> {}

export const routers: {[id: string]: IRouteItem} = {
  RECEIPT_HISTORY: {
    path: pathname.RECEIPT_HISTORY,
    private: true,
    exact: true,
    component: ReceiptHistory,
  },
  CLIENT_DETAIL: {
    path: pathname.CLIENT_DETAIL,
    private: true,
    exact: true,
    component: ClientDetal,
  },

  ACCOUNT: {
    path: pathname.ACCOUNT,
    private: true,
    exact: true,
    component: Account,
  },
  MAIN: {
    path: pathname.MAIN,
    private: true,
    exact: true,
    component: ReceiptHistory,
  },
  SIGN_IN: {
    path: pathname.SIGN_IN,
    private: false,
    exact: true,
    component: SignIn,
  },
  SIGN_UP: {
    path: pathname.SIGN_UP,
    private: false,
    exact: true,
    component: SignUp,
  },
};
