import Spinner from '@/common/Spinner';
import {BasicButton} from '@/components/Button';
import {DateSelector} from '@/components/DateSelector';
import {Dropdown} from '@/components/Dropdown';
import {Layout} from '@/components/Layout';
import PaginationContainer from '@/components/NewPagination';
import {DownLoading} from '@/components/Popup/DownLoading';
import {Flexbox} from '@/components/styledComponents/Common';
import Typography from '@/components/Typography';
import {
  useContinueList,
  useGetDashBoard,
  useGetExcelData,
  useMonthList,
  usePayTypeList,
  useRoomSearch,
} from '@/hooks/room';
import {useSession} from '@/hooks/session';
import {historySelectedState, historyValueState, headerState, roomListState, pageTypeState} from '@/stores/room';
import theme from '@/styled-components/theme';
import {getWeek} from '@/utils/date';
import {useBreakpoints} from '@/utils/media';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useRecoilState} from 'recoil';
import styled from 'styled-components';
import XLSX from 'xlsx';
import {receiptSearchType} from '../../constants/filters';
import selected from './btn_radio_selected.svg';
import {
  FIELDTYPES,
  getAddRequestTypesByValue,
  getAgeGroupByValue,
  getFieldProgressType,
  getGenderByValue,
  getJobTypeByValue,
  getMoveInDateTypeByValue,
  getProgressType,
  getRegionTypeByValue,
  getRentCostGroupByValue,
  getRoomTypeByValue,
  header,
  masterHeader,
  STATUS,
} from './data';
import ic_excel from './ic_excel.svg';
import './index.d';
import {List} from './List';
import time from './time.svg';

export interface valueProps {
  startDate?: Date | number;
  endDate?: Date | number;
  page: number;
  adminName?: string;
  name?: string;
  regionKeyword?: string;
  progressType?: 'ACCEPT' | 'ASSIGN' | 'COMPLETE' | 'FAIL' | null;
  fieldProgressType?: 'NORMAL' | 'MISSED' | 'VIEWING' | 'FINISH_VIEWING' | 'NOT_PROGRESS' | null;
  sort?: string;
}

export type IPageType = 'continue' | 'month1' | 'month2' | 'pay1' | 'pay2' | 'normal';

const initialValue = {
  // progressType: null,
  // startDate: null,
  // endDate: undefined,
  page: 1,
  // fieldProgressType: null,
  // adminName: null,
  // name: null,
  // regionKeyword: null,
  sort: 'id',
  // startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()).getTime(),
  // endDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime(),
};

export const ReceiptHistory = () => {
  const [request, result] = useRoomSearch();
  const [data, setRoomList] = useRecoilState(roomListState);
  const {user} = useSession();
  const [values, setValues] = useRecoilState(historyValueState);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [fileName, setFileName] = React.useState<string>();
  const [searchType, setSearchType] = React.useState(receiptSearchType[0].id);
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [requestExcel, ResultExcel] = useGetExcelData();
  const [onFilter, setOnFilter] = useState<boolean>(false);
  const {isSmall} = useBreakpoints();
  const selectedSearchType = React.useMemo(() => receiptSearchType?.find((i) => i.id === searchType), [searchType]);
  const [pageType, setPageType] = useRecoilState(pageTypeState);
  const [continueReq, continueRes] = useContinueList();
  const [monthReq, monthRes] = useMonthList();
  const [payTypeReq, payTypeRes] = usePayTypeList();

  // const selectedProgressType = React.useMemo(
  //   () => progressType?.find((i) => i.id === values.progressType),
  //   [values.progressType],
  // );
  const [selectedProgressType, setProgressType] = React.useState<string>();
  const [selectedFieldProgressType, setFieldProgressType] = React.useState<string>();
  const [headerValue, setHeaderValue] = useRecoilState(headerState);

  const [selected, setSelected] = useRecoilState(historySelectedState);

  React.useEffect(() => {
    if (values.fieldProgressType) {
      setFieldProgressType(values.fieldProgressType);
    }
    if (values.progressType) {
      setProgressType(values.progressType);
    }
  }, []);

  const requestQuery = React.useCallback(
    (page: number = values.page) => {
      request(values, page - 1, +selected);
    },
    [values, searchType, searchKeyword, selected],
  );

  React.useEffect(() => {
    setHeaderValue({...headerValue, isDetail: false});
    if (pageType === 'normal') {
      requestQuery();
    } else {
      handlePageChanage(values.page);
    }
  }, [selected]);

  const validation = () => {
    const values_Keys = Object.keys(values);
    const initialValue_keys = Object.keys(initialValue);
    if (values_Keys.length !== initialValue_keys.length) {
      return setOnFilter(false);
    }
    for (const i of values_Keys) {
      if (values[i] != initialValue[i]) {
        return setOnFilter(false);
      }
    }
    return setOnFilter(true);
  };

  React.useEffect(() => {
    validation();
  }, [values]);

  React.useEffect(() => {
    if (!result?.loading && result.error) {
      alert(result.error);
      return;
    }
    if (result.data) {
      setRoomList(result.data.data);
    }
  }, [result]);

  React.useEffect(() => {
    if (values.startDate > values.endDate) {
      alert('종료일은 시작일보다 커야합니다.');
      setValues({...values, endDate: undefined});
    }
  }, [values.startDate, values.endDate]);

  const handleDateChange = React.useCallback(
    (type, date) => {
      setValues({...values, [type]: typeof date !== 'number' ? date?.getTime() : date});
    },
    [values],
  );

  const handleChangeText = React.useCallback(
    (e) => {
      const {target} = e;
      setSearchKeyword(target?.value);
      setValues({...values, [searchType]: target?.value});
    },
    [searchType, values],
  );

  const handleProgressType = React.useCallback(
    (type, selected) => {
      setValues({...values, ['progressType']: type});
      setProgressType(type);
    },
    [values],
  );
  const handleFieldType = React.useCallback(
    (type, selected) => {
      setValues({...values, ['fieldProgressType']: type});
      setFieldProgressType(type);
    },
    [values],
  );

  const handlePageChanage = React.useCallback(
    (page) => {
      setValues({...values, page});
      if (pageType === 'normal') {
        const currPage = result?.data?.data?.pageable.pageNumber + 1;
        if (currPage !== page) {
          requestQuery(page);
        }
      } else if (pageType === 'pay2') {
        const params = {sort: 'id', startDate: values.startDate, endDate: values.endDate, payType: 'SPLIT'};
        payTypeReq(params, page - 1, +selected);
      } else if (pageType === 'pay1') {
        const params = {sort: 'id', startDate: values.startDate, endDate: values.endDate, payType: 'FULL'};
        payTypeReq(params, page - 1, +selected);
      } else if (pageType === 'continue') {
        const params = {sort: 'id', startDate: values.startDate, endDate: values.endDate};
        continueReq(params, page - 1, +selected);
      } else if (pageType === 'month1') {
        const params = {sort: 'id', startDate: values.startDate, endDate: values.endDate, month: 1};
        monthReq(params, page - 1, +selected);
      } else if (pageType === 'month2') {
        const params = {sort: 'id', startDate: values.startDate, endDate: values.endDate, month: 2};
        monthReq(params, page - 1, +selected);
      }
    },
    [result, payTypeRes, continueRes, monthRes, selected, pageType],
  );

  const handleSearchType = React.useCallback(
    (selected) => {
      setSearchType(selected.id);
    },
    [searchType, values],
  );

  // useEffect(() => {
  //   const newObj = values;
  //   delete newObj.adminName;
  //   delete newObj.name;
  //   delete newObj.regionKeyword;
  //   setValues(newObj);
  // }, [searchType]);

  const handleReset = React.useCallback(() => {
    setValues(initialValue);
    setSearchType(receiptSearchType[0].id);
    setSearchKeyword('');
    setProgressType(null);
    setFieldProgressType(null);
  }, []);

  const handleSubmit = React.useCallback(() => {
    setPageType('normal');
    requestQuery(1);
    const start = values.startDate ? (values.startDate as number) : null;
    const end = values.endDate ? (values.endDate as number) : null;
    req(start, end);
  }, [values, searchType, searchKeyword, selectedProgressType, selectedFieldProgressType]);

  const handleReload = React.useCallback(
    (page) => {
      requestQuery(page || data?.pageable?.pageNumber + 1);
    },
    [data],
  );

  const handleDownload = React.useCallback(() => {
    const queryURl = Object.entries(values)
      .map((e) => e.join('='))
      .join('&');
    requestExcel(queryURl);
    setFileName(`어드민_접수내역_${values.page}_${moment().format('YYYY-MM-DD')}.xlsx`);
    setIsLoading(true);
  }, [values]);

  React.useEffect(() => {
    if (!ResultExcel.called && ResultExcel.error) {
      alert(ResultExcel.error);
      return;
    }
    if (!ResultExcel.called && ResultExcel.data) {
      const data = ResultExcel.data.data;
      const exceldata = data.map(
        (x: {
          id: any;
          addrDong: string;
          addrGu: string;
          addrSi: string;
          createdAt: moment.MomentInput;
          progressType: any;
          fieldProgressType: any;
          userName: any;
          ageGroup: string;
          gender: string;
          jobType: string;
          userMobile: any;
          rentCostGroup: string;
          roomType: string;
          moveInDateType: string;
          regionTypes: string[];
          addRequestTypes: string[];
          requestMemo: any;
          internalAdmin: {name: any};
          fieldAdmin: {name: any};
        }) => ({
          no: x.id,
          접수일시: moment(x.createdAt).format('YYYY-MM-DD HH:MM'),
          처리상태: getProgressType(x.progressType),
          현장진행상태: getFieldProgressType(x.fieldProgressType),
          고객명: x.userName,
          나이: getAgeGroupByValue(x.ageGroup),
          성별: getGenderByValue(x.gender),
          직업: getJobTypeByValue(x.jobType),
          연락처: x.userMobile,
          희망월세: getRentCostGroupByValue(x.rentCostGroup),
          희망방개수: getRoomTypeByValue(x.roomType),
          희망입주시기: getMoveInDateTypeByValue(x.moveInDateType),
          희망지역: x.regionTypes.length !== 0 ? getRegionTypeByValue(x.regionTypes) : `${x.addrSi}/${x.addrGu}`,
          추가요청사항: getAddRequestTypesByValue(x.addRequestTypes),
          기타사항: x.requestMemo,
          내부담당자: x.internalAdmin?.name,
          현장담당자: x.fieldAdmin?.name,
          // 메모: x.memoList,
        }),
      );

      const getMemo = [];
      for (const x of data) {
        const list = [];
        const reversList = x.memoList.reverse();
        for (let i = x.memoList.length - 1; i >= 0; i--) {
          list.push({
            ['메모' + (i + 1)]: `(${moment(reversList[i].createdAt * 1000).format('YYYY-MM-DD')})${
              reversList[i].contents
            }`,
          });
        }

        getMemo.push(list.reverse());
      }

      for (let i = 0; i < exceldata.length; i++) {
        for (let j = 0; j < getMemo[i].length; j++) {
          exceldata[i] = {...exceldata[i], ...getMemo[i][j]};
        }
      }

      const wb = XLSX.utils.book_new();

      const ws = XLSX.utils.json_to_sheet(exceldata);

      XLSX.utils.book_append_sheet(wb, ws, '접수내역');

      XLSX.writeFile(wb, `어드민_접수내역_${values.page}_${moment().format('YYYY-MM-DD')}.xlsx`);

      setIsLoading(false);
    }
  }, [ResultExcel]);

  // Dashboard
  const [req, res] = useGetDashBoard();
  const [dashBoardDataset, setDashBoardDataset] = useState(null);

  useEffect(() => {
    if (!values.startDate && !values.endDate) {
      req();
    } else {
      req(values.startDate as number, values.endDate as number);
    }
  }, []);

  useEffect(() => {
    if (res.called && res.data) {
      setDashBoardDataset(res.data.data);
    }
  }, [res.called, res.data]);

  const onClickHandleDate = (type: string) => {
    const currentDay = new Date();
    const {weekStartDate, weekEndDate} = getWeek(currentDay);
    switch (type) {
      case 'day':
        setValues({...values, startDate: currentDay.getTime(), endDate: currentDay.getTime()});
        break;
      case 'week':
        setValues({...values, startDate: weekStartDate.getTime(), endDate: weekEndDate.getTime()});
        break;
      case 'month':
        setValues({
          ...values,
          startDate: new Date(currentDay.getFullYear(), currentDay.getMonth() - 1, currentDay.getDate()).getTime(),
          endDate: new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate()).getTime(),
        });
        break;
      default:
        console.log('ends');
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelected(e.target.value);
  };

  // DashBoard click api

  React.useEffect(() => {
    if (!payTypeRes.loading && payTypeRes.error) {
      alert(payTypeRes.error);
      return;
    }
    if (payTypeRes.data) {
      const rooms = payTypeRes.data.data.content;
      setRoomList({...payTypeRes.data.data, content: rooms});
    }
  }, [payTypeRes]);

  React.useEffect(() => {
    if (!monthRes.loading && monthRes.error) {
      alert(monthRes.error);
      return;
    }
    if (monthRes.data) {
      const rooms = monthRes.data.data.content;
      setRoomList({...monthRes.data.data, content: rooms});
    }
  }, [monthRes]);

  React.useEffect(() => {
    if (!continueRes.loading && continueRes.error) {
      alert(continueRes.error);
      return;
    }
    if (continueRes.data) {
      const rooms = continueRes.data.data.content;
      setRoomList({...continueRes.data.data, content: rooms});
    }
  }, [continueRes]);

  const handleDashClick = (name: string) => {
    const handleProgressType = (value: 'NORMAL' | 'MISSED' | 'VIEWING' | 'FINISH_VIEWING' | 'NOT_PROGRESS') => {
      const search: valueProps = {
        ...initialValue,
        startDate: values.startDate,
        endDate: values.endDate,
        fieldProgressType: value,
      };
      setPageType('normal');
      setValues(search);
      setFieldProgressType(value);
      request(search, 0, +selected);
      req(values.startDate as number, values.endDate as number);
      setProgressType('');
    };
    const handlePro = (value: 'COMPLETE' | 'FAIL') => {
      const search: valueProps = {
        ...initialValue,
        startDate: values.startDate,
        endDate: values.endDate,
        progressType: value,
      };
      setValues(search);
      setPageType('normal');
      setProgressType(value);
      request(search, 0, +selected);
      req(values.startDate as number, values.endDate as number);
      setFieldProgressType('');
    };
    switch (name) {
      case 'total':
        handleReset();
        request(initialValue, 0, +selected);
        req();
        setPageType('normal');
        break;
      case 'today': {
        setProgressType('');
        setFieldProgressType('');
        const search = {...initialValue, startDate: values.startDate, endDate: values.endDate};
        if (!search.endDate && !search.startDate) {
          search.startDate = new Date().getTime();
          search.endDate = new Date().getTime();
        }
        setValues(search);
        setPageType('normal');
        request(search, 0, +selected);
        req(search.startDate as number, search.endDate as number);
        break;
      }
      case 'notProgress': {
        handleProgressType('NOT_PROGRESS');
        break;
      }
      case 'missed': {
        handleProgressType('MISSED');
        break;
      }
      case 'normal': {
        handleProgressType('NORMAL');
        break;
      }
      case 'viewing': {
        handleProgressType('VIEWING');
        break;
      }
      case 'finishViewing': {
        handleProgressType('FINISH_VIEWING');
        break;
      }
      case 'complete': {
        handlePro('COMPLETE');
        break;
      }
      case 'fail': {
        handlePro('FAIL');
        break;
      }
      case 'continue': {
        const params = {sort: 'id', startDate: values.startDate, endDate: values.endDate};
        setValues({...initialValue, startDate: values.startDate, endDate: values.endDate});
        continueReq(params, 0, +selected);
        setProgressType('');
        setFieldProgressType('');
        setPageType('continue');
        break;
      }
      case 'month1': {
        const params = {sort: 'id', startDate: values.startDate, endDate: values.endDate, month: 1};
        setValues({...initialValue, startDate: values.startDate, endDate: values.endDate});
        monthReq(params, 0, +selected);
        setProgressType('');
        setFieldProgressType('');
        setPageType('month1');
        break;
      }
      case 'month2': {
        const params = {sort: 'id', startDate: values.startDate, endDate: values.endDate, month: 2};
        setValues({...initialValue, startDate: values.startDate, endDate: values.endDate});
        monthReq(params, 0, +selected);
        setProgressType('');
        setFieldProgressType('');
        setPageType('month2');
        break;
      }
      case 'pay1': {
        const params = {sort: 'id', startDate: values.startDate, endDate: values.endDate, payType: 'FULL'};
        setValues({...initialValue, startDate: values.startDate, endDate: values.endDate});
        payTypeReq(params, 0, +selected);
        setProgressType('');
        setFieldProgressType('');
        setPageType('pay1');
        break;
      }
      case 'pay2': {
        const params = {sort: 'id', startDate: values.startDate, endDate: values.endDate, payType: 'SPLIT'};
        setValues({...initialValue, startDate: values.startDate, endDate: values.endDate});
        payTypeReq(params, 0, +selected);
        setProgressType('');
        setFieldProgressType('');
        setPageType('pay2');
        break;
      }
      default:
        return;
    }
  };

  return (
    <Layout category="접수현황" page="접수현황">
      {/*  DashBoard */}
      <section>
        <DashBoardTitle>
          <li>접수현황</li>
          <li>진행현황</li>
          <li>계약현황</li>
          <li>계약만료</li>
          <li>납부방법</li>
        </DashBoardTitle>

        <DashBoardContent>
          <ul>
            <li>
              <div>
                <p
                  onClick={() => {
                    handleDashClick('total');
                  }}
                >
                  전체접수건 : <span>{dashBoardDataset?.allContract}</span>건
                </p>
                <p
                  onClick={() => {
                    handleDashClick('today');
                  }}
                >
                  일일접수건 : <span>{dashBoardDataset?.dailyContract}</span>건
                </p>
              </div>
            </li>
            <li>
              <div>
                <div>
                  <p
                    onClick={() => {
                      handleDashClick('notProgress');
                    }}
                  >
                    미진행 : <span>{dashBoardDataset?.notProgress}</span>건
                  </p>
                  <p
                    onClick={() => {
                      handleDashClick('missed');
                    }}
                  >
                    부재 : <span>{dashBoardDataset?.missed}</span>건
                  </p>
                </div>
                <div>
                  <p
                    onClick={() => {
                      handleDashClick('normal');
                    }}
                  >
                    일반상담 : <span>{dashBoardDataset?.normal}</span>건
                  </p>
                  <p
                    onClick={() => {
                      handleDashClick('viewing');
                    }}
                  >
                    투어예정 : <span>{dashBoardDataset?.viewing}</span>건
                  </p>
                  <p
                    onClick={() => {
                      handleDashClick('finishViewing');
                    }}
                  >
                    투어완료 : <span>{dashBoardDataset?.finishViewing}</span>건
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div>
                <p
                  onClick={() => {
                    handleDashClick('complete');
                  }}
                >
                  계약성공 : <span>{dashBoardDataset?.success}</span>건
                </p>
                <p
                  onClick={() => {
                    handleDashClick('continue');
                  }}
                >
                  계약연장 : <span>{dashBoardDataset?.extend}</span>건
                </p>
                <p
                  onClick={() => {
                    handleDashClick('fail');
                  }}
                >
                  계약실패 : <span>{dashBoardDataset?.fail}</span>건
                </p>
              </div>
            </li>
            <li>
              <div>
                <p
                  onClick={() => {
                    handleDashClick('month1');
                  }}
                >
                  <img src={time} /> 1개월 : <span>{dashBoardDataset?.oneMonth}건</span>
                </p>
                <p
                  onClick={() => {
                    handleDashClick('month2');
                  }}
                >
                  <img src={time} /> 2개월 : <span>{dashBoardDataset?.twoMonth}건</span>
                </p>
              </div>
            </li>
            <li>
              <div>
                <p
                  onClick={() => {
                    handleDashClick('pay1');
                  }}
                >
                  일시납 : <span>{dashBoardDataset?.fullPayment}</span>건
                </p>
                <p
                  onClick={() => {
                    handleDashClick('pay2');
                  }}
                >
                  분납 : <span>{dashBoardDataset?.splitPayment}</span>건
                </p>
              </div>
            </li>
          </ul>
        </DashBoardContent>
      </section>
      <Contents>
        <Filter>
          <Row isSmall={isSmall}>
            <StatusColumn isSmall={isSmall}>
              <Typography as="label" variant="body1">
                날짜
              </Typography>
              <DateSelector
                textHolder="시작일"
                value={values.startDate}
                onChange={(date) => handleDateChange('startDate', date)}
              />
              &nbsp;&nbsp;~&nbsp;&nbsp;
              <DateSelector
                textHolder="종료일"
                value={values.endDate}
                onChange={(date: any) => handleDateChange('endDate', date)}
              />
              <ButtonBlock>
                <button onClick={() => onClickHandleDate('day')}>오늘</button>
                <button onClick={() => onClickHandleDate('week')}>1주일</button>
                <button onClick={() => onClickHandleDate('month')}>1개월</button>
              </ButtonBlock>
            </StatusColumn>
            <StatusColumn isSmall={isSmall}>
              <Typography as="label" variant="body1">
                접수상태
              </Typography>
              {STATUS.map((x, idx) => (
                <RadioContainer key={idx}>
                  <label htmlFor={x.value}>
                    <input
                      id={x.value}
                      type="radio"
                      checked={selectedProgressType === x.value}
                      onChange={(e) => handleProgressType(x.value, e.target.checked)}
                    />
                    {x.label}
                  </label>
                </RadioContainer>
              ))}
            </StatusColumn>
          </Row>

          <Row isSmall={isSmall}>
            <StatusColumn isSmall={isSmall}>
              <Typography as="label" variant="body1">
                검색
              </Typography>
              <DropdownBox active={selectedSearchType} options={receiptSearchType} onSelected={handleSearchType} />
              <SearchBox value={searchKeyword} onChange={handleChangeText} />
            </StatusColumn>
            <StatusColumn isSmall={isSmall}>
              <Typography as="label" variant="body1">
                현장 진행상태
              </Typography>
              {FIELDTYPES.map((x, idx) => (
                <RadioContainer key={idx}>
                  <label htmlFor={x.value}>
                    <input
                      id={x.value}
                      type="radio"
                      checked={selectedFieldProgressType === x.value}
                      onChange={(e) => handleFieldType(x.value, e.target.checked)}
                    />
                    {x.label}
                  </label>
                </RadioContainer>
              ))}
            </StatusColumn>
          </Row>
        </Filter>
        <ButtonContainer>
          <BasicButton borderRadius={4} type="button" size="medium" onClick={handleReset}>
            초기화
          </BasicButton>
          {onFilter ? (
            <BasicButton borderRadius={4} type="button" size="medium" colorType="darkGreen" onClick={handleSubmit}>
              검색
            </BasicButton>
          ) : (
            <BasicButton borderRadius={4} type="button" size="medium" colorType="primary" onClick={handleSubmit}>
              검색
            </BasicButton>
          )}
        </ButtonContainer>
        {user.authorizeType === 'MASTER' && (
          <ExcelDownload>
            <ExcelButton onClick={handleDownload}>
              <img src={ic_excel} /> &nbsp;엑셀 다운
            </ExcelButton>
          </ExcelDownload>
        )}
        {isLoading && <DownLoading fileName={fileName} />}

        <ListInfoBlock>
          <span>
            검색결과 (총 <span className="highlight">{data?.totalElements}</span>건)
          </span>
          <span>
            * 본사 계정일 경우 본사 담당자와 중개담당자 수정할 수 있습니다. 현장 계정일 경우 수정이 불가합니다
          </span>

          <SelectBlock>
            <select onChange={onChange} value={selected}>
              <option value="10">10개</option>
              <option value="20">20개</option>
              <option value="50">50개</option>
              <option value="100">100개</option>
            </select>
          </SelectBlock>
        </ListInfoBlock>

        <ListContainer margin={user.authorizeType === 'MASTER'}>
          {result.loading || monthRes.loading || payTypeRes.loading || continueRes.loading ? (
            <Spinner />
          ) : (
            <List
              header={[...header, ...(user.authorizeType === 'MASTER' ? masterHeader : [])]}
              items={data?.content}
              totalElements={data?.totalElements}
              size={+selected}
              number={data?.number}
              onLoad={handleReload}
              page={data?.pageable?.pageNumber + 1}
            />
          )}
        </ListContainer>
        <PaginationContainer
          activePage={data?.pageable?.pageNumber + 1}
          itemsCountPerPage={+selected}
          totalItemsCount={data?.totalElements}
          pageRangeDisplayed={data?.pageable?.pageSize || 1}
          onPageChange={handlePageChanage}
        />

        {/* <Pagination
          initialPage={data?.pageable?.pageNumber + 1}
          pageCount={data?.totalPages}
          onPageChange={handlePageChanage}
          pageRangeDisplayed={data?.pageable?.pageSize || 1}
        /> */}
      </Contents>
    </Layout>
  );
};

const Contents = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

const ExcelDownload = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: right;
`;

const ExcelButton = styled(Typography).attrs({
  as: 'button',
  variant: 'body1',
})`
  border: 1px solid #989898;
  width: 92px;
  height: 35px;
  background: transparent;
  cursor: pointer;
`;

const RadioContainer = styled.div`
  font-size: 14px;

  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: none;
  }

  label {
    cursor: pointer;
    padding-right: 15px;
    color: ${theme.palette.blackLighter};
  }

  label input[type='radio']:after {
    content: '';
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid #eaeaea;
    background-color: #fff;
    border-radius: 100%;
    vertical-align: middle;
    cursor: pointer;
  }
  label input[type='radio']:checked:after {
    background: url(${selected}) 0 0 no-repeat;
    background-size: contain;
    border: 1px solid ${theme.palette.grey};
  }
`;

const Colum = styled(Flexbox)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const Filter = styled(Flexbox)`
  flex-direction: column;
  align-items: flex-start;
`;
const Row = styled.div<{isSmall: boolean}>`
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  flex-direction: ${(props: any) => (props.isSmall ? 'column' : 'row`')};
  width: 100%;
  padding: 10px 0px;
  background-color: ${({theme}) => theme.palette.grey};
  border-top: 1px solid ${({theme}) => theme.palette.borderGray};
  & + & {
    border-bottom: 1px solid ${({theme}) => theme.palette.borderGray};
  }
`;

const Column = styled(Flexbox)`
  width: 50%;
  padding-left: 30px;
  label {
    width: 120px;
  }
`;

const StatusColumn = styled(Column)<{isSmall: boolean}>`
  margin-right: ${(props: {isSmall: any}) => (props.isSmall ? `0px` : `50px`)};
  width: ${(props: {isSmall: any}) => (props.isSmall ? `100%` : `50%`)};

  &:nth-of-type(1) {
    margin-bottom: ${(props: {isSmall: any}) => (props.isSmall ? `10px` : `0px`)};
    padding-bottom: ${(props: {isSmall: any}) => (props.isSmall ? `5px` : `0px`)};
  }
`;

const DropdownBox = styled(Dropdown)`
  width: 145px;
  margin-right: 16px;
`;

const SearchBox = styled.input`
  width: 300px;
  height: 38px;
  outline: none;
  border: 1px solid ${theme.palette.borderGray};
  border-radius: 4px;
  padding-left: 14px;
  box-sizing: border-box;
  font-size: 13px;
  line-height: 19px;
`;

const ListContainer = styled.div<{margin: boolean}>`
  margin-top: ${(props: {margin: any}) => (props.margin ? `0px` : `30px`)};
  padding-bottom: 20px;
  overflow-x: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
  button {
    margin: 0 10px;
  }
`;

const DashBoardTitle = styled.ul`
  text-align: center;
  display: flex;
  list-style: none;
  height: 42px;
  align-items: center;
  background: #f8f8f8;
  padding: 0;
  margin: 0;
  li {
    flex: 1;
    color: #535c68;
    font-size: 13px;
    &:nth-child(1) {
      width: 358px;
    }
    &:nth-child(2) {
      width: 540px;
    }
    &:nth-child(3) {
      width: 255px;
    }
    &:nth-child(4) {
      width: 250px;
    }
    &:nth-child(5) {
      width: 337px;
    }
  }
`;

const DashBoardContent = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(174, 174, 174, 0.25);
  margin-bottom: 48px;
  ul {
    display: flex;

    padding: 0;
    margin: 0;
    li {
      flex: 1;
      height: 122px;
      list-style: none;
      border: 1px solid #f2f2f2;
      padding-top: 17.56px;
      &:nth-child(1) {
        width: 358px;
      }
      &:nth-child(2) {
        width: 540px;
        div:nth-child(1) > p:first-child {
          color: red;
        }
      }
      &:nth-child(3) {
        width: 255px;
        p:first-child {
          color: #1db177;
        }
      }
      &:nth-child(4) {
        width: 250px;
        span {
          color: red;
        }
      }
      &:nth-child(5) {
        width: 337px;
      }
      &:nth-child(2) > div {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        gap: 0 20px;
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 14.81px 0;
        p {
          margin: 0;
          font-size: 13px;
          color: #535c68;
          cursor: pointer;
        }
      }
    }
  }
`;

const ButtonBlock = styled.div`
  margin-left: 3.68px;
  display: flex;
  gap: 0 6px;

  button {
    width: 94px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #dddfe5;
    box-sizing: border-box;
    border-radius: 3px;
    color: #818181;
    font-size: 13px;
    cursor: pointer;
  }
`;

const ListInfoBlock = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;

  & > span {
    .highlight {
      color: #1db177;
    }
    &:nth-child(1) {
      color: #333333;
      font-size: 14px;
    }
    &:nth-child(2) {
      color: #818181;
      font-size: 12px;
    }
  }
`;

const SelectBlock = styled.div`
  position: absolute;
  left: 0;
  top: -40px;

  select {
    width: 140px;
    height: 34px;
  }
`;
