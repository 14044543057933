export type NameType = 'name' | 'email' | 'password' | 'password2' | 'mobile';

export type FieldsType = {
  [key in NameType]?: string | number | readonly string[] | undefined;
};

export const LOGIN_FIELDS: IField[] = [
  {
    id: 'email',
    label: '이메일 주소',
    type: 'text',
    required: true,
    placeholder: '이메일 주소를 입력해 주세요.',
  },
  {
    id: 'password',
    label: '비밀번호',
    type: 'password',
    required: true,
    placeholder: '비밀번호는 입력해 주세요.',
  },
];

export const SIGNUP_FIELDS: IField[] = [
  {
    id: 'email',
    label: '사용자 ID (Email)',
    type: 'text',
    required: true,
    placeholder: 'peterpans@peterpan.com.',
  },
  {
    id: 'password',
    label: '비밀번호',
    type: 'password',
    required: true,
    placeholder: '비밀번호를 입력해 주세요.',
  },
  {
    id: 'password2',
    label: '비밀번호 확인',
    type: 'password',
    required: true,
    placeholder: '비밀번호를 입력해 주세요.',
  },
  {
    id: 'fieldOffice',
    label: '중개업체명',
    type: 'text',
    required: true,
    placeholder: '중개업체명을 입력해 주세요.',
  },
  {
    id: 'basicAddr',
    label: '주소',
    type: 'button',
    required: true,
    placeholder: '주소를 입력해 주세요.',
  },
  {
    id: 'detailAddr',
    label: '상세주소',
    type: 'text',
    required: true,
    placeholder: '상세주소를 입력해 주세요.',
  },
  {
    id: 'name',
    label: '대표자(담당자) 이름',
    type: 'text',
    required: true,
    placeholder: '김피터.',
  },
  {
    id: 'mobile',
    label: '연락처',
    type: 'text',
    required: true,
    placeholder: '"-" 없이 입력해주세요 01012341234',
  },
];
