import {ContractInfoWindow} from '@/components/Popup/ContractInfo';
import {DocumentAttachWindow} from '@/components/Popup/DocumentAttach';
import {FieldManagerWindow} from '@/components/Popup/FieldManager';
import {InternalManagerWindow} from '@/components/Popup/InternalManager';
import {MemoWindow} from '@/components/Popup/Memo';
import {ReviewDetailPopup} from '@/components/Popup/ReviewDetail';
import {RoomInfoDetailWindow} from '@/components/Popup/RoomInfoDetail';
import {Cell} from '@/components/styledComponents/Table';
import Typography from '@/components/Typography';
import {useDeleteRoom} from '@/hooks/room';
import {useSession} from '@/hooks/session';
import {IRoom} from '@/interface';
import {userMobileState} from '@/stores/room';
import theme from '@/styled-components/theme';
import moment from 'moment';
import React from 'react';
import {useHistory} from 'react-router';
import {useSetRecoilState} from 'recoil';
import styled from 'styled-components';
import {
  getAgeGroupByValue,
  getFieldProgressType,
  getGenderByValue,
  getJobTypeByValue,
  getProgressType,
  getReplaceMobile,
} from './data';
import {IProps, ITableHeader} from './index.d';

const TableHeader = ({header}: {header: ITableHeader[]}) => {
  return (
    <Header>
      {header.map((x, index) => (
        <Cell key={index} minWidth={x.minWidth} wrapWidth={x.wrapWidth} align={x.align}>
          {x.title}
        </Cell>
      ))}
    </Header>
  );
};

export const List = ({items, header, onLoad, page, totalElements, size, number}: IProps) => {
  const [selectedItem, setSelectedItem] = React.useState<IRoom>(null);
  const [internalMemberForm, setInternalMemberForm] = React.useState<boolean>(false);
  const [fieldMemberForm, setFieldMemberForm] = React.useState<boolean>(false);
  const [memoForm, setMemoForm] = React.useState<boolean>(false);
  const [contractInfoForm, setContractInfoForm] = React.useState<boolean>(false);
  const [roomInfoForm, setRoomInfoForm] = React.useState<boolean>(false);
  const [documentsForm, setDocumentsForm] = React.useState<boolean>(false);
  const [reviewForm, setReviewForm] = React.useState<boolean>(false);
  const {user} = useSession();
  const [requestDelete, result] = useDeleteRoom();

  const history = useHistory();

  const handlePopup = (id: string, open: boolean = true) => {
    switch (id) {
      case 'internalManager':
        setInternalMemberForm(open);
        break;
      case 'memo':
        setMemoForm(open);
        break;
      case 'contractInfo':
        setContractInfoForm(open);
        break;
      case 'roomInfo':
        setRoomInfoForm(open);
        break;
      case 'fieldManager':
        setFieldMemberForm(open);
        break;
      case 'documents':
        setDocumentsForm(open);
        break;
      case 'review':
        setReviewForm(open);
        break;
    }
  };

  const handleClick = React.useCallback(
    (id, select) => {
      setSelectedItem(select);
      handlePopup(id);
    },
    [internalMemberForm],
  );

  const handleConfirm = React.useCallback(
    (id) => {
      handlePopup(id, false);
      onLoad(page);
    },
    [internalMemberForm, memoForm, contractInfoForm, roomInfoForm, page],
  );

  const handleDelete = React.useCallback((id) => {
    if (confirm('고객 접수정보를 삭제하시겠습니까?')) {
      requestDelete(id);
    }
  }, []);

  React.useEffect(() => {
    if (!result.loading && result.called) {
      alert('정보삭제가 완료되었습니다.');
      onLoad(page);
    } else if (!result.loading && result.error) {
      alert(result.error);
    }
  }, [result]);

  const userMobile = useSetRecoilState(userMobileState);

  const handleUser = (item: IRoom) => {
    localStorage.setItem('phone', item.userMobile);
    userMobile({userMobile: item.userMobile});
    history.push({pathname: '/clientDetail', state: {phone: item.userMobile}});
  };

  const getRentCostGroupByValue = (value: string) => {
    // return rentCostGroup.find((x) => x.value === value)?.label;
    if (!value || typeof value !== 'string') return;
    if (value.includes('-')) {
      return value + '만원';
    }
    if (value === '100' || value === '150') {
      return value + '만원 이상';
    }
    return value + '만원대';
  };

  return (
    <Root>
      <TableHeader header={header} />
      {items?.map((item, index) => (
        <Row key={index}>
          {header.map((h, idx) => (
            <Cell
              key={idx}
              width={h.width}
              minWidth={h.minWidth}
              wrapWidth={h.wrapWidth}
              align={h.align}
              isComplete={item?.progressType === 'COMPLETE'}
              isFail={item?.progressType === 'FAIL'}
              ismissed={item?.fieldProgressType === 'MISSED'}
              isNotProgress={item?.fieldProgressType === 'NOT_PROGRESS'}
              isInfo
            >
              {{
                userName: (
                  <div>
                    <ButtonDetail onClick={() => handleUser(item)}>{item.userName}</ButtonDetail>
                  </div>
                ),
                progressType: `${getProgressType(item?.progressType)}`,
                fieldProgressType: `${item?.fieldProgressType ? getFieldProgressType(item?.fieldProgressType) : ' '}`,
                userInfo: (
                  <div>
                    {`${getGenderByValue(item?.gender)} / ${getAgeGroupByValue(item?.ageGroup)} `}
                    <div>{getJobTypeByValue(item?.jobType)}</div>
                  </div>
                ),
                createdAt: moment(item[h.id]).format('yyyy-MM-DD HH:mm:ss'),
                userMobile: `${getReplaceMobile(item?.userMobile)}`,
                contactTime: (
                  <InfoBox>
                    <div>{item?.contactTime.split(',')[0] ?? ''}</div>
                    <div>{`${item?.contactTime.split(',')[1] ?? ''}`}</div>
                  </InfoBox>
                ),
                roomInfo: (
                  <InfoBox>
                    <div>{`${getRentCostGroupByValue(item?.rentCostGroup)}/${item?.addrGu}`}</div>

                    <div>
                      <ButtonDetail onClick={() => handleUser(item)}>
                        <u>[더보기]</u>
                      </ButtonDetail>
                    </div>
                  </InfoBox>
                ),
                memo: <ButtonDetail onClick={() => handleClick(h.id, item)}>보기</ButtonDetail>,
                documents: <ButtonDetail onClick={() => handleClick(h.id, item)}>보기</ButtonDetail>,
                contractInfo: <ButtonDetail onClick={() => handleClick(h.id, item)}>보기</ButtonDetail>,
                internalManager:
                  user.adminType !== 'FIELD' ? (
                    <ButtonDetail
                      onClick={() => {
                        handleClick(h.id, item);
                      }}
                    >
                      {item.internalAdmin ? item.internalAdmin?.name : '미배정'}
                    </ButtonDetail>
                  ) : (
                    <span>{item.internalAdmin ? item.internalAdmin?.name : '미배정'}</span>
                  ),
                fieldManager:
                  user.adminType !== 'FIELD' ? (
                    <ButtonDetail
                      onClick={() => {
                        handleClick(h.id, item);
                      }}
                    >
                      {item.fieldAdmin
                        ? item.fieldAdmin.fieldOffice
                          ? item.fieldAdmin.fieldOffice + ' ' + item.fieldAdmin?.name
                          : item.fieldAdmin.name
                        : '미배정'}
                    </ButtonDetail>
                  ) : (
                    <span> {item.fieldAdmin ? item.fieldAdmin?.name : '미배정'}</span>
                  ),
                delete: <ButtonDetail onClick={() => handleDelete(item.id)}>삭제</ButtonDetail>,
                review: (
                  <Typography
                    onClick={() => handleClick(h.id, item)}
                    as="div"
                    style={{textAlign: 'center', padding: '5px', cursor: 'pointer'}}
                    dangerouslySetInnerHTML={{
                      __html: `${item?.reviewInfo ? '등록완료<br/><u>[보기]</u>' : '미등록'}`,
                    }}
                  />
                ),
              }[h.id] ||
                item[h.id] ||
                totalElements - size * number - index}
            </Cell>
          ))}
        </Row>
      ))}
      {internalMemberForm && (
        <InternalManagerWindow
          data={selectedItem}
          onComplete={() => handleConfirm('internalManager')}
          onClose={() => setInternalMemberForm(false)}
        />
      )}
      {memoForm && (
        <MemoWindow data={selectedItem} onComplete={() => handleConfirm('memo')} onClose={() => setMemoForm(false)} />
      )}
      {contractInfoForm && (
        <ContractInfoWindow
          data={selectedItem}
          onComplete={() => handleConfirm('contractInfo')}
          onClose={() => setContractInfoForm(false)}
        />
      )}
      {fieldMemberForm && (
        <FieldManagerWindow
          data={selectedItem}
          onComplete={() => handleConfirm('fieldManager')}
          onClose={() => setFieldMemberForm(false)}
        />
      )}
      {roomInfoForm && (
        <RoomInfoDetailWindow
          data={selectedItem}
          onComplete={() => handleConfirm('roomInfo')}
          onClose={() => setRoomInfoForm(false)}
        />
      )}
      {documentsForm && (
        <DocumentAttachWindow
          data={selectedItem}
          onComplete={() => handleConfirm('documents')}
          onClose={() => setDocumentsForm(false)}
        />
      )}
      {reviewForm && selectedItem?.reviewInfo && (
        <ReviewDetailPopup
          roomId={selectedItem?.id}
          data={selectedItem.reviewInfo}
          onClose={() => setReviewForm(false)}
          onComplete={() => handleConfirm('review')}
        />
      )}
    </Root>
  );
};

const Header = styled.div`
  padding: 14px 0;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ededed;
  width: 100%;
`;

const Root = styled.div`
  margin-top: 16px;
  min-width: 1620px;
  width: 100%;
  box-shadow: ${theme.palette.shadow01};
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #ededed;
  width: 100%;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 13px;

  & div:nth-of-type(3) {
    display: flex;
  }
`;

const ButtonDetail = styled(({...props}) => <Typography as="div" {...props} />)`
  text-decoration-line: underline;
  cursor: pointer;
`;

const EmailContainer = styled.div`
  word-break: break-all;
  white-space: pre;
`;
