import App from './App';
import React from 'react';
import {RecoilRoot} from 'recoil';
import client from './configures/apollo_client';
import {ApolloProvider} from '@apollo/client';
import {ThemeProvider} from 'styled-components';
import theme from './styled-components/theme';

function Root() {
  return (
    <ApolloProvider client={client()}>
      <RecoilRoot>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </RecoilRoot>
    </ApolloProvider>
  );
}

export default Root;
