import {BasicButton} from '@/components/Button';
import {Dropdown} from '@/components/Dropdown';
import {Layout} from '@/components/Layout';
import Tabs from '@/components/Tabs';
import {useGetUserDataByPhone, useRoomSearch} from '@/hooks/room';
import {IRoom} from '@/interface';
import {headerState, roomListState} from '@/stores/room';
import theme from '@/styled-components/theme';
import {useBreakpoints} from '@/utils/media';
import React, {useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useRecoilState, useSetRecoilState} from 'recoil';
import styled from 'styled-components';
import {menuType} from '@/constants';
import ContractInformTab from './ContractInformTab';
import {DocumentAttachTab} from './DocumentAttachTab';
import Memo from './MemoTab';
import {ReceipDetail} from './ReceipDetail';

const initialValue = {
  address: null,
  broker: null,
  brokerMobile: null,
  company: null,
  contractDate: null,
  contractType: null,
  detailAddress: null,
  endDate: null,
  id: null,
  lessor: null,
  lessorMobile: null,
  paymentDate: null,
  startDate: null,
};

type LocationProps = {
  phone: string;
};

export const ClientDetal = () => {
  const [request, result] = useRoomSearch();
  const [data, setRoomList] = useRecoilState(roomListState);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [fileName, setFileName] = React.useState<string>();
  const {isSmall} = useBreakpoints();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [onFilter, setOnFilter] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = React.useState<IRoom>(null);
  const [internalAdmin, setinternalAdmin] = React.useState('미배정');
  const [currentTab, setCurrentTab] = useState(0);
  const [req, res] = useGetUserDataByPhone();
  const [hasChange, setHasChange] = useState(false);
  const [headerValue, setHeaderState] = useRecoilState(headerState);

  React.useEffect(() => {
    if (!result?.loading && result.error) {
      alert(result.error);
      return;
    }
    if (result.data) {
      setRoomList(result.data.data);
    }
  }, [result]);

  const handleDownload = React.useCallback(() => {
    setIsLoading(true);
  }, []);

  const handleMenu = (index: number) => {
    setCurrentTab(index);
  };

  const getKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
    localStorage.setItem('phone', e.target.value);
  };

  const location = useLocation();
  const phone = (location?.state as LocationProps)?.phone;

  const catchChange = useCallback(
    (isChange: boolean) => {
      setHasChange(isChange);
    },
    [hasChange],
  );

  useEffect(() => {
    // setHeaderState({...headerValue, isDetail: true});
    const p = localStorage.getItem('phone');
    setSearchKeyword(phone || p);
    req(phone || p).then((v) => {
      const res = v.data.data;
      setSelectedItem(res);
      setHeaderState({isDetail: true, name: res.internalAdmin ? res.internalAdmin.name : '미배정'});
    });
    setHasChange(false);
  }, [phone, catchChange]);

  const handleSearch = () => {
    if (!searchKeyword) return;
    req(searchKeyword)
      .then((v) => {
        const res = v.data.data;
        setSelectedItem(res);
      })
      .catch(() => alert('해당 번호로 조회되는 데이터가 없습니다.'));
  };

  return (
    <Layout category="고객상세" page="고객상세">
      <Contents>
        <InformRow>
          <InfoBlock>
            <SearchBlock>
              <SearchOption>
                <SearchOptionText>휴대폰번호</SearchOptionText>
              </SearchOption>
              <SearchInput placeholder="-없이 숫자만 입력" onChange={getKeyword} value={searchKeyword} />
              <BasicButton borderRadius={4} type="button" size="medium" colorType="primary" onClick={handleSearch}>
                검색
              </BasicButton>
            </SearchBlock>
            <Title>접수정보</Title>
            <ReceipDetail data={selectedItem} catchChange={catchChange} />
          </InfoBlock>
          <DiviedLine />
          <Tabs menus={menuType} handleMenu={handleMenu}>
            {currentTab === 0 && <ContractInformTab data={selectedItem} />}
            {currentTab === 1 && <DocumentAttachTab data={selectedItem} />}
            {currentTab === 2 && <Memo data={selectedItem} />}
          </Tabs>
        </InformRow>
      </Contents>
    </Layout>
  );
};
const Contents = styled.div`
  width: 100%;
  height: 100%;
`;

const Row = styled.div<{isSmall: boolean}>`
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  flex-direction: ${(props: any) => (props.isSmall ? 'column' : 'row`')};
  width: 100%;
  padding: 10px 0px;
  background-color: ${({theme}) => theme.palette.grey};
  border-top: 1px solid ${({theme}) => theme.palette.borderGray};
  & + & {
    border-bottom: 1px solid ${({theme}) => theme.palette.borderGray};
  }
`;

const SearchRow = styled.div`
  width: 100%;
`;

const InformRow = styled.div`
  //border-bottom: 1px solid #eaeaea;
  margin-top: 20px;
  /* overflow-y: auto;
  overflow-x: scroll; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const InfoBlock = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
`;

const SearchBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
`;

const SearchOption = styled.div`
  width: 151px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #818181;
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

const SearchOptionText = styled.span`
  color: #818181;
  text-align: left;
  padding-left: 14px;
`;

const SearchInput = styled.input`
  background: #ffffff;
  border: 1px solid #818181;
  box-sizing: border-box;
  border-radius: 4px;
  width: 223px;
  height: 36px;
  padding-left: 9.92px;

  &::placeholder {
    font-size: 13px;
    color: #c5c7ca;
  }
`;

const DiviedLine = styled.div`
  height: 864px;
  display: flex;
  align-items: center;
  margin: 0 24px;
  border: 1px solid #cdd5e1;
`;

const StatusColumn = styled.div`
  background-color: #f8f8f8;
  padding: 5px 1em;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;

  & label {
    width: 80px;
  }
`;
const DropdownBox = styled(Dropdown)`
  width: 145px;
  margin-right: 16px;
`;
const SearchBox = styled.input`
  width: 300px;
  height: 38px;
  outline: none;
  border: 1px solid ${theme.palette.borderGray};
  border-radius: 4px;
  padding-left: 14px;
  box-sizing: border-box;
  font-size: 13px;
  line-height: 19px;
  margin-right: 10px;
`;

const TextBox = styled.div`
  max-width: 205px;
  margin-left: 20px;
  display: flex;

  & h3 {
    font-size: 15px;
    font-weight: 500;
  }
  & p {
    font-size: 15px;
    font-weight: 500;
    margin-left: 5px;
  }
`;

const FlexBox = styled.div`
  display: flex;
`;

const Title = styled.span`
  font-size: 20px;
  color: #1db177;
  margin: 24px 0;
`;
