import Typography, {typographyStyle} from '@/components/Typography';
import theme from '@/styled-components/theme';
import {REGISTER_FORM_WIDTH} from '@/styled-components/constants';
import React from 'react';
import styled from 'styled-components';
import IconBack from './assets/icon__back.svg';
import IconInfo from './assets/icon__info.svg';
import IconCross from './assets/icon__cross.svg';

export const Section = styled.section<{spacing: number}>`
  margin-top: ${({spacing}) => 4 * (spacing ?? 6)}px;
`;

export const Label = styled((props) => <Typography as="div" variant="body1" {...props} />)`
  padding-bottom: 8px;
  border-bottom: 1px solid ${theme.palette.blackLight};
  color: ${theme.palette.black};
  font-weight: 700;
  margin-bottom: 16px;
`;

export const WideLabel = styled(Label)`
  margin-top: 50px;
`;

export const Container = styled.div`
  padding: 50px 0px 130px;
  max-width: ${REGISTER_FORM_WIDTH.sl}px;
  width: 100%;
  margin: 0 auto;
  color: ${theme.palette.black};
`;

export const InfoIcon = styled.div`
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${IconInfo});
`;

export const WarningText = styled((props) => <Typography variant="body2" as="div" {...props} />)`
  margin-top: 6px;
  color: ${theme.palette.primaryLighter};
`;

export const BackgroundImage = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  box-sizing: content-box;
`;

export const BackButton = styled(BackgroundImage)`
  width: 16px;
  height: 16px;
  background-image: url(${IconBack});
  cursor: pointer;
  margin-right: 8px;
`;

export const BreadcrumbsArrow = styled(BackgroundImage)`
  background-image: url(${IconBack});
  transform: rotate(180deg);
  width: 13px;
  height: 13px;
  margin-left: 6px;
  margin-right: 6px;
  cursor: default;
`;

export const IconClose = styled(BackgroundImage)`
  width: 24px;
  height: 24px;
  background-image: url(${IconCross});
`;

export const NewMark = styled.div`
  position: relative;
  width: 12px;
  height: 12px;
  ::before,
  ::after {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: block;
  }
  ::before {
    content: '';
    background-color: ${theme.palette.primaryLighter};
    transform: skewX(-4.78deg);
  }
  ::after {
    content: 'N';
    color: ${theme.palette.white};
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
    text-align: center;
  }
`;

export const NewMarkBig = styled(NewMark)`
  width: 16px;
  height: 16px;
  margin-left: 4px;
  margin-right: 2px;
  ::before {
    transform: skewX(-3.5deg);
    top: 1px;
    bottom: -1px;
  }
  ::after {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const CoralRoundBadge = styled((props) => <Typography variant="body2" as="div" {...props} />)`
  height: 16px;
  padding: 0 4px;
  border-radius: 8px;
  background-color: ${theme.palette.primaryLighter};
  color: ${theme.palette.white};
`;

export const CoralSqareBadge = styled.div`
  ${{...typographyStyle.smallest}}
  color: ${theme.palette.white};
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1;
  padding: 0 2px;
  min-width: 12px;
  height: 12px;
  line-height: 12px;
  box-sizing: border-box;
  position: relative;
  ::before {
    content: '';
    transform: skewX(-9.5deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    position: absolute;
    background-color: ${theme.palette.primaryLighter};
    z-index: -1;
  }
`;

export const TransparentBackdrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const Flexbox = styled.div`
  display: flex;
  align-items: center;
`;
