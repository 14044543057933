import React, {ReactElement} from 'react';
import styled from 'styled-components';
import theme from '@/styled-components/theme';
import {Spin, Row, Col} from 'antd';
import {SpinSize} from 'antd/lib/spin';

import Loading from './Loading';

interface ISpinnerProps {
  tip?: string;
  size?: SpinSize;
  delay?: number;
  style?: React.CSSProperties;
  mask?: boolean;
}

const StyledSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;

  svg {
    color: ${theme.palette.blackLight};
  }

  span {
    margin: 50px;
  }

  .ant-spin {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ant-spin-text {
    color: ${theme.palette.blackLighter};
    text-align: center;
  }
`;

function Spinner(props: ISpinnerProps): ReactElement {
  const {tip, size, delay, style, mask = false} = props;

  return (
    <StyledSpinnerWrapper>
      <Col>
        <Spin indicator={<Loading size={size} />} delay={delay} tip={tip} size={size} />
      </Col>
    </StyledSpinnerWrapper>
  );
}

Spinner.defaultProps = {
  tip: 'Loading...',
  size: 'large',
  delay: 0,
  style: {},
  mask: false,
};

export default Spinner;
