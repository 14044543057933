import {useLogout, useSession} from '@/hooks/session';
import React, {useState} from 'react';
import styled from 'styled-components';
import {NAV_HEIGHT} from '../../styled-components/constants';
import theme from '../../styled-components/theme';
import {UserAccountWindow} from '@/components/Popup/UserAccount';
import {Typography} from '@/components/Typography';
import {IAccount} from '@/interface';
import Icon__user from './icon__user.svg';
import {useRecoilValue} from 'recoil';
import {headerState} from '@/stores/room';

export interface Props {
  category?: string;
  page?: string;
}

export const GNB = ({category, page}: Props) => {
  const {hasSession, user} = useSession();
  const logout = useLogout();
  const [isOpen, SetIsOpen] = useState<boolean>(false);
  const [modifyForm, setModifyForm] = React.useState<boolean>(false);
  const headerValue = useRecoilValue(headerState);

  const handleConfirm = React.useCallback(() => {
    setModifyForm(false);
  }, []);

  return (
    <Root>
      <Container>
        <BreadCrumb>
          {category} <span>/ {page}</span>
        </BreadCrumb>
        <RightWrapper>
          <div
            style={headerValue.isDetail ? {fontSize: '13px', marginRight: '10px'} : {display: 'none'}}
          >{`본사 담당자: ${headerValue.name}`}</div>
          <div
            style={headerValue.isDetail ? {fontSize: '13px', marginRight: '10px'} : {display: 'none'}}
          >{`연락처: 1644-3670 (3번 연결)`}</div>
          <div style={headerValue.isDetail ? {fontSize: '13px', marginRight: '50px'} : {display: 'none'}}>
            {'이메일: mubo_cs@duse.co.kr'}
          </div>
          {hasSession && (
            <>
              <UserIcon src={Icon__user} alt="유저아이콘" />
              <div style={{cursor: 'pointer'}} onClick={() => SetIsOpen(!isOpen)}>
                {user.name}
              </div>
            </>
          )}
        </RightWrapper>
      </Container>
      {isOpen && (
        <UserModal>
          <div onClick={() => setModifyForm(true)}>정보변경</div>
          <Logout onClick={() => logout()}>로그아웃</Logout>
        </UserModal>
      )}
      {modifyForm && (
        <UserAccountWindow data={user} onComplete={() => handleConfirm()} onClose={() => setModifyForm(false)} />
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  height: ${NAV_HEIGHT.lg}px;
  z-index: ${({theme}) => theme.zIndex.nav};
  background-color: ${({theme}) => theme.palette.white};
  border-bottom: 1px solid ${theme.palette.borderGray};
`;

const Container = styled.div`
  justify-content: space-between;
  display: flex;
  flex-grow: 1;
  height: 100%;
  align-items: center;
`;

const BreadCrumb = styled(Typography)`
  span {
    color: ${theme.palette.border};
    font-size: 16px;
    line-height: 20px;
  }
`;

const UserIcon = styled.img`
  margin-right: 8px;
`;

const UserModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 30px;
  width: 140px;
  padding: 10px 0px;
  background-color: white;
  box-shadow: ${theme.palette.shadow01};
  border-top: 2px solid ${theme.palette.primary};
  div {
    cursor: pointer;
  }
`;

const RightWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
  font-size: 16px;
  line-height: 20px;
`;

const Logout = styled.div`
  margin-top: 6px;
  color: ${({theme}) => theme.palette.bl01};
`;
