import {BasicButton} from '@/components/Button';
import {Dropdown} from '@/components/Dropdown';
import {ResetPasswordWindow} from '@/components/Popup/ResetPassword';
import {MasterUserAccountWindow} from '@/components/Popup/UserAccountMaster';
import {Cell} from '@/components/styledComponents/Table';
import Typography from '@/components/Typography';
import {useDeleteAccount} from '@/hooks/room';
import {IAccount} from '@/interface/account';
import theme from '@/styled-components/theme';
import React from 'react';
import styled from 'styled-components';
import {authorizeTypes, getReplaceMobile, statusType} from './data';
import {IProps, ITableHeader} from './index.d';

const TableHeader = ({header}: {header: ITableHeader[]}) => {
  return (
    <Header>
      {header.map((x, index) => (
        <Cell key={index} minWidth={x.minWidth} wrapWidth={x.wrapWidth} align={x.align}>
          {x.title}
        </Cell>
      ))}
    </Header>
  );
};

interface IAuthType {
  id: number;
  value: string;
}

export const List = ({items, header, onLoad, onSetValue, totalElements, size, number}: IProps) => {
  const [selectedItem, setSelectedItem] = React.useState<IAccount>(null);
  const [resetPwForm, setResetPwForm] = React.useState<boolean>(false);
  const [modifyForm, setModifyForm] = React.useState<boolean>(false);
  const [fieldMemberForm, setFieldMemberForm] = React.useState<boolean>(false);
  const [memoForm, setMemoForm] = React.useState<boolean>(false);
  const [contractInfoForm, setContractInfoForm] = React.useState<boolean>(false);
  const [roomInfoForm, setRoomInfoForm] = React.useState<boolean>(false);
  const [documentsForm, setDocumentsForm] = React.useState<boolean>(false);
  const [requestDelete, result] = useDeleteAccount();

  const [values, setValues] = React.useState<IAccount[]>(items);

  const [authTypes, setAuthTypes] = React.useState<Array<IAuthType>>();
  const selectedAuthorType = React.useMemo(() => authorizeTypes[0], [authTypes]);

  React.useEffect(() => {
    setValues(items);
  }, [items]);

  React.useEffect(() => {
    setAuthTypes(
      values.map((x) => {
        return {
          id: x.id,
          value: x.authorizeType,
        };
      }),
    );
  }, []);

  const handlePopup = (id: string, open: boolean = true) => {
    switch (id) {
      case 'resetPw':
        setResetPwForm(open);
        break;
      case 'modify':
        setModifyForm(open);
        break;
    }
  };

  const handleClick = React.useCallback(
    (id, select) => {
      setSelectedItem(select);
      handlePopup(id);
    },
    [resetPwForm],
  );

  const handleConfirm = React.useCallback(
    (id) => {
      handlePopup(id, false);
      onLoad();
    },
    [resetPwForm, memoForm, contractInfoForm, roomInfoForm],
  );

  const handleDropDown = React.useCallback(
    (type, selected, index) => {
      if (type === 'authorizeType') {
        const newArray = [...values];

        newArray[index] = {
          ...newArray[index],
          authorizeType: selected.id,
        };

        setValues(newArray.slice(0, newArray.length));
        onSetValue(newArray.slice(0, newArray.length));
      } else if (type === 'statusType') {
        const newArray = [...values];

        newArray[index] = {
          ...newArray[index],
          status: selected.id === '1',
        };

        setValues(newArray.slice(0, newArray.length));
        onSetValue(newArray.slice(0, newArray.length));
      }
    },
    [values],
  );

  const handleAuthorType = (id, type) => {
    return authorizeTypes.find((x) => x.id === type);
  };

  const handleState = (id, status) => {
    return status ? statusType[0] : statusType[1];
  };

  const handleDelete = React.useCallback((id) => {
    if (confirm('회원 가입정보를 삭제하시겠습니까?')) {
      requestDelete(id);
    }
  }, []);

  React.useEffect(() => {
    if (!result.loading && result.called) {
      alert('정보삭제가 완료되었습니다.');
      onLoad();
    } else if (!result.loading && result.error) {
      alert(result.error);
    }
  }, [result]);

  return (
    <Root>
      <TableHeader header={header} />

      {values?.map((item, idx) => (
        <Row key={idx}>
          {header.map((h, index) => (
            <Cell key={index} minWidth={h.minWidth} wrapWidth={h.wrapWidth} align={h.align}>
              {{
                adminType: item[h.id] === 'INTERNAL' ? '내부' : '현장',
                authorizeType: (
                  <DropdownBox
                    active={handleAuthorType(item.id, item.authorizeType)}
                    options={authorizeTypes}
                    onSelected={(selected: any) => handleDropDown('authorizeType', selected, idx)}
                  />
                ),
                basicAddr:
                  item.basicAddr !== null ? item.basicAddr.split(' ')[0] + ' ' + item.basicAddr.split(' ')[1] : ' ',
                fieldOffice: item.adminType === 'INTERNAL' || !item.fieldOffice ? ' ' : item.fieldOffice,
                mobile: `${getReplaceMobile(item?.mobile)}`,
                status: (
                  <DropdownBox
                    active={handleState(item.id, item.status)}
                    options={statusType}
                    onSelected={(selected: any) => handleDropDown('statusType', selected, idx)}
                  />
                ),
                resetPw: <ButtonDetail onClick={() => handleClick(h.id, item)}>초기화</ButtonDetail>,
                delete: <ButtonDetail onClick={() => handleDelete(item.id)}>삭제</ButtonDetail>,
                modify: (
                  <BasicButton type="button" size="medium" onClick={() => handleClick(h.id, item)}>
                    수정
                  </BasicButton>
                ),
              }[h.id] ||
                item[h.id] ||
                totalElements - size * number - idx}
            </Cell>
          ))}
        </Row>
      ))}
      {resetPwForm && (
        <ResetPasswordWindow
          data={selectedItem}
          onComplete={() => handleConfirm('resetPw')}
          onClose={() => setResetPwForm(false)}
        />
      )}
      {modifyForm && (
        <MasterUserAccountWindow
          data={selectedItem}
          onComplete={() => handleConfirm('modify')}
          onClose={() => setModifyForm(false)}
        />
      )}
    </Root>
  );
};

const Header = styled.div`
  padding: 14px 0;
  background: #f7f7f7;
  display: flex;

  align-items: center;
  border-bottom: 1px solid #ededed;
  width: 100%;
`;

const Root = styled.div`
  margin-top: 16px;
  width: 100%;
  min-width: 1632px;

  box-shadow: ${theme.palette.shadow01};
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #ededed;
  width: 100%;
`;

const ButtonDetail = styled(({...props}) => <Typography as="div" {...props} />)`
  text-decoration-line: underline;
  cursor: pointer;
`;

const DropdownBox = styled(Dropdown)`
  width: 100px;
  margin-right: 16px;
`;
