export const progressType = [
  {id: 'ACCEPT', label: '접수'},
  {id: 'ASSIGN', label: '담당자 인계'},
  {id: 'COMPLETE', label: '계약완료'},
  {id: 'FAIL', label: '계약실패'},
];

export const receiptSearchType = [
  {id: 'name', label: '고객명'},
  {id: 'adminName', label: '담당자'},
  {id: 'regionKeyword', label: '지역명키워드'},
  // {id: 'email', label: '아이디'},
];

export const adminType = [
  {id: 'INTERNAL', label: '내부'},
  {id: 'FIELD', label: '현장'},
];

export const status = [
  {id: 'true', label: '승인'},
  {id: 'false', label: '미승인'},
];

export const authorizeType = [
  {id: 'MASTER', label: '마스터'},
  {id: 'GENERAL', label: '일반'},
];

export const clientSearchType = [{id: 'mobile', label: '연락처'}];

export const accountSearchType = [
  {id: 'name', label: '담당자명'},
  {id: 'email', label: '아이디'},
  {id: 'mobile', label: '연락처'},
];

export const menuType = [
  {id: 0, label: '계약정보'},
  {id: 1, label: '서류정보'},
  {id: 2, label: '메모'},
];
