import theme from '../../styled-components/theme';
import React from 'react';
import styled from 'styled-components';
import Typography from '../Typography';
import IconArrow from './icon-12-arrowdown.svg';
import IconCheck from './icon__check.svg';
import {TransparentBackdrop} from '../styledComponents/Common';

export interface IOption {
  id: string | number;
  label: string | number;
}

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  options: IOption[];
  active?: IOption;
  defaultOption?: IOption;
  disabled?: boolean;
  placeholder?: string;
  size?: 'large' | 'basic';
  maxOptionsHeight?: number;
  isMemo?: boolean;
  /**
   * 값 선택 시 강조 UI 여부
   */
  showSelectHighlight?: boolean;
  onSelected?(selected: IOption): void;
}

export const Dropdown = ({
  defaultOption,
  active,
  options,
  disabled,
  size,
  maxOptionsHeight,
  placeholder,
  showSelectHighlight,
  onSelected,
  isMemo,
  ...props
}: IProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const activeValue = React.useMemo(() => active ?? defaultOption, [active, defaultOption]);
  const isActive = React.useMemo(
    () => (showSelectHighlight && !disabled && active ? defaultOption?.id !== active.id : false),
    [showSelectHighlight, disabled, active, defaultOption],
  );

  const toggleClick = React.useCallback(() => {
    if (!disabled) {
      setOpen(!open);
    }
  }, [open, disabled, setOpen]);

  const handleSelect = React.useCallback(
    (selected) => () => {
      setOpen(false);
      if (onSelected && selected.id !== activeValue?.id) {
        onSelected(selected);
      }
    },
    [activeValue?.id, onSelected, setOpen],
  );

  return (
    <Root {...props}>
      <Box disabled={disabled} active={isActive} isMemo={isMemo}>
        <Item onClick={toggleClick} size={size} ellipsis>
          {activeValue ? activeValue.label : placeholder}
          <Arrow />
        </Item>
      </Box>
      {!disabled && open && (
        <>
          <TransparentBackdrop onClick={toggleClick} />
          <List isMemo={isMemo} hasMargin={size !== 'large'} maxOptionsHeight={maxOptionsHeight}>
            {options.map((i) => (
              <Item key={i.id} onClick={handleSelect(i)} title={i.label}>
                <Typography as="div" ellipsis>
                  {i.label}
                </Typography>
                {i.id === activeValue?.id && <Check />}
              </Item>
            ))}
          </List>
        </>
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  position: relative;
`;

const Item = styled(Typography).attrs({as: 'div', variant: 'body1'})<{size?: string}>`
  height: ${({size}) => (size === 'large' ? 44 : 36)}px;
  cursor: pointer;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.palette.blackLighter};
`;

const Box = styled.div<{active?: boolean; disabled?: boolean; isMemo: boolean}>`
  border-radius: 4px;
  border: solid 1px
    ${(props: {isMemo: any}) => (props.isMemo ? `${theme.palette.blackLighter}` : `${theme.palette.borderGray}`)};

  background-color: ${theme.palette.white};
  ${({disabled}) =>
    disabled &&
    `
    background-color: ${theme.palette.disabled};
    ${Item} {
      opacity: 0.2;
      cursor: default;
    }
  `}
  ${({active}) =>
    active &&
    `
    border-color: ${theme.palette.borderGray};
    background-color: ${theme.palette.grey2};
  `}
`;

const Icon = styled.div`
  flex-shrink: 0;
  background-position: center;
  background-repeat: no-repeat;
`;

const Arrow = styled(Icon)`
  background-image: url(${IconArrow});
  width: 12px;
  height: 12px;
`;

const List = styled(Box)<{hasMargin?: boolean; maxOptionsHeight?: number; isMemo: boolean}>`
  position: absolute;
  top: ${(props: {isMemo: any}) => (props.isMemo ? `115%` : `100%`)};
  left: 0;
  right: 0;
  width: inherit;
  z-index: 1;
  ${({hasMargin}) => (hasMargin ? `margin-top: 6px;` : `margin-top: -1px;`)}
  ${({maxOptionsHeight}) =>
    maxOptionsHeight > 0 &&
    `
    max-height: ${maxOptionsHeight}px;
    overflow: auto;
  `}
  ${Item}:hover {
    background-blend-mode: multiply;
    background-image: linear-gradient(to bottom, ${theme.palette.borderGray}, ${theme.palette.borderGray});
  }
`;

const Check = styled(Icon)`
  background-image: url(${IconCheck});
  width: 16px;
  height: 16px;
`;
