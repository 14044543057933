import {useSetNewMemo, useMemoList, useSetUpdateMemo} from '@/hooks/room';
import {IRoom} from '@/interface';
import {head} from 'lodash';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {BasicButton} from '../Button';
import {Dropdown} from '../Dropdown';
import {Modal} from '../Modal';
import {getStatusValue, getMemoSummary, memoHeader, getModifiedAt} from '@/page/ReceiptHistory/data';
import {Cell} from '../styledComponents/Table';
import Typography from '../Typography';
import {userInfo} from 'os';
import theme from '@/styled-components/theme';

interface IProps {
  onClose?(e): void;
  onComplete?(e): void;
  data?: IRoom;
}

interface IOption {
  id: string | undefined;
  label: string;
  value?: number;
}

interface IList {
  contents: string | undefined;
  id: number;
  modifiedAt: number;
  createdAt: number;
  createdBy: string;
  status: string;
}

const options = [
  {
    id: 1,
    label: '일반상담',
    value: 'NORMAL',
  },
  {
    id: 2,
    label: '부재',
    value: 'MISSED',
  },
  {
    id: 3,
    label: '투어예정',
    value: 'VIEWING',
  },
  {
    id: 4,
    label: '투어완료',
    value: 'FINISH_VIEWING',
  },
];

const INITIALSTATE = {
  id: 1,
  label: '일반상담',
  value: 'NORMAL',
};
export const MemoWindow = ({onClose, onComplete, data}: IProps) => {
  const [inputText, setInputText] = React.useState<string>();
  const [progress, setProgress] = React.useState<any>(INITIALSTATE);
  const [memoList, setMemoList] = React.useState<IList[]>();
  const [datas, setData] = React.useState<IOption[]>([]);
  const [memoId, setMemoId] = React.useState<number>();
  const [request, result] = useSetNewMemo();
  const [updateRequest, updateResult] = useSetUpdateMemo();
  const [listRequest, listResult] = useMemoList();

  const [memo, setMemo] = React.useState<string>();
  // const [inputValue, setValue] = React.useState<string | ReadonlyArray<string> | number>(data?.memo || '');

  React.useEffect(() => {
    listRequest(data.id);
  }, []);

  React.useEffect(() => {
    if (!listResult.loading && listResult.error) {
      alert(result.error);
    } else if (listResult.called && listResult.data) {
      setMemoList(listResult.data.data);
    }
  }, [listResult, setMemoList]);

  React.useEffect(() => {
    if (!result.loading && result.error) {
      alert(result.error);
    } else if (result.called && result.data) {
      onComplete(result.data);
    }
  }, [result]);

  React.useEffect(() => {
    if (!updateResult.loading && updateResult.error) {
      alert(updateResult.error);
    } else if (updateResult.called && updateResult.data) {
      onComplete(updateResult.data);
    }
  }, [updateResult]);

  const handleChange = React.useCallback(
    (e) => {
      setInputText(e.target.value);
    },
    [inputText],
  );

  const handleDropdown = (select) => {
    setProgress(select);
  };

  const handleRefresh = () => {
    setMemoId(undefined);
    setInputText('');
    setProgress(INITIALSTATE);
  };

  const handleMemo = (contents: string, memoId: number, status: string) => {
    const type = options.filter((x) => x.value === status);
    if (type[0] !== undefined) {
      setProgress(type[0]);
    }
    setInputText(contents);
    setMemoId(memoId);
  };

  const handleConfirm = React.useCallback(() => {
    if (inputText === null) {
      alert('내용을 입력해 주세요');
      return;
    }
    if (memoId !== undefined) {
      updateRequest(memoId, inputText, data.id, progress?.value);
    } else {
      request(data.id, inputText, progress?.value);
    }
  }, [inputText, progress]);

  // React.useEffect(() => {
  //   const type = options.filter((x) => x.value === data.progressType);
  //   if (type[0] !== undefined) {
  //     setProgress(type[0]);
  //   }
  //   if (progress?.value === undefined) {
  //     setProgress(INITIALSTATE);
  //     return;
  //   }
  // }, [data.progressType]);

  // React.useEffect(() => {
  //   try {
  //     setInputText(JSON.parse(data?.memo));
  //   } catch (e) {
  //     setMemo(data?.memo || '123123');
  //   }
  // }, [data, memo]);

  return (
    <Modal title={'메모작성'} width={1000}>
      <Root>
        <ContentsBox>
          <ContentHeader>진행단계</ContentHeader>
          <DropdownBox>
            <Dropdown
              isMemo
              maxOptionsHeight={220}
              active={progress}
              options={options}
              size="large"
              defaultOption={{
                id: 0,
                label: '일반상담',
              }}
              onSelected={(selected) => handleDropdown(selected)}
            />
          </DropdownBox>
          <UserInfo>
            이름: {data.userName}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 휴대폰번호:{' '}
            {data.userMobile.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3')}
          </UserInfo>
        </ContentsBox>
        <ContentsBox>
          <ContentHeader>내용</ContentHeader>
          <div>
            <InputBox onChange={handleChange} placeholder="직접입력" value={inputText} />
          </div>
        </ContentsBox>
        <Header>
          {memoHeader.map((x) => (
            <Cell key={x.id} minWidth={x.minWidth} align={x.align}>
              {x.title}
            </Cell>
          ))}
        </Header>
        <ListBox>
          {memoList &&
            memoList.map((item, index) => (
              <Row key={index}>
                {memoHeader.map((x) => (
                  <Cell key={x.id} minWidth={x.minWidth} align={x.align}>
                    {
                      {
                        contents: (
                          <ButtonDetail onClick={() => handleMemo(item.contents, item.id, item.status)}>
                            전체보기
                          </ButtonDetail>
                        ),

                        createdAt:
                          moment(parseInt(item.createdAt + '000')).format('yyyy-MM-DD') +
                          '\u00A0' +
                          '\u00A0' +
                          '\u00A0' +
                          moment(parseInt(item.createdAt + '000')).format('HH:mm a'),
                        modifiedAt: `${getModifiedAt(item.modifiedAt)}`,
                        createdBy: item.createdBy,
                        memoSample: `${getMemoSummary(item.contents)}`,
                        status: `${getStatusValue(item?.status)}`,
                      }[x.id]
                    }
                  </Cell>
                ))}
              </Row>
            ))}
        </ListBox>

        {/* {data?.memoWriter && <LastModified>최종수정일 {moment(data?.memoAt * 1000).format('YYYY-MM-DD')}</LastModified>} */}
      </Root>
      <ButtonContainer>
        <BasicButton borderRadius={4} type="button" size="medium" onClick={onClose}>
          닫기
        </BasicButton>
        <BasicButton borderRadius={4} type="button" size="medium" onClick={handleRefresh}>
          초기화
        </BasicButton>
        <BasicButton borderRadius={4} type="button" size="medium" colorType="darkGreen" onClick={handleConfirm}>
          {memoId !== undefined ? '수정하기' : '등록하기'}
        </BasicButton>
      </ButtonContainer>
    </Modal>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 450px;
  padding-top: 20px;
`;

const ManagerName = styled(Typography)`
  border: 1px solid #818181;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  width: 193px;
  margin-left: 17px;
`;

const ContentsBox = styled.div`
  display: flex;

  margin-bottom: 20px;
`;

const ContentHeader = styled.div`
  width: 132px;
  font-size: 14px;
  line-height: 20px;
  color: #464646;
`;

const DropdownBox = styled.div`
  width: 173px;
`;

const UserInfo = styled.p`
  margin-left: 20px;
  font-size: 16px;
  line-height: 20px;
  color: ${theme.palette.blackLighter};
`;

const InputBox = styled.textarea`
  border: 1px solid #818181;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  width: 615px;
  height: 156px;
  padding: 14px;
  resize: none;
`;

const LastModified = styled(Typography)`
  text-align: end;
  margin-right: 14px;
  margin-bottom: 25px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-right: 8px;
  }
`;

const ListBox = styled.div`
  margin-bottom: 40px;
  height: 165px;
  overflow-y: auto;
`;

const Header = styled.div`
  padding: 8px 0;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ededed;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #ededed;
  width: 100%;
`;

const ButtonDetail = styled.div`
  text-decoration-line: underline;
  cursor: pointer;
`;
