import {match as Match} from 'react-router-dom';
import H from 'history';
import {pathname} from './pathname';

interface IMenuItem {
  id: string;
  label: string;
  path: string;
}

export const sideMenu: IMenuItem[] = [
  {
    id: 'ReceiptHistory',
    label: '접수현황',
    path: pathname.RECEIPT_HISTORY,
  },
  {
    id: 'ClientDetail',
    label: '고객상세',
    path: pathname.CLIENT_DETAIL,
  },
  {
    id: 'Account',
    label: '계정관리',
    path: pathname.ACCOUNT,
  },
];
