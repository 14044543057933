import {pathname} from '@/constants';
import {useSession} from '@/hooks/session';
import {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

export const SignInRouter = ({from}: {from?: string}) => {
  const {hasSession} = useSession();
  const history = useHistory();
  const location = useLocation<{from?: string}>();
  const state = {
    from: from || location.state?.from || pathname.MAIN,
  };

  useEffect(() => {
    if (hasSession) {
      history.push({
        pathname: state.from,
      });
    }
  }, [hasSession]);

  return null;
};
