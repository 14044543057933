import {FieldsType, LOGIN_FIELDS, NameType} from '@/components/Auth';
import {BasicButton} from '@/components/Button';
import {SignInRouter} from '@/components/SigninRouter';
import {Section} from '@/components/styledComponents/Common';
import {TextField} from '@/components/TextField';
import Typography from '@/components/Typography';
import {pathname} from '@/constants';
import {useLogin, useUpdateUserWithToken} from '@/hooks/session';
import {emailValidator, isValidPassword, normalizePassword, passwordValidator} from '@/utils/inputValidator';
import React from 'react';
import {useHistory} from 'react-router';
import styled from 'styled-components';
import {Layout} from '../../components/Layout';

export const SignIn = () => {
  const history = useHistory();
  const [login, {called, data, loading, error}] = useLogin();
  const updateUserWithToken = useUpdateUserWithToken();
  const [values, setValues] = React.useState<FieldsType>({});
  const [errors, setErrors] = React.useState<{[key in NameType]?: string}>({});

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (loading) {
        return;
      }

      const fields = {
        email: undefined,
        password: undefined,
        ...values,
      };
      const newErrors: {[key in NameType]?: string} = checkValidation(fields);
      if (Object.values(newErrors).filter((i) => !!i?.length).length) {
        return;
      }

      try {
        await login(String(fields.email), String(fields.password));
      } catch (e) {
        if (/[ㄱ-힣]/.test(e?.message?.[0])) {
          setErrors({password: e?.response?.data?.message});
        } else {
          setErrors({password: '로그인을 실패했습니다. 잠시 후 다시 시도해주세요.'});
        }
        alert(e);
      }
    },
    [loading, values],
  );

  React.useEffect(() => {
    if (called && data) {
      updateUserWithToken(data.data);
    }
  }, [called, data]);

  const checkValidation = React.useCallback(
    (obj) => {
      const newErrors: {[key in NameType]?: string} = {};

      Object.keys(obj).forEach((key) => {
        const value = obj[key];

        if (key === 'email') {
          newErrors[key] = emailValidator(value);
        }
        // else if (key === 'password') {
        //   newErrors[key] = passwordValidator(value);
        // }
      });

      setErrors({
        ...errors,
        ...newErrors,
      });

      return newErrors;
    },
    [errors],
  );

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {name} = e.target;
      let value = e.target.value;

      if (name) {
        checkValidation({[name]: value});

        if (name.includes('password') && !isValidPassword(value as string)) {
          value = normalizePassword(value as string);
        }

        setValues({
          ...values,
          [name]: value,
        });
      }
    },
    [checkValidation, values],
  );

  return (
    <div>
      <SignInRouter />
      <Root>
        <Title>관리자 로그인</Title>
        <form onSubmit={handleSubmit} style={{minWidth: '400px'}}>
          <Section spacing={0}>
            {LOGIN_FIELDS.map(
              (item) =>
                item && (
                  <Section key={item.id} spacing={4}>
                    <TextField
                      fullWidth
                      label={item.label}
                      type={item.type}
                      required={item.required}
                      name={item.id}
                      value={values[item.id]}
                      error={errors[item.id]}
                      placeholder={item.placeholder}
                      onChange={handleChange}
                    />
                  </Section>
                ),
            )}
          </Section>
          <ButtonContainer>
            <BasicButton size="medium" colorType="primary" width={'100%'} type="submit">
              로그인
            </BasicButton>
          </ButtonContainer>
        </form>
        <SignUp onClick={() => history.push(pathname.SIGN_UP)}>회원가입</SignUp>
      </Root>
    </div>
  );
};

const SignUp = styled(({...props}) => <Typography as="div" {...props} />)`
  text-decoration-line: underline;
  cursor: pointer;
  margin-top: 20px;
  justify-content: flex-end;
  display: flex;
  width: 100%;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  max-width: 400px;
  margin: auto;
  margin-top: 70px;
`;

const Title = styled(Typography).attrs({
  variant: 'title2',
  fontWeight: '700',
})`
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  max-width: 420px;
`;
