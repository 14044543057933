export const header = [
  {
    id: 'id',
    title: 'No',
  },
  {
    id: 'createdAt',
    title: '접수일시',
  },
  {
    id: 'progressType',
    title: '처리상태',
  },
  {
    id: 'userName',
    minWidth: 70,
    title: '고객명',
  },
  {
    id: 'userEmail',
    title: 'ID',
  },
  {
    id: 'userMobile',
    title: '연락처',
  },
  {
    id: 'contactTime',
    title: '연락 가능시간',
  },
  {
    id: 'ageGroup',
    title: '연령',
  },
  {
    id: 'gender',
    title: '성별',
  },
  {
    id: 'jobType',
    title: '직업',
  },
  {
    id: 'fieldAdmin',
    title: '담당자',
  },
  {
    id: 'rentCostGroup',
    title: '희망월세',
  },
  {
    id: 'roomType',
    title: '희망 방개수',
  },
  {
    id: 'moveInDateType',
    title: '희망 입주시기',
  },
  {
    id: 'regionTypes',
    title: '희망지역',
  },
  {
    id: 'addRequestTypes',
    title: '추가요청사항',
  },
  {
    id: 'requestMemo',
    title: '기타사항',
  },
];

// export const getMemoSummary = (value: string) => {
//   if (value.length < 7) {
//     return value + '...';
//   } else {
//     return value.slice(0, 10) + '...';
//   }
// };

export const getProgressType = (type) => {
  if (type === 'COMPLETE') return '계약성공';
  else if (type === 'ACCEPT') return '접수';
  else if (type === 'FAIL') return '계약실패';
  else if (type === 'ASSIGN') return '담당자인계';
  else if (type === 'NORMAL') return '일반상담';
  else if (type === 'MISSED') return '부재';
  else if (type === 'VIEWING') return '투어예정';
  else if (type === 'FINISH_VIEWING') return '투어완료';
  else type;
};

export const jobType = [
  {
    id: 1,
    label: '직장인',
    value: 'OFFICE_WORKER',
  },
  {
    id: 2,
    label: '대학(원)생',
    value: 'STUDENT',
  },
  {
    id: 3,
    label: '취업예정자,무직',
    value: 'OUT_OF_WORK',
  },
  {
    id: 4,
    label: '자영업',
    value: 'SELF_EMPLOYMENT',
  },
  {
    id: 5,
    label: '프리랜서,아르바이트',
    value: 'FREELANCE',
  },
  {
    id: 6,
    label: '전문직',
    value: 'PROFESSIONAL',
  },
  {
    id: 5,
    label: '기타',
    value: 'ETC',
  },
];

export const getJobTypeByValue = (value: string) => {
  return jobType.find((x) => x.value === value)?.label;
};

export const CONTACTTIME = [
  {id: 1, label: '10:00 ~ 12:00', value: '10:00 ~ 12:00'},
  {id: 2, label: '12:00 ~ 14:00', value: '12:00 ~ 14:00'},
  {id: 3, label: '14:00 ~ 16:00', value: '14:00 ~ 16:00'},
  {id: 4, label: '16:00 ~ 18:00', value: '16:00 ~ 18:00'},
  {id: 5, label: '18:00 ~ 20:00', value: '18:00 ~ 20:00'},
  {id: 6, label: '20:00 ~ 22:00', value: '20:00 ~ 22:00'},
];

export const getContactTimeByValue = (value: string) => {
  return CONTACTTIME.find((v) => v.label === value);
};

export const rentCostGroup = [
  {
    id: 1,
    label: '30만원대',
    value: '30',
  },
  {
    id: 2,
    label: '40만원대',
    value: '40',
  },
  {
    id: 3,
    label: '50만원대',
    value: '50',
  },
  {
    id: 4,
    label: '60만원대',
    value: '60',
  },
  {
    id: 5,
    label: '70만원대',
    value: '70',
  },
  {
    id: 6,
    label: '80만원대',
    value: '80',
  },
  {
    id: 7,
    label: '90만원대',
    value: '90',
  },
  {
    id: 8,
    label: '100만원이상',
    value: '100',
  },
];

export const getRentCostByValue = (value: string) => {
  return rentCostGroup.find((v) => v.label === value);
};

const roomType = [
  {
    id: 1,
    label: '원룸',
    value: 'ONE',
  },
  {
    id: 2,
    label: '투룸',
    value: 'TWO',
  },
  {
    id: 3,
    label: '쓰리룸 이상',
    value: 'MORE_THAN_THREE',
  },
];

export const getAgeGroupByValue = (value: string) => {
  return ageGroup.find((x) => x.value === value)?.label;
};

export const getRentCostGroupByValue = (value: string) => {
  // return rentCostGroup.find((x) => x.value === value)?.label;
  if (!value || typeof value !== 'string') return;
  if (value.includes('-')) {
    return value + '만원';
  }
  if (value === '100' || value === '150') {
    return value + '만원 이상';
  }
  return value + '만원대';
};

export const getRoomTypeByValue = (value: string) => {
  return roomType.find((x) => x.value === value)?.label;
};

const regionTypes = [
  {
    id: 1,
    label: '금천구',
    value: 'GEUMCHEON',
    checked: false,
  },
  {
    id: 2,
    label: '관악구',
    value: 'GWANAK',
    checked: false,
  },
  {
    id: 3,
    label: '구로구',
    value: 'GURO',
    checked: false,
  },
];

export const getContactTimeValue = (value: string) => {
  return value;
};

export const getRegionTypeByValue = (value: string[]) => {
  const result: any = [];
  for (let i = 0; i < value?.length; i++) {
    result.push(regionTypes.find((x) => x.value === value[i])?.label);
  }
  return result.join(',');
};

export const moveInDateType = [
  {
    id: 1,
    label: '즉시',
    value: 'NOW',
  },
  {
    id: 2,
    label: '1개월이내',
    value: 'ONE_MONTH',
  },
];

export const getMoveInByValue = (value: string) => {
  return moveInDateType.find((v) => v.value === value);
};

export const ageGroup = [
  {
    id: 1,
    label: '20대',
    value: 'TWENTIES',
  },
  {
    id: 2,
    label: '30대',
    value: 'THIRTIES',
  },
  {
    id: 3,
    label: '40대',
    value: 'FORTIES',
  },
  {
    id: 4,
    label: '50대 이상',
    value: 'MORE_THEN_FIFTIES',
  },
];

export const getMoveInDateTypeByValue = (value: string) => {
  return moveInDateType.find((x) => x.value === value)?.label;

  return value;
};

const addRequestTypes = [
  {
    id: 1,
    label: '주차가능',
    value: 'PARKING',
  },
  {
    id: 2,
    label: '반려동물',
    value: 'PET',
  },
  {
    id: 3,
    label: '엘리베이터',
    value: 'ELEVATOR',
  },
  {
    id: 4,
    label: '기타',
    value: 'ETC',
  },
];

export const getAddRequestTypesByValue = (value: string[]) => {
  const result: any = [];
  for (let i = 0; i < value?.length; i++) {
    result.push(addRequestTypes.find((x) => x.value === value[i])?.label);
  }
  return result.join(',');
};

export const getGenderByValue = (value: string) => {
  return value ? (value === 'male' ? '남자' : '여자') : '';
};

export const FIELDTYPES = [
  {label: '미진행', value: 'NOT_PROGRESS'},
  {label: '일반상담', value: 'NORMAL'},
  {label: '부재', value: 'MISSED'},
  {label: '투어예정', value: 'VIEWING'},
  {label: '투어완료', value: 'FINISH_VIEWING'},
];

export const STATUS = [
  {label: '접수', value: 'ACCEPT'},
  {label: '담당자인계', value: 'ASSIGN'},
  {label: '계약완료', value: 'COMPLETE'},
  {label: '계약실패', value: 'FAIL'},
];

export const lesseeDocuments = ['3자 계약서', '임대차 계약서', '보증보험증서', '기타(신분증 등)'];
export const LessorDocuments = ['위탁계약서', '계좌사본', '건축물대장', '사업자등록증', '매물사진', '기타'];
