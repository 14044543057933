import {useGetManagerMember, useSetFieldManager} from '@/hooks/room';
import {IRoom} from '@/interface';
import React from 'react';
import styled from 'styled-components';
import {BasicButton} from '../Button';
import {Dropdown} from '../Dropdown';
import {Modal} from '../Modal';

interface IProps {
  onClose?(e): void;
  onComplete?(e): void;
  data: IRoom;
}

interface IOption {
  id: number | undefined;
  label: string;
  email?: string;
}

export const FieldManagerWindow = ({onClose, onComplete, data}: IProps) => {
  const [request, result] = useSetFieldManager();
  const [memberRequest, members] = useGetManagerMember();

  const [selectedItem, setSelectedItem] = React.useState<IOption>({id: null, label: '미배정'});
  const [datas, setData] = React.useState<IOption[]>([]);

  const handleDropdown = React.useCallback(
    (selected) => {
      setSelectedItem(selected);
    },
    [selectedItem],
  );

  const handleConfirm = React.useCallback(
    (e) => {
      request(selectedItem.id, data?.id);
    },
    [selectedItem],
  );

  React.useEffect(() => {
    memberRequest('FIELD');
  }, []);

  React.useEffect(() => {
    if (members.called && members.data) {
      setData(
        members?.data?.data?.map((x) => {
          return {
            id: x.id,
            label: x.name,
            email: x.email,
          };
        }),
      );

      const selectedInternalAdmin = members?.data?.data?.find((x) => x.id === data?.fieldAdmin?.id);
      if (selectedInternalAdmin) {
        setSelectedItem({
          id: selectedInternalAdmin.id,
          label: selectedInternalAdmin.name,
          email: selectedInternalAdmin.email,
        });
      }
    } else if (!members.loading && members.error) {
      alert(members.error);
    }
  }, [members]);

  React.useEffect(() => {
    if (!result.loading && result.error) {
      alert(result.error);
    } else if (result.called && result.data) {
      onComplete(result.data);
    }
  }, [result]);

  return (
    <Modal title={'현장담당자 지정'} onClose={onClose} width={500}>
      <Root>
        <Container>
          현장 담당자 지정
          <DropdownBox
            maxOptionsHeight={220}
            active={selectedItem}
            options={[{id: null, label: '미배정'}, ...datas]}
            onSelected={(selected) => handleDropdown(selected)}
          />
        </Container>
      </Root>
      <ButtonContainer>
        <BasicButton borderRadius={4} type="button" size="medium" onClick={onClose}>
          취소
        </BasicButton>
        <BasicButton borderRadius={4} type="button" size="medium" colorType="darkGreen" onClick={handleConfirm}>
          수정하기
        </BasicButton>
      </ButtonContainer>
    </Modal>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 250px;
`;

const Container = styled.div`
  padding: 20px 12px 12px 0px;
  display: flex;
`;

const DropdownBox = styled(Dropdown)`
  width: 175px;
  margin-left: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-right: 8px;
  }
`;
