import {useContractDetail, useCreateContract} from '@/hooks/room';
import theme from '@/styled-components/theme';
import React, {useState} from 'react';
import styled from 'styled-components';
import {BasicButton} from '../Button';
import {DateSelector} from '../DateSelector';
import {Dropdown} from '../Dropdown';
import {Modal} from '../Modal';
import {TextField} from '../TextField';
import Typography from '../Typography';
import {IContractInfo, IRoom} from '@/interface';
import DaumPostcode from 'react-daum-postcode';
import Icon_x from '@/components/Modal/icon__x.svg';

interface IProps {
  onClose?(e): void;
  onComplete?(e): void;
  data: IRoom;
}

interface IOption {
  id: number | undefined;
  label: string;
  value?: string;
}

const initialValue = {
  address: null,
  broker: null,
  brokerMobile: null,
  company: null,
  contractDate: null,
  contractType: null,
  detailAddress: null,
  endDate: null,
  id: null,
  lessor: null,
  lessorMobile: null,
  paymentDate: null,
  startDate: null,
};

const options: IOption[] = [
  {
    id: 1,
    label: '신규',
    value: 'NEW',
  },
  {
    id: 2,
    label: '연장',
    value: 'CONTINUE',
  },
  {
    id: 3,
    label: '계약실패',
    value: 'FAIL',
  },
];

const POST_WIDTH = 500;
const POST_HEIGHT = 450;

export const ContractInfoWindow = ({onClose, onComplete, data}: IProps) => {
  const [selectedItem, setSelectedItem] = React.useState<IOption>({id: null, label: '선택'});
  const [create, createResponse] = useCreateContract();
  const [open, setOpen] = useState<boolean>(false);
  const [getContractDetail, result] = useContractDetail();

  const [values, setValues] = React.useState<any>(initialValue);

  React.useEffect(() => {
    if (data?.id) {
      getContractDetail(data?.id);
      setValues({
        ...values,
        id: data.id,
      });
    }
  }, []);

  const handleConfirm = React.useCallback(
    (e) => {
      if (selectedItem.id === null) {
        alert('계약구분을 선택해 주세요');
        return;
      }

      if (selectedItem.id !== 3 && Object.keys(initialValue).filter((i) => !values[i]).length) {
        alert('계약구분이 신규와 연장은 값을 전부 입력해야 합니다.');
        return;
      }

      create(values);
    },
    [selectedItem, values],
  );

  const handleDropdown = React.useCallback(
    (selected) => {
      if (selected.id) {
        setSelectedItem(selected);
        setValues({...values, ['contractType']: selected.value});
      }
    },
    [selectedItem, values],
  );

  const handleChange = React.useCallback(
    (type, value) => {
      setValues({...values, [type]: value});
    },
    [values],
  );

  const execPostCode = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';
    if (data.userSelectedType === 'R') {
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setValues({
      ...values,
      address: fullAddress,
    });
    setOpen(false);
  };

  React.useEffect(() => {
    const regex = /[^0-9]/g;
    if (regex.test(values.lessorMobile) && values.lessorMobile) {
      const newNum = values.lessorMobile.replace(/[^0-9]/g, '');
      setValues({
        ...values,
        lessorMobile: newNum,
      });
    }

    if (regex.test(values.brokerMobile) && values.brokerMobile) {
      const newNum = values.brokerMobile.replace(/[^0-9]/g, '');
      setValues({
        ...values,
        brokerMobile: newNum,
      });
    }
  }, [values.lessorMobile, values.brokerMobile]);

  React.useEffect(() => {
    if (result?.data?.data) {
      setValues(result?.data?.data);

      if (result?.data?.data?.contractType) {
        setSelectedItem(options.find((x) => x.value === result?.data?.data?.contractType));
      }
    } else if (!result?.loading && result.error) {
      alert(result.error);
    }
  }, [result]);

  React.useEffect(() => {
    if (createResponse.called && createResponse.data) {
      onComplete(createResponse);
    } else if (!createResponse?.loading && createResponse.error) {
      alert(createResponse.error);
    }
  }, [createResponse]);

  const handleDateChange = React.useCallback(
    (type, date) => {
      setValues({...values, [type]: new Date(date).getTime()});
    },
    [values],
  );

  return (
    <Modal title={'계약정보'} onClose={onClose} width={700}>
      <Root>
        <Container>
          <Label>계약구분</Label>
          <DropdownBox
            active={selectedItem}
            options={[{id: null, label: '선택'}, ...options]}
            onSelected={(selected) => handleDropdown(selected)}
          />
        </Container>
        <Container>
          <Label>계약일</Label>
          <DateSelector
            textHolder="날짜선택"
            value={values?.contractDate}
            onChange={(date) => handleDateChange('contractDate', date)}
          />
        </Container>
        <Container>
          <Label>계약기간</Label>
          <DateSelector
            textHolder="시작일"
            value={values?.startDate}
            onChange={(date) => handleDateChange('startDate', date)}
          />
          &nbsp;&nbsp;~&nbsp;&nbsp;
          <DateSelector
            textHolder="종료일"
            value={values?.endDate}
            onChange={(date) => handleDateChange('endDate', date)}
          />
        </Container>
        <Container>
          <Label>결제일</Label>
          <DateSelector
            textHolder="날짜선택"
            value={values?.paymentDate}
            onChange={(date) => handleDateChange('paymentDate', date)}
          />
        </Container>
        <Container>
          <Label>주소</Label>
          <InputTextField
            isAddressInput={true}
            value={values?.address}
            onChange={(e) => handleChange('address', e.target.value)}
          />
          <CallApiBtn onClick={() => setOpen(true)}>주소검색</CallApiBtn>
          {open && (
            <AdressModal>
              <CloseBtn onClick={() => setOpen(false)}>
                <img src={Icon_x} />
              </CloseBtn>
              <DaumPostcode
                onComplete={execPostCode}
                autoClose
                width={POST_WIDTH}
                height={POST_HEIGHT}
                style={{
                  position: 'absolute',
                  zIndex: 100,
                  border: `5px solid ${theme.palette.primary}`,
                }}
              />
            </AdressModal>
          )}
        </Container>
        <Container>
          <Label>상세주소</Label>
          <InputTextField
            value={values?.detailAddress}
            onChange={(e) => handleChange('detailAddress', e.target.value)}
          />
        </Container>
        <Container>
          <Label>임대인 이름</Label>
          <InputTextField value={values?.lessor} onChange={(e) => handleChange('lessor', e.target.value)} />
        </Container>
        <Container>
          <Label>임대인 연락처</Label>
          <InputTextField value={values?.lessorMobile} onChange={(e) => handleChange('lessorMobile', e.target.value)} />
        </Container>
        <Container>
          <Label>담당업체명</Label>
          <InputTextField value={values?.company} onChange={(e) => handleChange('company', e.target.value)} />
        </Container>
        <Container>
          <Label>담당중개사</Label>
          <InputTextField value={values?.broker} onChange={(e) => handleChange('broker', e.target.value)} />
        </Container>
        <Container>
          <Label>중개사연락처</Label>
          <InputTextField value={values?.brokerMobile} onChange={(e) => handleChange('brokerMobile', e.target.value)} />
        </Container>
      </Root>
      <ButtonContainer>
        <BasicButton borderRadius={4} type="button" size="medium" onClick={onClose}>
          닫기
        </BasicButton>
        <BasicButton borderRadius={4} type="button" size="medium" colorType="darkGreen" onClick={handleConfirm}>
          수정하기
        </BasicButton>
      </ButtonContainer>
    </Modal>
  );
};

const AdressModal = styled.div`
  position: absolute;
  z-index: 99;
  top: 160px;
  left: 75px;
  width: 600px;
`;

const CloseBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -10px;
  width: 20px;
  height: 20px;
  z-index: 101;
  background-color: ${theme.palette.primary};
  cursor: pointer;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 650px;
`;

const Label = styled(Typography)`
  width: 110px;
`;

// eslint-disable-next-line react/jsx-no-undef
const TextLabel = styled(({...props}) => <Typography {...props} />)`
  border: 1px solid ${theme.palette.black};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  margin-left: 17px;

  ${({disable}) =>
    disable &&
    `
    color: ${theme.palette.border};
    border: 1px solid ${theme.palette.border};
  `}
`;

const InputTextField = styled(({...props}) => <TextField allowSpace fullWidth {...props} />)`
  box-sizing: border-box;
  margin-left: 17px;
`;

const Container = styled.div`
  padding: 20px 12px 12px 0px;
  & + & {
    padding: 4px 12px 12px 0px;
  }

  display: flex;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-right: 8px;
  }
`;

const DropdownBox = styled(Dropdown)`
  width: 100%;
  margin-left: 16px;
`;

const CallApiBtn = styled.button`
  width: 15%;
  margin-left: 10px;
  border: none;
  background-color: ${theme.palette.primary};
  color: white;
`;
