import {useSession} from '@/hooks/session';
import {DocumentType, IFiles, IRoom} from '@/interface';
import theme from '@/styled-components/theme';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import {BasicButton} from '../Button';
import {Dropdown} from '../Dropdown';
import {Modal} from '../Modal';
import Typography from '../Typography';
import IconX from './icon__x.svg';
import ReactStars from 'react-rating-stars-component';
import {getRegionTypeByValue, getRentCostGroupByValue} from '@/page/ReceiptHistory/data';
import {ImageDetailPopup} from './ImageDetailView';
import {useRemoveReview} from '@/hooks/room';

interface IProps {
  onClose?(e): void;
  onComplete?(e): void;
  data?: IReview;
  roomId: number;
}

interface IReview {
  contents: string;
  deleted: boolean;
  createdAt: number;
  id: number;
  imageUriList: any[];
  regionTypes: string[];
  regionType: string;
  rentCostGroup: string;
  star: number;
  userName: string;
}

interface IDoc {
  id?: number;
  url: string;
  fileName: string;
}

export const ReviewDetailPopup = ({onClose, onComplete, data, roomId}: IProps) => {
  const {user} = useSession();
  const [removeReview, result] = useRemoveReview();

  const handleDelete = React.useCallback((e) => {
    if (!roomId) {
      alert('roomId가 없습니다');
      return;
    }
    if (confirm('해당 후기를 삭제하시겠습니까?')) {
      removeReview(roomId);
    }
  }, []);

  React.useEffect(() => {
    if (!result.called && result.error) {
      alert(result.error);
      return;
    }

    if (!result.called && result.data) {
      onComplete(result.data?.data);
      return;
    }
  }, [result]);

  return (
    <Modal onClose={onClose} width={500}>
      <Root>
        <Item data={data} roomId={roomId} />
      </Root>

      <ButtonContainer>
        {user.adminType === 'FIELD' ? (
          <BasicButton borderRadius={4} type="button" size="medium" onClick={onClose}>
            닫기
          </BasicButton>
        ) : (
          <>
            <BasicButton borderRadius={4} type="button" size="medium" onClick={onClose}>
              취소
            </BasicButton>
            <BasicButton borderRadius={4} type="button" size="medium" colorType="darkGreen" onClick={handleDelete}>
              삭제
            </BasicButton>
          </>
        )}
      </ButtonContainer>
    </Modal>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 350px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-right: 8px;
  }
`;

const Item = ({data, roomId}: IProps) => {
  const [imagePopup, setImagePopup] = React.useState(null);

  const handleImageView = React.useCallback(
    (e) => {
      const image = data.imageUriList[e.target.id];
      setImagePopup(image);
    },
    [data],
  );

  const handleImagePage = (page) => {
    setImagePopup(data.imageUriList[page - 1]);
  };

  return (
    <ItemRoot>
      <ItemContainer>
        <ReactStars count={5} size={16} value={data?.star} activeColor="#ffd700" edit={false} />
        <Typography color={theme.palette.blackLighter} style={{marginTop: '3px', marginBottom: '0px'}}>
          &nbsp;&nbsp;&nbsp;
          {data?.star}
          &nbsp;&nbsp;&nbsp;|
        </Typography>
        <DateContainer>
          <Typography color={theme.palette.blackLighter} style={{marginTop: '0px', marginBottom: '0px'}}>
            {moment(data?.createdAt * 1000).format('YYYY.MM.DD')}
          </Typography>
        </DateContainer>
      </ItemContainer>

      <ItemContainer style={{marginTop: '16px'}}>
        <Typography color={theme.palette.blackLighter}>{data.userName}</Typography>
        <Typography color={theme.palette.blackLighter}>
          &nbsp;&nbsp;|&nbsp;&nbsp;{getRegionTypeByValue(data?.regionTypes) || data.regionType}
        </Typography>
        <Typography color={theme.palette.blackLighter}>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          {getRentCostGroupByValue(data?.rentCostGroup)}
        </Typography>
      </ItemContainer>

      {data && data?.imageUriList?.length > 0 && (
        <ItemContainer style={{marginTop: '16px'}}>
          {data?.imageUriList?.map((i, index) => (
            <Image key={i} id={`${index}`} url={i} onClick={handleImageView} />
          ))}
        </ItemContainer>
      )}

      <ItemContainer style={{borderBottom: '1px solid #aaaaaa', marginTop: '16px'}}>
        <Typography color={theme.palette.blackLighter} fontWeight={'500'} style={{padding: '6px 0px'}}>
          {data.contents}
        </Typography>
      </ItemContainer>

      {imagePopup && (
        <ImageDetailPopup
          activePage={data.imageUriList.indexOf(imagePopup)}
          page={data.imageUriList.length}
          src={imagePopup}
          onChange={handleImagePage}
          onClose={() => setImagePopup(null)}
        />
      )}
    </ItemRoot>
  );
};

const Image = styled.img<{url: string}>`
  width: 70px;
  height: 70px;
  cursor: pointer;
  margin-right: 10px;
  ${({url}) =>
    url &&
    `background-image: url(${url});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;`}
`;

const ItemRoot = styled.div`
  display: flex;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ItemContainer = styled.div`
  display: flex;
`;

const DateContainer = styled.div`
  margin-left: 10px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
