import React from 'react';
import styled from 'styled-components';
import Typography from '../Typography';

interface IProp {
  onChange?(id, target): void;
  id: string;
}

export const FileAttach = ({id, onChange}: IProp) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleChange = React.useCallback((type, files) => {
    onChange(type, files);
  }, []);

  const handleAttach = React.useCallback(
    (id: string) => {
      inputRef.current.value = null;
      inputRef.current.click();
    },
    [inputRef],
  );

  return (
    <div>
      <AttachedFile onClick={() => handleAttach(id)}>첨부하기</AttachedFile>
      <input ref={inputRef} type="file" accept="*" onChange={(e) => handleChange(id, e.target.files)} hidden />
    </div>
  );
};

const AttachedFile = styled((props) => <Typography as="div" {...props} />)`
  width: 100%;
  display: flex;
  text-decoration-line: underline;
  cursor: pointer;

  align-items: center;
`;
