import {WarningText} from '@/components/styledComponents/Common';
import Typography from '@/components/Typography';
import theme from '@/styled-components/theme';
import React from 'react';
import styled from 'styled-components';
import {IForm} from './index.d';

export const TextField = React.forwardRef<HTMLInputElement, IForm>(
  (
    {
      borderRadius,
      isAddressInput,
      className,
      label,
      error,
      fullWidth,
      onChange,
      required,
      value,
      allowSpace,
      validator,
      suffix,
      onBlur,
      onFocus,
      ...props
    },
    ref,
  ) => {
    const [inputValue, setValue] = React.useState<string | ReadonlyArray<string> | number>(value || '');
    const [active, setActive] = React.useState<boolean>(false);
    const val = React.useMemo(() => value ?? inputValue, [value, inputValue]);
    const labelActive = React.useMemo(() => !!(val || active || val === '0' || typeof val === 'number'), [val, active]);

    const handleChange = React.useCallback(
      (e) => {
        const newValue = allowSpace ? e.target.value : e.target.value.trim();

        if (validator && newValue.length && !validator(newValue)) {
          return;
        }

        setValue(newValue);
        if (onChange) {
          onChange(e);
        }
      },
      [onChange, validator, allowSpace, setValue],
    );

    const handleFocus = React.useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        if (!val) {
          setActive(true);
        }

        if (onFocus) {
          onFocus(e);
        }
      },
      [val, setActive, onFocus],
    );

    const handleBlur = React.useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        if (!val) {
          setActive(false);
        }

        if (onBlur) {
          onBlur(e);
        }
      },
      [val, setActive, onBlur],
    );

    return (
      <Wrapper isAddressInput={isAddressInput} fullWidth={fullWidth} hasLabel={label?.length} className={className}>
        <InputContainer required={required}>
          <InputForm
            {...props}
            borderRadius={borderRadius}
            active={Number(active)}
            hasLabel={label?.length}
            value={val}
            ref={ref}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <Fieldset active={labelActive} hasLabel={label?.length} borderRadius={borderRadius}>
            {!!label?.length && <legend>{label}</legend>}
          </Fieldset>
          {!!label?.length && (
            <Label
              variant={labelActive ? 'body2' : 'body1'}
              color={theme.palette[labelActive ? 'bk05' : 'bk04']}
              active={labelActive}
            >
              {label}
            </Label>
          )}
          {suffix?.length && <FieldSuffix>{suffix}</FieldSuffix>}
        </InputContainer>
        {!!error?.length && <WarningText>{error}</WarningText>}
      </Wrapper>
    );
  },
);

const Label = styled(Typography).attrs({as: 'label'})<{active}>`
  display: inline-block;
  position: absolute;
  left: 16px;
  top: 14px;
  pointer-events: none;
  transition: all 400ms;
  ${({active}) =>
    active &&
    `
    top: -9px;
    padding: 0 6px;
  `}
`;

export const Wrapper = styled.div<{isAddressInput; fullWidth; hasLabel}>`
  width: ${({isAddressInput}) => (isAddressInput ? `85%` : `100%`)};
  position: relative;
  ${({fullWidth}) => !fullWidth && `max-width: 200px;`}
  ${({hasLabel}) => hasLabel && `padding-top: 8px;`}
`;

export const Fieldset = styled.fieldset<{active; hasLabel; borderRadius}>`
  top: ${({hasLabel}) => (hasLabel ? -5 : 0)}px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  border-style: solid;
  border-width: 1px;
  border-radius: ${({borderRadius}) => (borderRadius ? `${borderRadius}px` : `inherit`)};
  border-color: ${theme.palette.borderGray};
  pointer-events: none;
  transition: all 400ms;
  legend {
    width: auto;
    height: 11px;
    display: block;
    padding: 0;
    font-size: 12px;
    max-width: 0.01px;
    text-align: left;
    visibility: hidden;
    transition: all 100ms;
    ${({active}) =>
      active &&
      `
      max-width: 1000px;
      padding: 0 6px;
      margin-left: 6px;
    `}
  }
`;

const InputContainer = styled.div<{
  required?: boolean;
}>`
  position: relative;
  ${({required, theme: {palette}}) =>
    required &&
    `
    ::after {
      content: "";
      width: 6px;
      height: 6px;
      display: block;
      position: absolute;
      background-color: ${palette.primaryLighter};
      right: 6.5px;
      top: 6px;
      transform: skewX(-9.5deg);
    }
  `}
`;

const InputForm = styled.input<{
  active?: number;
  hasLabel?: number;
  borderRadius?: number;
}>`
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.29;
  height: 44px;
  width: 100%;
  padding-left: 15px;
  background-color: ${theme.palette.white};
  border: 0;
  border-radius: ${({borderRadius}) => (borderRadius ? `${borderRadius}px` : `inherit`)};
  color: ${theme.palette.black};
  &&& {
    :disabled {
      cursor: default;
      color: ${theme.palette.black};
      background-color: ${theme.palette.black};
    }
  }
  :enabled:not([readonly]):focus {
    outline: 0;
    & + ${Fieldset} {
      border-color: ${theme.palette.black};
      & + ${Label} {
        color: ${theme.palette.black};
      }
    }
  }
  ::placeholder {
    color: ${theme.palette.black};
    transition: all 400ms;
    opacity: ${({active, hasLabel}) => (!hasLabel || active ? 0.5 : 0)};
  }
  -webkit-autofill {
    color: #fff !important;
  }
  -webkit-appearance: none;
`;

const FieldSuffix = styled((props) => <Typography as="div" variant="body1" {...props} />)`
  position: absolute;
  bottom: 0;
  right: 0;
  color: ${theme.palette.black};
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 100%;
  pointer-events: none;
`;
