import {useAccountUpdate} from '@/hooks/room';
import theme from '@/styled-components/theme';
import React from 'react';
import styled from 'styled-components';
import {BasicButton} from '../Button';
import {DateSelector} from '../DateSelector';
import {Dropdown} from '../Dropdown';
import {Modal} from '../Modal';
import {TextField} from '../TextField';
import Typography from '../Typography';
import moment from 'moment';
import {IAccount, IAccountUpdate, IContractInfo, IRoom} from '@/interface';
import {isValidPassword} from '@/utils/inputValidator';
import {useAdminDetail} from '@/hooks/session';
import {useRecoilState, useRecoilValue} from 'recoil';
import {userState} from '@/stores/session';
import {IUserWithToken} from '@/hooks';

interface IProps {
  onClose?(e): void;
  onComplete?(e): void;
  data: any;
}

interface IOption {
  id: number | undefined;
  label: string;
  value?: string;
}

const initialValue = {
  adminType: null,
  authorizeType: null,
  createdAt: null,
  deleted: null,
  email: null,
  id: null,
  mobile: null,
  name: null,
  status: null,
  updatedAt: null,
  password: null,
  password2: null,
};

const options: IOption[] = [
  {
    id: 1,
    label: '신규',
    value: 'NEW',
  },
  {
    id: 2,
    label: '연장',
    value: 'CONTINUE',
  },
  {
    id: 3,
    label: '계약실패',
    value: 'FAIL',
  },
];

export const UserAccountWindow = ({onClose, onComplete, data}: IProps) => {
  const [selectedItem, setSelectedItem] = React.useState<IOption>({id: null, label: '선택'});
  const [update, result] = useAccountUpdate();
  const [errors, setErrors] = React.useState<{[id: string]: string}>({});
  const [values, setValues] = React.useState<IAccount>(initialValue);
  const [meInfo, meInfoResult] = useAdminDetail();
  const [user, setUser] = useRecoilState(userState);

  React.useEffect(() => {
    setValues({
      ...(user as IAccount),
    });
  }, []);

  const handleConfirm = React.useCallback(
    (e) => {
      Object.keys(errors).forEach((err) => {
        if (errors[err]) {
          alert(errors[err]);
          return;
        }
      });
      update(values as IAccountUpdate);
    },
    [selectedItem, values],
  );

  React.useEffect(() => {
    if (!result.loading && result?.called && result?.data) {
      meInfo();
    } else if (!result.loading && result.error) {
      alert(result?.error?.response?.data?.message);
    }
  }, [result]);

  React.useEffect(() => {
    if (!meInfoResult.loading && meInfoResult.called && meInfoResult.data) {
      const u = meInfoResult.data?.data as IUserWithToken;
      setUser({...user, ...u});
      alert('업데이트 되었습니다');
      onComplete(meInfoResult);
    }
  }, [meInfoResult]);

  const handleChange = React.useCallback(
    (type, value) => {
      setErrors({[type]: undefined});
      // checkValidate({[type]: value});
      setValues({...values, [type]: value});
    },
    [values],
  );

  // const checkValidate = React.useCallback(
  //   (obj) => {
  //     console.log(values.password);
  //   },
  //   [values, errors],
  // );

  const handleDateChange = React.useCallback(
    (type, date) => {
      setValues({...values, [type]: new Date(date).getTime()});
    },
    [values],
  );

  return (
    <Modal title={'정보변경'} onClose={onClose} width={600}>
      <Root>
        <Container>
          <Label>담당자 구분</Label>
          <Label>{values?.adminType === 'INTERNAL' ? '내부' : '현장'} </Label>
        </Container>
        <Container>
          <Label>사용자 ID</Label>
          <Label>{values?.email} </Label>
        </Container>
        <Container>
          <Label>비밀번호</Label>
          <InputTextField
            error={errors.password}
            value={values?.password}
            type="password"
            onChange={(e) => handleChange('password', e.target.value)}
          />
        </Container>
        <Container>
          <Label>비밀번호 확인</Label>
          <InputTextField
            error={errors.password2}
            value={values?.password2}
            type="password"
            onChange={(e) => handleChange('password2', e.target.value)}
          />
        </Container>
        <Container>
          <Label>담당자명</Label>
          <InputTextField value={values?.name} onChange={(e) => handleChange('name', e.target.value)} />
        </Container>
        <Container>
          <Label>연락처</Label>
          <InputTextField value={values?.mobile} onChange={(e) => handleChange('mobile', e.target.value)} />
        </Container>
      </Root>
      <ButtonContainer>
        <BasicButton borderRadius={4} type="button" size="medium" onClick={onClose}>
          닫기
        </BasicButton>
        <BasicButton borderRadius={4} type="button" size="medium" colorType="darkGreen" onClick={handleConfirm}>
          수정하기
        </BasicButton>
      </ButtonContainer>
    </Modal>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 450px;
`;

const Label = styled(Typography)`
  width: 150px;
`;

// eslint-disable-next-line react/jsx-no-undef
const TextLabel = styled(({...props}) => <Typography {...props} />)`
  border: 1px solid ${theme.palette.black};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  margin-left: 17px;

  ${({disable}) =>
    disable &&
    `
    color: ${theme.palette.border};
    border: 1px solid ${theme.palette.border};
  `}
`;

const InputTextField = styled(({...props}) => <TextField allowSpace fullWidth {...props} />)`
  box-sizing: border-box;

  margin-left: 17px;
`;

const Container = styled.div`
  padding: 20px 12px 12px 0px;
  & + & {
    padding: 4px 12px 12px 0px;
  }

  display: flex;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-right: 8px;
  }
`;

const DropdownBox = styled(Dropdown)`
  width: 100%;
  margin-left: 16px;
`;
