import {useSession} from '@/hooks/session';
import React from 'react';
import {Redirect, Route as DomRoute, RouteComponentProps, RouteProps} from 'react-router-dom';
import {pathname} from '../../constants';

interface IRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  isPrivate?: boolean;
}

export const Route = ({component: Component, isPrivate, ...rest}: IRouteProps) => {
  const {hasSession} = useSession();

  return (
    <DomRoute
      {...rest}
      render={(props) =>
        !isPrivate || hasSession ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: pathname.SIGN_IN,
              state: {from: location.pathname},
            }}
          />
        )
      }
    />
  );
};
