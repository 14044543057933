export const replacePathParams = (pathname: string, params: {[id: string]: string}) => {
  return pathname
    .split('/')
    .map((i) => {
      if (i.startsWith(':')) {
        const key = i.replace(':', '');

        if (key?.length) {
          const value = params[key];

          if (value?.length) {
            return value;
          }
        }
      }

      return i;
    })
    .join('/');
};

export const getCookie = (cname) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let c of ca) {
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
};

export const setCookie = (cname: string, cvalue: string, exdays: number, domain?: string) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();

  // eslint-disable-next-line no-extra-boolean-cast
  document.cookie = `${cname}=${cvalue};${expires};path=/${!!domain?.length ? `;domain=${domain}` : ''}`;
};

export const removeCookie = (cname: string, domain?: string) => {
  setCookie(cname, undefined, -1, domain);
};

export const wait = (m: number) => new Promise((r) => setTimeout(r, m));

export const convertToPy = (n: any) => {
  if (isNaN(n)) {
    return undefined;
  }

  n = Number(n);

  return Math.round(n / 3.33);
};

export const convertToM2 = (n: any) => {
  if (isNaN(n)) {
    return undefined;
  }

  n = Number(n);

  return Math.round(n * 3.33 * 100) / 100.0;
};

export const replaceLineBreak = (text: string) => text?.replace(/\n/g, '<br/>') || '';

export const getFileNameFromUrl = (url: string) => (url ? url.substring(url.lastIndexOf('/') + 1) : undefined);

export const getLastSegment = (url: string) => (url ? url.substring(url.lastIndexOf('/') + 1) : undefined);

export const numberWithCommas = (n: number) => n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const textLengthOverCut = (text: string) => {
  if (text.length > 15) {
    const t = text.substring(0, 20);
    return t + '...';
  } else {
    return text;
  }
};
