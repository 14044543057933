import React, {useEffect} from 'react';
import styled from 'styled-components';
import DatePicker, {registerLocale} from 'react-datepicker';
import theme from '@/styled-components/theme';
import ko from 'date-fns/locale/ko';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ko', ko);

interface IProps {
  value?: string;
  onClick?(): void;
  textHolder?: string;
}

export const DateSelector = ({value, onChange, textHolder}) => {
  const [startDate, setStartDate] = React.useState(value);

  React.useEffect(() => {
    onChange(startDate);
  }, [startDate]);

  const DateButton = React.forwardRef<HTMLButtonElement, IProps>(({value, onClick}, ref) => (
    <DatePickerButton onClick={onClick} ref={ref}>
      {value ? (
        <DateValue>{value}</DateValue>
      ) : (
        <>
          <i className="far fa-calendar-alt"></i>
          <span>{textHolder ? textHolder : '날짜선택'}</span>
        </>
      )}
    </DatePickerButton>
  ));

  return (
    <Root>
      <DatePicker
        locale="ko"
        dateFormat="yyyy/MM/dd"
        placeholder={textHolder}
        value={value}
        selected={value}
        onChange={(date) => setStartDate(date)}
        customInput={<DateButton />}
      />
    </Root>
  );
};

const DateValue = styled.span`
  padding-left: 25%;
`;

const Root = styled.div``;

const DatePickerButton = styled.button`
  display: flex;
  align-items: center;
  width: 175px;
  height: 34px;
  padding-left: 10px;
  border-radius: 4px;
  border: solid 1px #eeeeee;
  background-color: #ffffff;
  cursor: pointer;
  i {
    margin-right: 50px;
    color: ${theme.palette.border};
  }

  span {
    color: ${theme.palette.blackLighter};
  }
`;
