import {IAccount, IAccountUpdate, IContractInfo, IFiles, IRoom, ISignUp} from '@/interface';
import {Memo} from '@/page/ClientDetail/MemoTab';
import {useAxios} from './axios';

export const useRoomSearch = () => {
  const [request, response] = useAxios();

  const run = (params: any, page: number, size: number = 20) => {
    return request({
      url: '/v2/room/search',
      method: 'GET',
      params: {
        ...params,
        page,
        size,
      },
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useContinueList = () => {
  const [request, response] = useAxios();

  const run = (params: any, page: number, size: number = 20) => {
    return request({
      url: '/v2/room/dashboard/continue',
      method: 'GET',
      params: {
        ...params,
        page,
        size,
      },
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useMonthList = () => {
  const [request, response] = useAxios();

  const run = (params: any, page: number, size: number = 20) => {
    return request({
      url: '/v2/room/dashboard/month',
      method: 'GET',
      params: {
        ...params,
        page,
        size,
      },
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const usePayTypeList = () => {
  const [request, response] = useAxios();

  const run = (params: any, page: number, size: number = 20) => {
    return request({
      url: '/v2/room/dashboard/paytype',
      method: 'GET',
      params: {
        ...params,
        page,
        size,
      },
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useSetInternalManager = () => {
  const [request, response] = useAxios();

  const run = (adminId: number, id: number) => {
    return request({
      url: '/v2/room/assign/internal',
      method: 'POST',
      data: {
        adminId,
        id,
      },
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useSetFieldManager = () => {
  const [request, response] = useAxios();

  const run = (adminId: number, id: number) => {
    return request({
      url: '/v2/room/assign/field',
      method: 'POST',
      data: {
        adminId,
        id,
      },
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useSetNewMemo = () => {
  const [request, response] = useAxios();

  const run = (roomId: number, contents: string, status: string) => {
    return request({
      url: '/v2/memo/register',
      method: 'POST',
      data: {
        roomId,
        contents,
        status,
      },
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useSetUpdateMemo = () => {
  const [request, response] = useAxios();

  const run = (id: number, contents: string, roomId: number, status: string) => {
    return request({
      url: '/v2/memo/update',
      method: 'POST',
      data: {
        id,
        contents,
        roomId,
        status,
      },
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useMemoList = () => {
  const [request, response] = useAxios();

  const run = (roomId: number) => {
    return request({
      url: `v2/memo/search?roomId=${roomId}`,
      method: 'GET',
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useGetManagerMember = () => {
  const [request, response] = useAxios();

  const run = (adminType: 'INTERNAL' | 'FIELD') => {
    return request({
      url: '/v2/member/manager/list',
      method: 'GET',
      params: {
        adminType,
      },
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useDocumentList = () => {
  const [request, response] = useAxios();

  const run = (roomId: number) => {
    return request({
      url: `/v2/documents/${roomId}/search`,
      method: 'GET',
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useDocumentRegister = () => {
  const [request, response] = useAxios();

  const run = (data: IFiles[]) => {
    return request({
      url: `/v2/documents/register`,
      method: 'POST',
      data,
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useDocumentDelete = () => {
  const [request, response] = useAxios();

  const run = (id: number) => {
    return request({
      url: `/v2/documents/${id}/delete`,
      method: 'POST',
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useUploadFile = () => {
  const [request, response] = useAxios();

  const run = (type: string, data: FormData) => {
    return request({
      url: `/v1/upload/${type}/file`,
      method: 'POST',
      data,
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useContractDetail = () => {
  const [request, response] = useAxios();

  const run = (id: number) => {
    return request({
      url: `/v2/contract/${id}/detail`,
      method: 'GET',
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useCreateContract = () => {
  const [request, response] = useAxios();

  const run = (data: IContractInfo) => {
    return request({
      url: `/v2/contract/register`,
      method: 'POST',
      data,
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useAccountList = () => {
  const [request, response] = useAxios();

  const run = (params: any, page: number) => {
    return request({
      url: `/v2/member/search`,
      method: 'GET',
      params: {
        ...params,
        page,
      },
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useMemberUpdateList = () => {
  const [request, response] = useAxios();

  const run = (data: IAccount[]) => {
    return request({
      url: `/v2/member/update/list`,
      method: 'POST',
      data,
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useAccountUpdate = () => {
  const [request, response] = useAxios();

  const run = (data: IAccountUpdate) => {
    return request({
      url: `/v2/member/update`,
      method: 'POST',
      data,
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useSignUp = () => {
  const [request, response] = useAxios();

  const run = (data: ISignUp) => {
    return request({
      url: `/v1/auth/register`,
      method: 'POST',
      data,
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useDeleteAccount = () => {
  const [request, response] = useAxios();

  const run = (id: string) => {
    return request({
      url: `/v2/member/${id}/delete`,
      method: 'POST',
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useDeleteRoom = () => {
  const [request, response] = useAxios();

  const run = (id: number) => {
    return request({
      url: `/v2/room/${id}/deleted`,
      method: 'POST',
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useResetPassword = () => {
  const [request, response] = useAxios();

  const run = (id: number) => {
    return request({
      url: `/v2/member/${id}/password/reset`,
      method: 'POST',
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useRemoveReview = () => {
  const [request, response] = useAxios();

  const run = (id: number) => {
    return request({
      url: `/v2/review/${id}/deleted`,
      method: 'POST',
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useGetExcelData = () => {
  const [request, response] = useAxios();

  const run = (data: any) => {
    return request({
      url: `/v2/excel/room?${data}`,
      method: 'GET',
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useGetUserDataByPhone = () => {
  const [request, response] = useAxios();

  const run = (data: any) => {
    return request({
      url: `/v2/room/search/mobile?mobile=${data}`,
      method: 'GET',
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useGetDashBoard = () => {
  const [request, response] = useAxios();

  const run = (start?: number, end?: number) => {
    return request({
      url: '/v2/room/dashboard',
      params: {
        startDate: start,
        endDate: end,
      },
      method: 'GET',
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useUpdateRoom = () => {
  const [request, response] = useAxios();

  const run = (data: Partial<IRoom>) => {
    return request({
      url: '/v2/room/update',
      method: 'POST',
      data: data,
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useUpdateregister = () => {
  const [request, response] = useAxios();

  const run = (data: Partial<IRoom>) => {
    return request({
      url: '/v2/contract/register',
      method: 'POST',
      data: data,
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useGetMemoListByInternal = () => {
  const [request, response] = useAxios();

  const run = (id: number) => {
    return request({
      url: `/v2/memo/search/internal?roomId=${id}`,
      method: 'GET',
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useGetMemoListByField = () => {
  const [request, response] = useAxios();

  const run = (id: number) => {
    return request({
      url: `/v2/memo/search/field?roomId=${id}`,
      method: 'GET',
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useSaveMemo = () => {
  const [request, response] = useAxios();

  const run = (data: Partial<Memo>) => {
    return request({
      url: '/v2/memo/register',
      method: 'POST',
      data,
    });
  };

  return [run, response] as [typeof run, typeof response];
};
