import {hasSessionSelector, tokenSelector, userState} from '@/stores/session';
import gql from 'graphql-tag';
import React from 'react';

import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';
import {IResult, ISessionHookOptions, IUserWithToken} from '.';
import {useAxios} from './axios';

export const useSession = () => {
  const user = useRecoilValue(userState);
  const hasSession = useRecoilValue(hasSessionSelector);

  const result = {
    hasSession,
    user,
  };

  return result as typeof result;
};

export const useLogin = () => {
  const updateUserWithToken = useUpdateUserWithToken();
  const [request, response] = useAxios();

  const run = (email: string, password: string) => {
    return request({
      url: '/v1/auth/login',
      method: 'POST',
      data: {email, password},
    });
  };

  return [run, response] as [typeof run, typeof response];
};

export const useLogout = () => {
  const updateUserWithToken = useUpdateUserWithToken();
  // const [run, result] = useMutation<IResult<{}>, {email: string; password: string}>(MUTATION_LOGOUT, options);

  const logout = () => {
    // run();
    updateUserWithToken(undefined);
  };

  return logout;
};

export const useUpdateUserWithToken = () => {
  const [token, setToken] = useRecoilState(tokenSelector);
  const resetToken = useResetRecoilState(tokenSelector);
  const updateUser = useUpdateUser();
  const [user, setUser] = useRecoilState(userState);
  const [meInfo, result] = useAdminDetail();

  const update = (data?: IUserWithToken) => {
    if (data) {
      updateUser(data);
      setToken(data.accessToken);
    } else {
      resetToken();
      updateUser();
    }
  };

  React.useEffect(() => {
    if (token) {
      meInfo();
    }
  }, [token]);

  React.useEffect(() => {
    if (result.called && result.data) {
      const u = result.data?.data as IUserWithToken;
      setUser({...user, ...u});
    }
  }, [result]);
  return update;
};

export const useUpdateUser = () => {
  const setUser = useSetRecoilState(userState);
  const resetUser = useResetRecoilState(userState);

  const update = (data?: IUserWithToken) => {
    if (data) {
      setUser(data);
    } else {
      resetUser();
    }
  };

  return update;
};

export const useAdminDetail = () => {
  const [request, response] = useAxios();
  const {user} = useSession();

  const run = () => {
    return request({
      url: `/v2/member/${user?.id}/detail`,
      method: 'GET',
    });
  };

  return [run, response] as [typeof run, typeof response];
};
