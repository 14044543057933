import {tokenSelector} from '@/stores/session';
import Axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import {useCallback, useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';

// tslint:disable-next-line: interface-name
export interface UseAxiosResponse {
  called: boolean;
  data: any;
  loading: boolean;
  error: AxiosError;
}
export type UseAxiosPromise = Promise<AxiosResponse<any>>;
export type UseAxiosType = [(config?: AxiosRequestConfig) => UseAxiosPromise, UseAxiosResponse];

const axios = Axios.create({
  baseURL: window?.location.host.includes('admin.mubo.peterpanz')
    ? 'https://prodapi.unosolution.de/api'
    : 'https://devapi.unosolution.de/api',
});

export const useAxios = (): UseAxiosType => {
  const token = useRecoilValue(tokenSelector);

  const [data, setData] = useState<any>();
  const [error, setError] = useState<AxiosError<any>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [called, setCalled] = useState<boolean>(false);
  const [response, setResponse] = useState<UseAxiosResponse>({
    error,
    loading,
    called,
    data,
  });

  const request = useCallback(
    async (config?: AxiosRequestConfig) => {
      setCalled(false);
      setLoading(true);
      setData(undefined);
      setError(undefined);

      config = {
        ...config,
        headers: {
          ...config?.headers,
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const res = await axios(config);
        setData(res?.data);

        return res;
        // eslint-disable-next-line no-useless-catch
      } catch (e) {
        const error = e?.response?.data?.message ? e?.response?.data?.message : e;
        setError(error);
        throw error;
      } finally {
        setCalled(true);
        setLoading(false);
      }
    },
    [axios, token, setCalled, setLoading, setData, setError],
  );

  useEffect(() => {
    setResponse({
      error,
      loading,
      called,
      data,
    });
  }, [error, loading, data, called]);

  return [request, response];
};
