import {BasicButton} from '@/components/Button';
import {Dropdown} from '@/components/Dropdown';
import {useGetMemoListByField, useGetMemoListByInternal, useSaveMemo} from '@/hooks/room';
import {useSession} from '@/hooks/session';
import {IRoom} from '@/interface';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Table from './Table';
import {useRecoilState} from 'recoil';
import {changeRoomDataSttate} from '@/stores/room';

type MemoProps = {
  data: IRoom;
};

export type Memo = {
  adminId: number;
  contents: string;
  createdAt: number;
  createdBy: string;
  id: number;
  memoType: string;
  modifiedAt: number;
  modifiedBy: string;
  status: string;
  roomId?: number;
  name?: string;
};

const INITIALSTATE = {
  id: 1,
  label: '일반상담',
  value: 'NORMAL',
};

const options = [
  {
    id: 1,
    label: '일반상담',
    value: 'NORMAL',
  },
  {
    id: 2,
    label: '부재',
    value: 'MISSED',
  },
  {
    id: 3,
    label: '투어예정',
    value: 'VIEWING',
  },
  {
    id: 4,
    label: '투어완료',
    value: 'FINISH_VIEWING',
  },
];

export const getOptionLabelByValue = (value: string) => {
  return options.find((x) => x.value === value)?.label;
};

function MemoTab({data}: MemoProps) {
  const {user} = useSession();
  const [req, res] = useGetMemoListByInternal();
  const [fieldReq, fieldRes] = useGetMemoListByField();

  const [internalMemo, setInternalMemo] = useState<Memo[]>([] as Memo[]);
  const [fieldMemo, setFieldMemo] = useState<Memo[]>([] as Memo[]);

  const [regiReq, regiRes] = useSaveMemo();

  const [internal, setInternal] = useState<Memo>();
  const [field, setField] = useState<Memo>();

  const [internalMemoValue, setInternalMemoValue] = useState('');
  const [fieldMemoValue, setFieldMemoValue] = useState('');

  const [isChange, setIsChange] = useRecoilState(changeRoomDataSttate);

  const handleOnChangeInternal = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInternalMemoValue(e.target.value);
    setIsChange(true);
  };

  const handleSubmitInternal = () => {
    if (internal) {
      regiReq({...internal, contents: internalMemoValue, roomId: data.id, memoType: 'INTERNAL'})
        .then(() => {
          alert('완료되었습니다.');
          setIsChange(false);
          setInternalMemoValue('');
        })
        .catch((e) => {
          alert(e);
        });
    } else {
      regiReq({contents: internalMemoValue, roomId: data.id, name: user.name, memoType: 'INTERNAL'})
        .then(() => {
          alert('완료되었습니다.');
          setIsChange(false);
          setInternalMemoValue('');
        })
        .catch((e) => {
          alert(e);
        });
    }
  };

  const handleSubmitField = () => {
    if (field) {
      if (user?.adminType === 'INTERNAL') {
        regiReq({
          ...field,
          contents: fieldMemoValue,
          roomId: data.id,
          status: progress.value,
          memoType: 'FIELD',
          name: fieldWriter.trim(),
          // name:
          //   fieldWriter.trim() !== undefined && fieldWriter.trim().length > 0
          //     ? fieldWriter.trim()
          //     : data.fieldAdmin.fieldOffice + ' ' + data.fieldAdmin.name,
        })
          .then(() => {
            alert('완료되었습니다.');
            setFieldMemoValue('');
            setIsChange(false);
          })
          .catch((e) => {
            alert(e);
          });
      } else {
        regiReq({
          contents: fieldMemoValue,
          roomId: data.id,
          name: fieldWriter.trim(),
          // name:
          //   fieldWriter.trim() !== undefined && fieldWriter.trim().length > 0
          //     ? fieldWriter.trim()
          //     : data.fieldAdmin.fieldOffice + ' ' + data.fieldAdmin.name,
          memoType: 'FIELD',
          status: progress.value,
        })
          .then(() => {
            alert('완료되었습니다.');
            setIsChange(false);
            setFieldMemoValue('');
          })
          .catch((e) => {
            alert(e);
          });
      }
    } else {
      regiReq({
        contents: fieldMemoValue,
        roomId: data.id,
        name: fieldWriter.trim(),
        // name:
        //   fieldWriter.trim() !== undefined && fieldWriter.trim().length > 0
        //     ? fieldWriter.trim()
        //     : data.fieldAdmin.fieldOffice + ' ' + data.fieldAdmin.name,
        memoType: 'FIELD',
        status: progress.value,
      })
        .then(() => {
          alert('완료되었습니다.');
          setIsChange(false);
          setFieldMemoValue('');
        })
        .catch((e) => {
          alert(e);
        });
    }
  };

  const handleOnChangeField = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFieldMemoValue(e.target.value);
    setIsChange(true);
  };

  useEffect(() => {
    req(data?.id).then((v) => setInternalMemo(v.data.data));
    fieldReq(data?.id).then((v) => setFieldMemo(v.data.data));
  }, [regiRes]);

  const handleInternalClick = (item: Memo) => {
    setInternal(item);
    setInternalMemoValue(item.contents);
  };
  const handleFieldClick = (item: Memo) => {
    if (user?.adminType === 'INTERNAL') {
      setFieldMemoValue(item.contents);
      setField(item);
      setFieldWriter(item.createdBy);
    } else {
      setFieldTotalMemo(item.contents);
      setField(item);
      // setFieldWriter(item.createdBy);
    }
  };

  const handleDropdown = (select) => {
    setProgress(select);
    setIsChange(true);
  };

  const [progress, setProgress] = React.useState<any>(INITIALSTATE);
  const [fieldWriter, setFieldWriter] = useState('');
  const [fieldTotalMemo, setFieldTotalMemo] = useState('');

  const handleFieldTotalMemo = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFieldTotalMemo(e.target.value);
    setIsChange(true);
  };

  useEffect(() => {
    setIsChange(false);
  }, []);
  return (
    <Root>
      {user?.adminType === 'INTERNAL' ? (
        <>
          <Title>본사메모</Title>
          <Container>
            <Block>
              <span>내용</span>
              <textarea value={internalMemoValue} placeholder="직접입력" onChange={handleOnChangeInternal} />
            </Block>
            <Table handleOnClick={handleInternalClick} memos={internalMemo} />
            <ButtonContainer>
              <BasicButton borderRadius={4} type="button" size="medium" onClick={() => setInternalMemoValue('')}>
                초기화
              </BasicButton>
              <BasicButton
                borderRadius={4}
                type="button"
                size="medium"
                colorType="darkGreen"
                onClick={handleSubmitInternal}
                style={{background: '#1db177', color: '#fff'}}
              >
                등록 및 수정
              </BasicButton>
            </ButtonContainer>
          </Container>
          {/* 현장 */}
          <Title>현장메모</Title>
          <Container>
            <Block className="col">
              <div style={{display: 'flex', marginBottom: '10px', alignItems: 'center'}}>
                <ContentHeader>진행단계</ContentHeader>
                <DropdownBox active={progress} options={options} onSelected={(selected) => handleDropdown(selected)} />
                <Title>작성자</Title>
                <InputStyles
                  value={fieldWriter}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldWriter(e.target.value)}
                />
              </div>

              <div style={{display: 'flex'}}>
                <span>내용</span>
                <textarea value={fieldMemoValue} placeholder="직접입력" onChange={handleOnChangeField} />
              </div>
            </Block>
            <Table memos={fieldMemo} handleOnClick={handleFieldClick} />
            <ButtonContainer>
              <BasicButton borderRadius={4} type="button" size="medium" onClick={() => setFieldMemoValue('')}>
                초기화
              </BasicButton>
              <BasicButton
                borderRadius={4}
                type="button"
                size="medium"
                colorType="darkGreen"
                style={{background: '#1db177', color: '#fff'}}
                onClick={handleSubmitField}
              >
                등록 및 수정
              </BasicButton>
            </ButtonContainer>
          </Container>
        </>
      ) : (
        <>
          {/* 현장 */}
          <Title>현장메모</Title>
          <Container>
            <Block className="col">
              <div style={{display: 'flex', marginBottom: '10px', alignItems: 'center'}}>
                <ContentHeader>진행단계</ContentHeader>
                <DropdownBox active={progress} options={options} onSelected={(selected) => handleDropdown(selected)} />
                <Title>작성자</Title>
                <InputStyles
                  value={fieldWriter}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldWriter(e.target.value)}
                />
              </div>

              <div style={{display: 'flex'}}>
                <span>내용</span>
                <textarea value={fieldMemoValue} placeholder="직접입력" onChange={handleOnChangeField} />
              </div>
            </Block>
            <Table memos={fieldMemo} handleOnClick={handleFieldClick} />
            <ButtonContainer>
              <BasicButton borderRadius={4} type="button" size="medium" onClick={() => setFieldMemoValue('')}>
                초기화
              </BasicButton>
              <BasicButton
                borderRadius={4}
                type="button"
                size="medium"
                colorType="darkGreen"
                style={{background: '#1db177', color: '#fff'}}
                onClick={handleSubmitField}
              >
                등록하기
              </BasicButton>
            </ButtonContainer>

            <Block style={{marginTop: '68px'}}>
              <span>메모 전체</span>
              <MemoViewer>{fieldTotalMemo}</MemoViewer>
            </Block>
          </Container>
        </>
      )}
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  margin: 0px 0px 12px 0px;
  padding-left: 12px;
`;

const Title = styled.span`
  font-size: 20px;
  color: #1db177;
  margin: 24px 0 12px 0;
`;
const Container = styled.div`
  padding: 5px 12px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .col {
    flex-direction: column;
  }
`;

const Block = styled.div`
  width: 100%;
  display: flex;

  span {
    width: 92px;
    height: 24px;
    color: #464646;
    font-size: 14px;
    align-items: flex-start;
  }

  textarea {
    margin-left: 14px;
    height: 40px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #818181;
    border-radius: 4px;
    padding: 12px 8px;
    font-size: 14px;
    resize: none;
  }
`;

const TableStyles = styled.table`
  margin-top: 18px;
  width: 100%;
  border-collapse: collapse;
  background-color: #f8f8f8;

  th,
  td {
    text-align: center;
  }

  th {
    color: #464646;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
  }
  tr {
    height: 40px;
    & > td:last-child {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  td {
    background-color: #fff;
    font-size: 14px;
    color: #464646;
    font-weight: 400;
    border-bottom: 1px solid #ececec;
  }
`;

const MemoViewer = styled.div`
  height: 200px;
  width: 100%;
  padding: 15px;
  white-space: pre-line;
  border-radius: 4px;
  border: 1px solid #818181;
  overflow-y: scroll;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  width: 100%;

  button {
    margin-right: 8px;
  }
`;

const DropdownBox = styled(Dropdown)`
  width: 173px;
  margin-right: 28px;
  align-items: center;
  border: 0px;
  background: #fff;
`;

const ContentHeader = styled.div`
  width: 92px;
  font-size: 14px;
  line-height: 20px;
  color: #464646;
`;

const InputStyles = styled.input`
  width: 173px;
  height: 36px;
  border: 1px solid #818181;
  border-radius: 4px;
`;
export default MemoTab;
