export const getWeek = (date: Date) => {
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();

  const weekStartDate = new Date(currentYear, currentMonth, currentDay - 6);
  const weekEndDate = new Date(currentYear, currentMonth, currentDay);

  return {weekStartDate, weekEndDate};
};
