import {NameType, SIGNUP_FIELDS} from '@/components/Auth';
import {BasicButton} from '@/components/Button';
import {Section} from '@/components/styledComponents/Common';
import {TextField} from '@/components/TextField';
import Typography from '@/components/Typography';
import {pathname} from '@/constants';
import {useSignUp} from '@/hooks/room';
import {ISignUp} from '@/interface';
import theme from '@/styled-components/theme';
import {emailValidator, isValidPassword, normalizePassword, phoneValidator} from '@/utils/inputValidator';
import React, {useState} from 'react';
import DaumPostcode from 'react-daum-postcode';
import {useHistory} from 'react-router';
import styled from 'styled-components';

const initValues = {
  adminType: undefined,
  email: undefined,
  mobile: undefined,
  name: undefined,
  password: undefined,
  password2: undefined,
  basicAddr: undefined,
  detailAddr: undefined,
  fieldOffice: undefined,
};

export const SignUp = () => {
  const history = useHistory();
  const [register, {called, data, loading}] = useSignUp();
  const [values, setValues] = useState<ISignUp>(initValues);
  const [errors, setErrors] = useState<{[key in NameType]?: string}>({});

  const [adminType, setAdminType] = useState<string>(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (loading) {
        return;
      }

      const fields = {
        email: undefined,
        password: undefined,
        adminType,
        ...values,
      };
      const newErrors: {[key in NameType]?: string} = checkValidation(fields);
      if (Object.values(newErrors).filter((i) => !!i?.length).length) {
        return;
      }

      if (adminType === undefined) {
        alert('담당자 구분을 선택해 주세요');
        return;
      }
      try {
        await register(values);
      } catch (e) {
        console.log(e);
        if (e) {
          setErrors({password: e});
        } else {
          setErrors({password: '로그인을 실패했습니다. 잠시 후 다시 시도해주세요.'});
        }
        alert(e);
      }
    },
    [loading, values, adminType],
  );

  React.useEffect(() => {
    // if (called && data) {
    //   updateUserWithToken(data.data);
    // }
    if (called && data && confirm('가입 되었습니다.')) {
      history.replace(pathname.SIGN_IN);
    }
  }, [called, data]);

  const checkValidation = React.useCallback(
    (obj) => {
      const newErrors: {[key in NameType]?: string} = {};

      Object.keys(obj).forEach((key) => {
        const value = obj[key];

        newErrors[key] = null;
        if (key === 'email') {
          newErrors[key] = emailValidator(value);
        } else if (key === 'name') {
          if (!value?.length) {
            newErrors[key] = '담당자명을 입력해 주세요.';
          }
        } else if (key === 'mobile') {
          newErrors[key] = phoneValidator(value);
        }
      });

      setErrors({
        ...errors,
        ...newErrors,
      });

      return newErrors;
    },
    [errors, values, adminType],
  );

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {name} = e.target;
      let value = e.target.value;

      checkValidation({[name]: value});

      if (name.includes('password') && !isValidPassword(value as string)) {
        value = normalizePassword(value as string);
      }

      setValues({
        ...values,
        [name]: value,
      });
    },
    [checkValidation, values],
  );

  const handleRadio = React.useCallback(
    (e) => {
      const id = e.target.id;

      setAdminType(id);
      setValues({...values, adminType: id});
    },
    [values, adminType],
  );

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setValues({...values, basicAddr: fullAddress});
    setIsOpen(false);
  };

  return (
    <div>
      <Root>
        <Title>피터팬의 무보증방 구하기 관리센터</Title>
        <Alert
          dangerouslySetInnerHTML={{
            __html:
              '피터팬의 무보증방 구하기 담당자로 가입하실 수 있습니다.</br> 가입 이후 승인절차가 완료되어야 관리센터 화면 접속이 가능합니다.',
          }}
        />
        <Section key={'adminType'} spacing={4}>
          <AdminTypeContainer>
            <Label>{'담당자 구분'}</Label>
            <RadioContainer>
              <input type="radio" id="INTERNAL" checked={adminType === 'INTERNAL'} onChange={handleRadio} />
              본사
              <input
                type="radio"
                id="FIELD"
                checked={adminType === 'FIELD'}
                onChange={handleRadio}
                style={{marginLeft: 36}}
              />
              중개파트너
            </RadioContainer>
          </AdminTypeContainer>
        </Section>
        <form onSubmit={handleSubmit} style={{minWidth: '400px'}} autoComplete={'off'}>
          <Section spacing={0}>
            {SIGNUP_FIELDS.map(
              (item) =>
                item &&
                (item.type === 'button' ? (
                  <Section
                    key={item.id}
                    spacing={4}
                    style={{
                      display: adminType === 'INTERNAL' && item.label === '주소' ? 'none' : '',
                    }}
                  >
                    <AddOnButtonBlock>
                      <TextField
                        fullWidth
                        label={item.label}
                        type={item.type}
                        required={item.required}
                        autoComplete={'new-password'}
                        name={item.id}
                        value={values[item.id]}
                        error={errors[item.id]}
                        placeholder={item.placeholder}
                        onChange={handleChange}
                      />
                      <AddressButton type="button" onClick={() => setIsOpen(!isOpen)}>
                        검색
                      </AddressButton>
                      {isOpen && (
                        <AddressModal>
                          {isOpen && <CloseBtn onClick={() => setIsOpen(false)}>X</CloseBtn>}
                          <DaumPostcode onComplete={handleComplete} />
                        </AddressModal>
                      )}
                    </AddOnButtonBlock>
                  </Section>
                ) : (
                  <Section
                    key={item.id}
                    spacing={4}
                    style={{
                      display:
                        adminType === 'INTERNAL' && (item.label === '중개업체명' || item.label === '상세주소')
                          ? 'none'
                          : '',
                    }}
                  >
                    <TextField
                      fullWidth
                      label={item.label}
                      type={item.type}
                      required={item.required}
                      autoComplete={'new-password'}
                      name={item.id}
                      value={values[item.id]}
                      error={errors[item.id]}
                      placeholder={item.placeholder}
                      onChange={handleChange}
                      // disabled={adminType === 'INTERNAL' && item.label === '중개업체명'}
                    />
                  </Section>
                )),
            )}
          </Section>
          <ButtonContainer>
            <BasicButton size="medium" colorType="primary" width={'100%'} type="submit">
              가입
            </BasicButton>
          </ButtonContainer>
        </form>
      </Root>
    </div>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
`;

const Title = styled(Typography).attrs({
  variant: 'title4',
  fontWeight: '700',
})`
  margin-bottom: 20px;
  position: relative;
  ::after {
    content: '';
    position: absolute;
    bottom: -40px;
    left: -75px;
    width: calc(100% + 150px);
    height: 3px;
    background-color: ${theme.palette.primary};
    z-index: 3;
  }
`;

const Alert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 20px;
  background-color: #f0eeec;
`;

const ButtonContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  max-width: 420px;
`;

const Label = styled.div`
  min-width: 120px;
  &:before {
    content: '*';
    color: red;
    height: 100%;
    vertical-align: middle;
    margin-right: 3px;
  }
`;

const AdminTypeContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 400px;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AddOnButtonBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`;
const AddressButton = styled.button`
  width: 200px;
  height: 48px;
  margin-top: 8px;
  border: none;
  background-color: #1db177;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
`;

const AddressModal = styled.div`
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: 400px;
`;

const CloseBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -10px;
  width: 20px;
  height: 20px;
  z-index: 101;
  background-color: ${theme.palette.primary};
  cursor: pointer;
`;
