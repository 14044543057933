export enum UserType1 {
  MANAGER = '관리자',
  DRIVER = '기사',
  TESTER = '테스터',
}

export enum LogLevel {
  d = 'd',
  i = 'i',
  w = 'w',
  e = 'e',
}

export enum LogType {
  GM_KMR = 'GM_KMR',
  KMR_GM = 'KMR_GM',
  APP = 'APP',
  WEB = 'WEB',
  SERVER = 'SERVER',
  WORKER = 'WORKER',
  DAEMON = 'DAEMON',
  DEPOSIT_MANUAL = 'DEPOSIT_MANUAL',
}

export enum LogPriority {
  HIGH = 'HIGH',
  NORMAL = 'NORMAL',
  LOW = 'LOW',
}
