import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Spinner from '@/common/Spinner';
import {Modal} from '../Modal';
import excelLogo from './excel-logo.png';
import folderLogo from './folder-logo.jpeg';
import theme from '@/styled-components/theme';

export const DownLoading = ({fileName}) => {
  return (
    <Modal width={500}>
      <Root>
        <ImageBox>
          <LogoImg src={excelLogo} alt="엑셀로고" />
          . . .
          <LogoImg src={folderLogo} alt="폴더이미지" />
        </ImageBox>

        <Spinner tip={`${fileName}을 다운로드 중...`} />
      </Root>
    </Modal>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

const ImageBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40%;
`;

const LogoImg = styled.img`
  height: 40px;
`;
