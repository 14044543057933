import {TimeSelect} from '@/components/Dropdown/TimeSelect';
import {IAccount, IRoom} from '@/interface';
import {
  CONTACTTIME,
  getAddRequestTypesByValue,
  getAgeGroupByValue,
  getGenderByValue,
  getJobTypeByValue,
  getMoveInByValue,
  getProgressType,
  getRentCostByValue,
  getRoomTypeByValue,
  moveInDateType,
  rentCostGroup,
} from '@/page/ClientDetail/data';
import {roomState} from '@/stores/room';
import {userState} from '@/stores/session';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useRecoilState} from 'recoil';
import styled from 'styled-components';
import {Dropdown} from '@/components/Dropdown';

interface IProps {
  data?: IRoom;
}

interface IOption {
  id: number | undefined;
  label: string;
  value?: string;
}

const initialValue = {
  age: null,
};

export const Retouch = ({data}: IProps) => {
  const [propData, setpropData] = React.useState<IAccount>();
  const [user, setUser] = useRecoilState(userState);
  const [informNo, setinformNo] = React.useState('');
  const [dropData, setdropData] = React.useState<any>(initialValue);
  const [age, setage] = React.useState<IOption>({id: null, label: `${getAgeGroupByValue(data.ageGroup)}`});
  const [selectedTime, setSelectedTime] = React.useState(
    data.contactTime.split(',').length > 1
      ? [data?.contactTime?.split(',')[0], data?.contactTime?.split(',')[1]]
      : [data.contactTime],
  );

  const [selectedRentCost, setSelectedRentCost] = React.useState<IOption>({
    id: getRentCostByValue(data.rentCostGroup)?.id,
    label: getRentCostByValue(data.rentCostGroup)?.label,
    value: getRentCostByValue(data.rentCostGroup)?.value,
  });
  const [selectedMoveInDate, setSelectedMoveInDate] = React.useState<IOption>({
    id: getMoveInByValue(data.moveInDateType)?.id,
    label: getMoveInByValue(data.moveInDateType)?.label,
    value: getMoveInByValue(data.moveInDateType)?.value,
  });

  const [addr, setAddr] = useState(data.wishRegion);
  const [room, setRoom] = useRecoilState(roomState);

  React.useEffect(() => {
    setpropData({
      ...(user as IAccount),
    });
    setRoom(data);
  }, []);

  const ageDropdown = React.useCallback(
    (selected) => {
      if (selected.id) {
        setage(selected);
        setdropData(selected.value);
      }
    },
    [age, dropData],
  );

  const handleChangeText = React.useCallback(
    (e) => {
      const {target, data} = e;
      setinformNo(target?.value);
    },
    [informNo],
  );
  // const room = useSetRecoilState(roomState);

  const handleRentCost = React.useCallback((selected) => {
    setSelectedRentCost(selected);
    setRoom((prev) => ({...room, ...prev, rentCostGroup: selected.label}));
  }, []);

  const handleMoveInDate = React.useCallback((selected) => {
    setSelectedMoveInDate(selected);
    setRoom((prev) => ({...room, ...prev, moveInDateType: selected.value}));
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setAddr(e.target.value);
    setRoom((prev) => ({...room, ...prev, wishRegion: e.target.value}));
  };

  const handleTime = React.useCallback((e) => {
    setSelectedTime((prev) => [...prev, e]);
  }, []);

  useEffect(() => {
    setRoom((prev) => ({...room, ...prev, contactTime: String([...selectedTime])}));
  }, [selectedTime]);

  const handleDelete = (e) => {
    const filter = selectedTime.filter((x) => x !== e);
    setSelectedTime(filter);
  };

  return (
    <Items>
      <ItemContainer>
        <Title>No</Title>
        <Content value={data.id} disabled={true} />
      </ItemContainer>
      <ItemContainer>
        <Title>접수일시</Title>
        <Content value={moment(data.createdAt).format('yyyy-MM-DD HH:mm:ss')} disabled={true} />
      </ItemContainer>
      <ItemContainer>
        <Title>처리상태</Title>
        <Content value={getProgressType(data.progressType)} disabled={true} />
      </ItemContainer>
      <ItemContainer>
        <Title>고객명</Title>
        <Content value={data.userName} disabled={true} />
      </ItemContainer>
      <ItemContainer>
        <Title>ID</Title>
        <Content value={data.userEmail} disabled={true} />
      </ItemContainer>
      <ItemContainer>
        <Title>연락처</Title>
        <Content value={data.userMobile} disabled={true} />
      </ItemContainer>
      <ItemContainer>
        <Title>
          연락 가능시간
          <br />
          (택 2)
        </Title>
        <div style={{flexDirection: 'column', padding: '12px 0'}}>
          <TimeSelect
            defaultOption={[{id: 0, label: '선택', value: undefined}]}
            onSelected={handleTime}
            onDeleted={handleDelete}
            showSelectHighlight
            size={'large'}
            active={selectedTime}
            options={[...CONTACTTIME]}
            placeholder={'선택'}
          />
          <div style={{gap: '6px', display: 'flex', marginTop: '11px'}}>
            {selectedTime.map((v, index) => (
              <ButtonStyle key={index}>{v}</ButtonStyle>
            ))}
          </div>
        </div>
      </ItemContainer>
      <ItemContainer>
        <Title>연령</Title>
        <Content style={{borderTop: '1px solid #e5e5e5'}} value={getAgeGroupByValue(data.ageGroup)} disabled={true} />
      </ItemContainer>
      <ItemContainer>
        <Title>성별</Title>
        <Content value={getGenderByValue(data.gender)} disabled={true} />
      </ItemContainer>
      <ItemContainer>
        <Title>직업</Title>
        <Content value={getJobTypeByValue(data.jobType)} disabled={true} />
      </ItemContainer>
      <ItemContainer>
        <Title>담당자</Title>
        <Content value={data?.fieldAdmin?.name ?? '미지정'} disabled={true} />
      </ItemContainer>
      <ItemContainer>
        <Title>희망월세</Title>
        <DropdownBox
          active={selectedRentCost}
          options={[...rentCostGroup]}
          onSelected={(selected) => handleRentCost(selected)}
        />
      </ItemContainer>
      <ItemContainer>
        <Title>희망 방개수</Title>
        <Content value={getRoomTypeByValue(data.roomType)} disabled={true} />
      </ItemContainer>
      <ItemContainer>
        <Title>희망 입주시기</Title>
        <DropdownBox
          active={selectedMoveInDate}
          options={[...moveInDateType]}
          onSelected={(selected) => handleMoveInDate(selected)}
        />
      </ItemContainer>
      <ItemContainer>
        <Title>희망지역</Title>
        <Content value={addr} onChange={onChange} isEdit />
      </ItemContainer>
      <ItemContainer>
        <Title>추가요청사항</Title>
        <Content value={getAddRequestTypesByValue(data.addRequestTypes)} disabled={true} />
      </ItemContainer>
      <ItemContainer>
        <Title>기타사항</Title>
        <Content value={data.requestMemo} disabled={true} />
      </ItemContainer>
    </Items>
  );
};

const Items = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
`;
const ItemContainer = styled.div`
  display: flex;
`;
const Title = styled.div`
  min-width: 124px;
  padding: 7px 10px;
  display: flex;
  padding-left: 14px;
  background: #f8f8f8;
`;

const DropdownBox = styled(Dropdown)`
  width: 100%;
  min-width: 300px;
  display: flex;
  padding-left: 12px;
  align-items: center;
  border: 0px;
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
`;

const Content = styled.input<{isEdit: boolean}>`
  width: 100%;
  min-width: 300px;
  display: flex;
  padding-left: 12px;
  align-items: center;
  border: 0px;
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
  ${(props) =>
    props.isEdit &&
    `
    border: 1px solid #DDDDDD;
border-radius: 4px;
  `}
`;

const ButtonStyle = styled.button`
  background: #1db177;
  border-radius: 4px;
  width: 93px;
  height: 28px;
  color: #fff;
  font-size: 13px;
  border: none;
`;
