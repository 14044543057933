import {useResetPassword} from '@/hooks/room';
import {IAccount} from '@/interface';

import React from 'react';
import styled from 'styled-components';
import {BasicButton} from '../Button';

import {Modal} from '../Modal';
import Typography from '../Typography';

interface IProps {
  onClose?(e): void;
  onComplete?(e): void;
  data?: IAccount;
}

interface IOption {
  id: string | undefined;
  label: string;
  value?: number;
}

export const ResetPasswordWindow = ({onClose, onComplete, data}: IProps) => {
  const [inputText, setInputText] = React.useState<string>('비밀번호를</br>초기화 하시겠습니까?');
  const [resetState, setReset] = React.useState<boolean>(false);
  const [datas, setData] = React.useState<IOption[]>([]);
  const [request, result] = useResetPassword();

  const handleConfirm = React.useCallback(() => {
    request(data.id);
  }, [inputText]);

  React.useEffect(() => {
    if (!result.loading && result.error) {
      alert(result.error);
    } else if (!result.loading && result.called && result.data) {
      setInputText('비밀번호가</br>12345678으로 초기화 되었습니다.');
      setReset(true);
    }
  }, [result]);

  const handleComplete = React.useCallback(() => {
    onComplete(resetState);
  }, [resetState]);

  return (
    <Modal title={'패스워드 초기화'} onClose={onClose} width={500}>
      <Root>
        <Typography as="div" variant="body1" fontWeight="500" dangerouslySetInnerHTML={{__html: inputText}} />
      </Root>
      <ButtonContainer>
        {resetState ? (
          <BasicButton type="button" size="medium" colorType="primary" onClick={handleComplete}>
            확인
          </BasicButton>
        ) : (
          <div>
            <BasicButton borderRadius={4} type="button" size="medium" onClick={onClose}>
              아니요
            </BasicButton>
            <BasicButton borderRadius={4} type="button" size="medium" colorType="darkGreen" onClick={handleConfirm}>
              예
            </BasicButton>
          </div>
        )}
      </ButtonContainer>
    </Modal>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  div {
    margin: auto;
  }
  text-align: center;
`;

const ManagerName = styled(Typography)`
  border: 1px solid #818181;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  width: 193px;
  margin-left: 17px;
`;

const InputBox = styled.textarea`
  border: 1px solid #818181;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  width: 374px;
  height: 142px;
  padding: 14px;
  resize: none;
  margin-left: 80px;
`;

const LastModified = styled(Typography)`
  text-align: end;
  margin-right: 14px;
`;

const Container = styled.div`
  padding: 20px 12px 12px 0px;
  display: flex;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-right: 8px;
  }
`;
