import {
  useDocumentList,
  useSetInternalManager,
  useDocumentRegister,
  useUploadFile,
  useDocumentDelete,
} from '@/hooks/room';
import {useSession} from '@/hooks/session';
import {DocumentType, IFiles, IRoom} from '@/interface';
import theme from '@/styled-components/theme';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import {BasicButton} from '../Button';
import {Dropdown} from '../Dropdown';
import {FileAttach} from '../File';
import {Modal} from '../Modal';
import Typography from '../Typography';
import IconX from './icon__x.svg';

interface IProps {
  onClose?(e): void;
  onComplete?(e): void;
  data?: IRoom;
}

interface IDoc {
  id?: number;
  url: string;
  fileName: string;
}

export const DocumentAttachWindow = ({onClose, onComplete, data}: IProps) => {
  const [documents, setDocuments] = React.useState<IFiles[]>([]);
  const [doc, setDoc] = React.useState<{[id: string]: Array<{id?: number; url: string; fileName: string}>}>({});
  const [request, documentList] = useDocumentList();
  const [register, registerResult] = useDocumentRegister();
  const [upload, uploadResult] = useUploadFile();
  const [deleteFile, deleteResult] = useDocumentDelete();
  const {user} = useSession();

  React.useEffect(() => {
    if (data) {
      request(data.id);
    }
  }, []);

  React.useEffect(() => {
    if (documentList.called && documentList.data) {
      setDocuments(documentList?.data?.data || []);

      if (documentList.data?.data?.length) {
        const docData: {[id: string]: any} = {};
        documentList?.data?.data?.map((x) => {
          docData[String(x.documentsType)] = x?.fileUriList?.map((f, index) => {
            return {
              id: x.id,
              url: f,
              fileName: x.fileNameList[index],
            };
          });
        });

        setDoc(docData);
      }
    } else if (!documentList.loading && documentList.error) {
      alert(documentList.error);
    }
  }, [documentList, deleteResult]);

  const handleChange = React.useCallback((type, files) => {
    if (files && files.length > 0) {
      const fd = new FormData();
      fd.append('file', files[0]);
      fd.append('path', type);
      upload(type, fd);
    }
  }, []);

  const handleRemove = React.useCallback(
    (type, index, id) => {
      if (user?.authorizeType !== 'MASTER') {
        alert('마스터 권한만 삭제할 수 있습니다.');
        return;
      }

      //삭제 id
      if (id) {
        //이미지, api 업로드가 둘다 된 경우
        deleteFile(id);
      } else {
        //이미지만 업로드 된 경우
        const newArray = [...(doc[type] || [])];

        if (newArray) {
          delete newArray[index];
        }

        setDoc({
          ...doc,
          [type]: newArray,
        });
      }
    },
    [doc],
  );

  React.useEffect(() => {
    if (deleteResult.called && !deleteResult.loading && deleteResult.data) {
      request(data.id);
    } else if (!deleteResult.loading && deleteResult.error) {
      alert(deleteResult.error);
    }
  }, [deleteResult]);

  React.useEffect(() => {
    if (uploadResult.called && uploadResult.data) {
      const url = uploadResult?.data?.data?.url;
      const fileName = uploadResult?.data?.data?.name;

      try {
        //"https://d12tke0ce4zf2d.cloudfront.net/LESSOR_ETC/655799d6-d073-4cc4-a68f-2bd592d783b9.png"
        const param = url.split('.cloudfront.net/')[1];
        if (param) {
          const type = param.split('/')[0];

          const subList = [
            ...(doc[type] || []),
            {
              url,
              fileName,
            },
          ];

          const uniqueArr: any = [];
          subList.map((x) => {
            if (uniqueArr?.find((u) => u.fileName === x.fileName)) {
              return x;
            }
            uniqueArr.push(x);
            return x;
          });

          if (uniqueArr?.length > 10) {
            alert('최대 10개까지 첨부할 수 있습니다.');
            return;
          }

          setDoc({
            ...doc,
            [type]: uniqueArr,
          });
        }
      } catch (e) {
        console.log('uploadResult', e);
      }
    } else if (!uploadResult.loading && uploadResult.error) {
      alert(uploadResult.error);
    }
  }, [uploadResult]);

  const handleConfirm = React.useCallback(
    (e) => {
      const files: IFiles[] = [];

      Object.keys(doc).map((key) => {
        const value = doc[key];
        files.push({
          documentsType: key,
          fileUriList: value.filter((x) => x.url !== 'null' && x.url).map((d) => d.url),
          fileNameList: value.filter((x) => x.fileName !== 'null' && x.fileName).map((d) => d.fileName),
          id: value[0]?.id,
          roomId: data?.id,
        });
      });

      register(files);
    },
    [doc],
  );

  React.useEffect(() => {
    if (registerResult.called && registerResult.data) {
      onComplete(registerResult.data);
    } else if (!registerResult.loading && registerResult.error) {
      alert(registerResult.error);
    }
  }, [registerResult]);

  return (
    <Modal title={'관련서류'} onClose={onClose} width={500}>
      <Root>
        <Container>
          <MiddleTitle>임차인 서류</MiddleTitle>
          <Items>
            {lessee.map((x) => (
              <ItemContainer key={x.id}>
                <Title>{x.title}</Title>

                <Documents>
                  {doc &&
                    doc[x.id] &&
                    doc[x.id]?.map((d, index) => (
                      <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                        <AttachedFile
                          key={index}
                          onClick={() => {
                            window.open(d.url, '_blank');
                          }}
                        >
                          {d?.fileName}
                        </AttachedFile>
                        <Close onClick={() => handleRemove(x.id, index, d.id)} />
                      </div>
                    ))}
                  <FileAttach id={x.id} onChange={handleChange} />
                </Documents>

                {/* <Content>
                  {{
                    createdAt: moment(data[x.id]).format('yyyy-MM-DD HH:mm:ss'),
                    fieldAdmin: data[x.id]?.name,
                  }[x.id] || data[x.id]}
                </Content> */}
              </ItemContainer>
            ))}
          </Items>
          <MiddleTitle>임대인 서류</MiddleTitle>
          <Items>
            {lessor.map((x) => (
              <ItemContainer key={x.id}>
                <Title dangerouslySetInnerHTML={{__html: x.title}} />

                <Documents>
                  {doc[x.id] &&
                    doc[x.id]?.map((d, index) => (
                      <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                        <AttachedFile
                          key={index}
                          onClick={() => {
                            window.open(d.url, '_blank');
                          }}
                        >
                          {d?.fileName}
                        </AttachedFile>
                        <Close onClick={() => handleRemove(x.id, index, d.id)} />
                      </div>
                    ))}
                  <FileAttach id={x.id} onChange={handleChange} />
                </Documents>

                {/* <Content>
                  {{
                    createdAt: moment(data[x.id]).format('yyyy-MM-DD HH:mm:ss'),
                    fieldAdmin: data[x.id]?.name,
                  }[x.id] || data[x.id]}
                </Content> */}
              </ItemContainer>
            ))}
          </Items>
        </Container>
      </Root>
      <ButtonContainer>
        <BasicButton borderRadius={4} type="button" size="medium" onClick={onClose}>
          취소
        </BasicButton>
        <BasicButton borderRadius={4} type="button" size="medium" colorType="darkGreen" onClick={handleConfirm}>
          수정하기
        </BasicButton>
      </ButtonContainer>
    </Modal>
  );
};

const MiddleTitle = styled((props) => (
  <Typography variant="title2" as="div" color={theme.palette.primary} {...props} />
))`
  padding: 20px 0px;
  border-bottom: 1px solid ${theme.palette.border};
`;

const AttachedFile = styled((props) => <Typography as="div" color={theme.palette.primary} {...props} />)`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 650px;
`;

const ItemContainer = styled.div`
  display: flex;
`;

const Container = styled.div`
  padding: 0px 12px 12px 0px;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-right: 8px;
  }
`;

const Documents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 12px;
  padding: 5px;
  border-bottom: 1px solid #e5e5e5;
`;

const Items = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  border-radius: 5px;
  padding: 20px 0px;
`;

const Title = styled.div`
  min-width: 124px;
  padding: 14px;
  display: flex;
  justify-content: center;
  background: #f8f8f8;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  padding-left: 12px;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
`;

export const lessee = [
  {
    id: 'THREE_WAY_CONTRACT',
    title: '3자 계약서',
  },
  {
    id: 'LEASE_CONTRACT',
    title: '임대차 계약서',
  },
  {
    id: 'GUARANTEE_INSURANCE',
    title: '보증보험증서',
  },
  {
    id: 'TENANT_ETC',
    title: '기타(매물사진 등)',
  },
];

export const lessor = [
  {
    id: 'CONSIGNMENT_CONTRACT',
    title: '위탁계약서',
  },
  {
    id: 'ACCOUNT_COPY',
    title: '계좌사본',
  },
  {
    id: 'CERTIFICATE_OF_SEAL',
    title: '건축물대장',
  },
  {
    id: 'BUSINESS_LICENSE',
    title: '사업자등록증',
  },
  {
    id: 'REGISTERED_COPY',
    title: '등기부등본',
  },

  {
    id: 'LESSOR_ETC',
    title: '기타(매물사진 등)',
  },
];

const Close = styled.div`
  width: 24px;
  height: 12px;
  cursor: pointer;

  background-repeat: no-repeat;
  background-image: url(${IconX});
  background-position: center;
  margin-left: 3px;
  :active {
    opacity: 0.5;
  }
`;
