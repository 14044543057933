import {useSetInternalManager} from '@/hooks/room';
import {IRoom} from '@/interface';
import {
  getAddRequestTypesByValue,
  getAgeGroupByValue,
  getGenderByValue,
  getJobTypeByValue,
  getMoveInDateTypeByValue,
  getProgressType,
  getRegionTypeByValue,
  getRentCostGroupByValue,
  getRoomTypeByValue,
  getVisitTypeByValue,
  getContactTimeValue,
} from '@/page/ReceiptHistory/data';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import {BasicButton} from '../Button';
import {Dropdown} from '../Dropdown';
import {Modal} from '../Modal';

interface IProps {
  onClose?(e): void;
  onComplete?(e): void;
  data?: IRoom;
}

export const RoomInfoDetailWindow = ({onClose, onComplete, data}: IProps) => {
  return (
    <Modal title={'접수정보 상세'} onClose={onClose} width={500}>
      <Root>
        <Container>
          <Items>
            {header.map((x) => (
              <ItemContainer key={x.id}>
                <Title>{x.title}</Title>
                <Content>
                  {{
                    contactTime: getContactTimeValue(data[x.id]),
                    createdAt: moment(data[x.id]).format('yyyy-MM-DD HH:mm:ss'),
                    fieldAdmin: data[x.id]?.name || '미지정',
                    ageGroup: getAgeGroupByValue(data[x.id]),
                    progressType: getProgressType(data[x.id]),
                    gender: getGenderByValue(data[x.id]),
                    jobType: getJobTypeByValue(data[x.id]),
                    rentCostGroup: getRentCostGroupByValue(data[x.id]),
                    roomType: getRoomTypeByValue(data[x.id]),
                    moveInDateType: getMoveInDateTypeByValue(data[x.id]),
                    regionTypes: getRegionTypeByValue(data[x.id]),
                    addRequestTypes: getAddRequestTypesByValue(data[x.id]),
                  }[x.id] || data[x.id]}
                </Content>
              </ItemContainer>
            ))}
          </Items>
        </Container>
      </Root>
      <ButtonContainer>
        <BasicButton type="button" size="medium" onClick={onClose}>
          닫기
        </BasicButton>
      </ButtonContainer>
    </Modal>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 650px;
`;

const ItemContainer = styled.div`
  display: flex;
`;

const Container = styled.div`
  padding: 20px 12px 12px 0px;
  display: flex;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Items = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
`;

const Title = styled.div`
  min-width: 124px;
  padding: 10px;
  display: flex;
  padding-left: 14px;
  background: #f8f8f8;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  padding-left: 12px;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
`;

export const header = [
  {
    id: 'id',
    title: 'No',
  },
  {
    id: 'createdAt',
    title: '접수일시',
  },
  {
    id: 'progressType',
    title: '처리상태',
  },
  {
    id: 'userName',
    minWidth: 70,
    title: '고객명',
  },
  {
    id: 'userEmail',
    title: 'ID',
  },
  {
    id: 'userMobile',
    title: '연락처',
  },
  {
    id: 'contactTime',
    title: '연락 가능시간',
  },
  {
    id: 'ageGroup',
    title: '연령',
  },
  {
    id: 'gender',
    title: '성별',
  },
  {
    id: 'jobType',
    title: '직업',
  },
  {
    id: 'fieldAdmin',
    title: '담당자',
  },
  {
    id: 'rentCostGroup',
    title: '희망월세',
  },
  {
    id: 'roomType',
    title: '희망 방개수',
  },
  {
    id: 'moveInDateType',
    title: '희망 입주시기',
  },
  {
    id: 'regionTypes',
    title: '희망지역',
  },
  {
    id: 'addRequestTypes',
    title: '추가요청사항',
  },
  {
    id: 'requestMemo',
    title: '기타사항',
  },
];
