import React from 'react';
import styled from 'styled-components';
import {Modal} from '../Modal';
import PaginationContainer from '../NewPagination';
import {useHistory} from 'react-router';

interface IProps {
  onClose?(e): void;
  onComplete?(e): void;
  onChange?: (page: number) => void;
  src: string;
  page: number;
  activePage: number;
}

interface IOption {
  id: number | undefined;
  label: string;
  email?: string;
}

export const ImageDetailPopup = ({activePage, page, src, onChange, onClose, onComplete}: IProps) => {
  const history = useHistory();

  return (
    <Modal onClose={onClose} width={700} style={{height: '100%', overflowY: 'hidden'}}>
      <Root>
        <img src={src}></img>
        <PaginationContainer
          activePage={activePage + 1}
          itemsCountPerPage={1}
          totalItemsCount={page}
          onPageChange={onChange}
          pageRangeDisplayed={page}
        />
      </Root>
    </Modal>
  );
};

const Root = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  min-height: 250px;
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Confirm = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 13px;
  width: 100%;
  cursor: pointer;
`;

const BodyContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 25px;
`;
