import isEmail from 'validator/lib/isEmail';

export const passwordValidator = (value: string) => {
  if (!value?.length) {
    return '비밀번호를 입력해 주세요';
  } else if (value.match(/[ㄱ-힣]/)) {
    return '비밀번호에 한글을 포함할 수 없습니다';
  } else if (!isValidPassword(value)) {
    return '영문, 숫자 포함 8~12자리 까지 가능합니다.';
  }

  return undefined;
};

export const emailValidator = (value: string) => {
  if (!value?.length) {
    return '이메일 주소를 입력해 주세요';
  } else if (!isEmail(value)) {
    return '이메일 주소를 정확히 입력해 주세요';
  }

  return undefined;
};

export const phoneValidator = (value: string) => {
  if (!value?.length) {
    return '번호를 입력해 주세요';
  } else if (!value.match(/^\d+$/)) {
    return '휴대폰번호는 숫자만 입력할 수 있습니다.';
  }

  return undefined;
};

export const nameValidator = (value: string) => {
  if (!value?.length) {
    return '이름을 입력해 주세요';
  } else if (!value.match(/[ㄱ-힣0-9 ]/)) {
    return '이름은 한글숫자만 가능합니다.';
  }

  return undefined;
};

export const carNumberValidator = (value: string) => {
  if (!value?.length) {
    return '차량번호를 입력해 주세요';
  } else if (!value.match(/^[ㄱ-힣0-9 ]+$/)) {
    return '차량번호는 한글 숫자만 가능합니다';
  }

  return undefined;
};

export const isValidPassword = (str: string = '') => {
  const reg1 = /^[a-zA-Z0-9]{8,20}$/;
  const reg2 = /[a-z]/g;
  const reg3 = /[0-9]/g;
  return reg1.test(str) && reg2.test(str) && reg3.test(str);
};

export const normalizePassword = (value: string = '') => {
  return value.replace(/[ㄱ-힣 ]/gi, '');
};
