import {ApolloClient, InMemoryCache} from '@apollo/client';

export default () => {
  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        RecommendedStore: {
          keyFields: ['id'],
        },
      },
    }),
    uri:
      process.env.NODE_ENV === 'production'
        ? 'http://3.37.224.186:6661/graphql'
        : `${process.env.REACT_APP_LOCAL_KMR_SERVER}/graphql`,
  });
  return client;
};
